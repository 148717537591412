import {getSocketUrl} from './request'
export default {
  data() {
    return {
      msgNum: 0,
      userList: []
    }
  },
  mounted() {
    this.userList.uid =  localStorage.getItem('userId')
    this.userList.roleId =  localStorage.getItem('userId')
    this.initWebsocket()
  },
  beforeDestroy() {
    this.closeWebsocket()
  },
  methods: {
    initWebsocket() {
      if (typeof WebSocket === 'undefined') {
        alert('您的浏览器不支持socket')
      } else {
        // 实例化socket
        let url = getSocketUrl() + '/messageWebsocket?userId=' + this.userList.uid + '&roleId='+this.userList.roleId
        this.socket = new WebSocket(url)
        // 监听socket连接
        this.socket.onopen = this.open
        // 监听socket错误信息
        this.socket.onerror = this.error
        // 监听socket消息
        this.socket.onmessage = this.getMessage
        // 监听关闭
        this.socket.onclose = function () {
          // 关闭 websocket
          console.log("连接已关闭...");
          //断线重新连接
          if(localStorage.getItem('userId')&&localStorage.getItem('userId')!=undefined){
            setTimeout(() => {
              this.socket = new WebSocket(url)
            }, 2000);
          }
        };
      }
    },
    open() {
      console.log('socket连接成功')
    },
    error() {
      console.log('连接错误')
    },
    getMessage(msg) {
      const msgList = JSON.parse(msg.data)
      this.msgNum = msgList.num;
      this.$eventBus.$emit('methodB','4444');
      this.$forceUpdate()
    },
    send(params) {
      this.socket.send(params)
    },
    closeWebsocket() {
      if (this.socket) {
        this.socket.close()
      }
    }
  }
}
