import axios from 'axios';
import domMessage from './MessageOnce'
import { Loading } from 'element-ui'; //项目已经全局引入element的话可以不单独引入
let Message  = new domMessage()
import router from '@/router/index'
import $store from '@/store/index';
import global from '@/global'; 
import { MessageBox } from 'element-ui';

let baseUrl = global.baseUrl
let socketUrl = global.socketUrl
axios.defaults.timeout = 300000
export function getSocketUrl(){
    return socketUrl;
}

let loading
function startLoading() {
    loading = Loading.service({
        lock: true,
        text: '加载中……',
        background: 'rgba(0, 0, 0, 0.7)',
        fullscreen:true
    })
}
function endLoading() {
    loading.close()
}
let needLoadingRequestCount = 0
export function showFullScreenLoading() {
    if (needLoadingRequestCount === 0) {
        startLoading()
    }
    needLoadingRequestCount++
}

export function tryHideFullScreenLoading() {
    if (needLoadingRequestCount <= 0) return
    needLoadingRequestCount--
    if (needLoadingRequestCount === 0) {
        endLoading()
    }
}
//http request 拦截器
const service = axios.create({
})
service.defaults.baseURL = baseUrl
//http request 拦截器
var Scope = 1;
let CancelToken = axios.CancelToken;
let source = CancelToken.source();
service.interceptors.request.use(
    config => {
        var token = ''
        let MenuArr = JSON.parse(localStorage.getItem('MenuArrayList'))
        // if(MenuArr){
        //     MenuArr.forEach(item=>{
        //         if(item.url==location.href.split('#')[1].split('/')[location.href.split('#')[1].split('/').length-1]){
        //             config.headers['Scope'] = item.scope;
        //             Scope = item.scope;
        //         }
        //     })
        // }
        // 延时一小时
        if(config.url == '/indoor/excelVillage') {
            config.timeout = 1000*60*60
        }
        config.headers['Scope'] = localStorage.getItem('dataScope')
        Scope = localStorage.getItem('dataScope')
        let tokenName=localStorage.getItem('tokenName')
        let tokenValue=localStorage.getItem('tokenValue')
        if(config.method == 'post'){
            if(config.url == '/aliYun/uploadGasPatrolImgToAliYunOSS'||config.url == '/aliYun/uploadMarkImgToAliYunOSS'||config.url == '/materialRecord/materialImport'||config.url == '/indoor/intoCity'||config.url == '/indoor/intoStandPipe'||config.url == '/indoor/intoVillage'||config.url == '/aliYun/uploadMarkImg'||config.url == '/AaumArchive/importUserExcel'||config.url == '/arumArchive/importUserExcel'){
                config.headers['Content-Type'] = 'multipart/form-data'
            }else{
                config.data = JSON.stringify(config.data);
                config.headers = {
                    'Content-Type':'application/json',
                }
            }
            if(!config.isloading){
                showFullScreenLoading()
            }
        }else if(config.method == 'get'){
            config.headers = {
                'Content-Type':'application/x-www-form-urlencoded',
            }
            if(!config.isloading){
                showFullScreenLoading()
            }
            
        }
       
       
        config.cancelToken = source.token

        $store.commit('setCancelTokenList', source.cancel)
        
        config.headers[tokenName]=tokenValue
        return config;
    },
    error => {
        return Promise.reject(err);
    }
);
service.interceptors.response.use(
    response => {
        // console.log(response)
        tryHideFullScreenLoading()
        if(response.data.type=='application/vnd.ms-excel'){
            return Promise.resolve(response.data)
        }

        if (response.data.type == 'application/json') { /* 导出excel失败时走这个 **/
            let data = response.data
            var reader = new FileReader();
            reader.readAsText(data, 'utf-8');
            reader.onload = () => {
                data = JSON.parse(reader.result)
                Message.error(data.msg)
            }
            $store.commit('setderiveVisible', false)
            return Promise.reject()
        }
        if (response.config.url == '/arumArchive/importUserExcel') {
                
            MessageBox.alert(response.data.msg || response.data.data, "提示", {
                confirmButtonText: "确定",
                closeOnClickModal:false,
                type: "warning",
            })
        }
        // 批量转派
        if (response.config.url == '/disData/dataTransfer') {
            return response.data;
        }
        if(response.data.code!=200){
            if(response.data.code == -104 || response.data.code == -103){
                Message.error(response.data.msg)
                localStorage.removeItem('tokenName')
                localStorage.removeItem('tokenValue')
                setTimeout((e)=>{
                    router.push('/Login').catch(err => {})
                    return Promise.reject()
                },1500)
            } 
            if (response.config.url != '/arumArchive/importUserExcel') {
                if (response.data.msg) Message.error(response.data.msg)
            }
            return Promise.reject(response.data)
        }
        return response.data;
    },
    error => {
        tryHideFullScreenLoading()
        return Promise.reject(error)
    }
)
export default service
