var myMixin = {
    mounted(){
        let inputArray = document.querySelectorAll('.el-input__inner:not(input[name="limitLength"])')
        inputArray.forEach((item, index) => {
            item.setAttribute('maxlength', 30)
        })
        let textareaArray = document.querySelectorAll('.el-textarea__inner')
        textareaArray.forEach((item, index) => {
            item.setAttribute('maxlength', 100)
        })
        let textareaArray1 = document.querySelectorAll('.el-textarea__inner:not(input[name="limitLength"])')
        textareaArray1.forEach((item, index) => {
            item.setAttribute('maxlength', 200)
        })
    },
}

export default myMixin
