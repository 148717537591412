<template>
    <div class="map-row">
        <subMap ref="subMap" :path="path" @setLocation="setLocation" />
        <div class="dialogBox" v-if="dialogTableVisible">
          <div class="gaine">
          <div class="fialogTitle">新增计划</div>
          <i class="el-icon-close modality"  @click="close()"></i>
        <el-form ref="form" :model="form" :rules="rules" label-width="80px" class="formBox">
          
          <el-form-item label="巡线区域" prop="executorPeopleId">
            <el-input v-model="form.executorPeopleId" placeholder="请输入巡检区域名称" class="widthes"></el-input>
          </el-form-item>
          <el-form-item label="巡线员" prop="networkId">
            <el-select v-model="form.networkId" placeholder="请选择" class="widthes"> 
              <el-option
                   v-for="item in excutorDownArray"
                   :key="item.id"
                   :label="item.username"
                   :value="item.id">
               </el-option>
            </el-select>
          </el-form-item>
          <el-row style="text-align: center;margin-top:53px">
            <el-button type="primary" @click="onSubmit" class="buttones">确 认</el-button>
            <el-button  class="buttones"  @click="close()">重新绘制</el-button>
         </el-row>
        </el-form>
          </div>
      </div>
      </div>
</template>

<script>
import subMap from '@/views/mapTool/subMapPlan'
import { getUserList } from "@/RequestPort/maintenance/task";
import { networkDetail} from '@/apis/commonType'
export default {
  components: { subMap},
  name: "inspePlanAdd",
  data() {
    return {
      path: [],
      // 表单提交
      form:{},
      // 新增计划弹窗
      dialogTableVisible:true,
      rules:{
        executorPeopleId: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        networkId: [{ required: true, message: "请选择", trigger: "change" }],
      },
      excutorDownArray:[]
    };
  },
  mounted() {
    /**
     * 获取巡线员
     */
   getUserList({type:1}).then((e) => {
      this.excutorDownArray = e.data;
    });
    // 判断是否有id
    if(this.$route.query.id) {
      // 调取接口获取参数赋值
      networkDetail({id:this.$route.query.id}).then(res => {
        console.log(res)
      })
      // 判断是否是查询
      if(this.$route.query.inquire){
        // 是查询的话
        this.dialogTableVisible = false
      }
    }
    
  },
  methods: {
    setLocation(point) {
      let arr = point
      arr.push(point[0])
      let newArr = []
      let strArr = []
      arr.forEach(item => {
        newArr.push([item.lng, item.lat])
        strArr.push(`${item.lng} ${item.lat}`)
      })
      this.form.polygon = String(strArr)
      let polygonObj = polygon([newArr])
      let center = centerOfMass(polygonObj)
      this.form.lon = center.geometry.coordinates[0]
      this.form.lat = center.geometry.coordinates[1]
    },
    setMapClick() {
      this.$refs.subMap.getLocation()
    },
    /**
     * 添加巡线计划
     */
    onSubmit(){
      console.log(this.form)
    },
    /**
     * 关闭弹窗
     */
    close(){
      this.dialogTableVisible = false
      this.form = {}
    }
  }
};
</script>

<style lang="less" scoped>
.map-row {
  position: relative;
  // 弹窗盒子
  .dialogBox{
    background: #ffffff;
    position: absolute;
    top: 30%;
    left: 50%;
    width: 420px;
    height: 363px;
    border-radius: 6px;
    // 内容盒子
    .gaine{
      position: relative;
    // 标题
    .fialogTitle{
      height: 60px;
      line-height: 60px;
      color: #0C235B;
      text-align: left;
      padding-left: 30px;
      font-size: 24px;
    }
    // 关闭按钮
    .modality{
      width: 19px;
      height: 19px;
      position: absolute;
      right: 30px;
      top: 20px;
      font-size: 30px;
    }
    // form盒子
    .formBox{
      margin: 53px 30px 10px;
      // 输入区域宽
      .widthes{
        width: 230px;
      }
      // 底部按钮宽
      .buttones{
        width: 160px;
      }
    }
    }
  }
}
</style>
