import axios from '@/common/js/request';

export default {
  // 下拉框
  getDropdown(params) {
    return axios({
        url: '/options/enum',
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
        params
    })
  },
  // 查字典
  getDicts1(params) {
    return axios({
        url: '/scada/dataType/leakwarn',
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
        params
    })
  },
  // 查字典
  getDicts(params) {
      return axios({
          url: '/dict/selectDictListByParentCode',
          method: 'GET',
          headers: {
              'Content-Type': 'application/json'
          },
          params
      })
  },
  // 数据类型
  getDataType(params) {
      return axios({
          url: '/scada/dataType/scada',
          method: 'GET',
          headers: {
              'Content-Type': 'application/json'
          },
          params
      })
  }
}
