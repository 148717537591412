import {getSocketUrl} from './request'
export default {
  data() {
    return {}
  },
  mounted() {
    // this.userList.uid =  localStorage.getItem('userId')
    // this.userList.roleId =  localStorage.getItem('userId')
    this.initWebsocket()
  },
  beforeDestroy() {
    this.closeWebsocket()
    if(this.$refs.audio){
      this.$refs.audio.pause()
    }
  },
  methods: {
    closeMusic() {
      let arr = document.getElementsByClassName('double').length
      if (arr == 0) {
        this.$refs.audio.pause() //停止
      }
    },
    playMusic(type) {
      this.$refs.audio.play()
      // setTimeout(() => {
      //   this.$refs.audio.pause() //停止
      // }, 120000)
    },
    initWebsocket() {
      if (typeof WebSocket === 'undefined') {
        alert('您的浏览器不支持socket')
      } else {
        let companyId = localStorage.getItem('zlCompanyId')
        // 实例化socket
        // let url = getSocketUrl() + '/warningPopup?userId=' + this.userList.uid + '&roleId='+this.userList.roleId
        let url = getSocketUrl() + '/warningPopup?companyId='+companyId
        this.socket = new WebSocket(url)
        // 监听socket连接
        this.socket.onopen = this.open
        // 监听socket错误信息
        this.socket.onerror = this.error
        // 监听socket消息
        this.socket.onmessage = this.getMessage
        // 监听关闭
        this.socket.onclose = function () {
          // 关闭 websocket
          console.log("连接已关闭...");
          //断线重新连接
          // setTimeout(() => {
          //   this.socket = new WebSocket(url)
          // }, 2000);
          
        };
      }
    },
    open() {
      console.log('socket连接成功')
    },
    error() {
      console.log('连接错误')
    },
    getMessage(msg) {
      let [info,page,that] = [msg.data,localStorage.getItem("showTitle"),this]
      if(info == 'warningPopup' && page == 'SCADA'){
        this.dialogVisibleWarn = true
        this.getList('reset')
      }
    },
    send(params) {
      this.socket.send(params)
    },
    closeWebsocket() {
      if (this.socket) {
        this.socket.close()
      }
    }
  }
}
