<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2022-06-17 08:21:43
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2023-05-17 08:53:17
 * @FilePath: \linqingpc\src\views\mapTool\question\zoom.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div></div>
</template>

<script>
export default {
  name: 'Bar',
  components: {},
  data() {
    return {
      startZoom: 14
    }
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    init() {
      let that = this
      this.$parent.map.on('zoomend', function(e) {
        //2.0zoom变化量小问题
        let map = that.$parent.map
        let nowZoom = map.getZoom()
        let old = nowZoom
        let zoom = nowZoom
        if (nowZoom > that.startZoom) {
          zoom = Math.ceil(nowZoom)
        } else if (nowZoom < that.startZoom) {
          zoom = parseInt(nowZoom)
        }
        map.setZoom(zoom)
        that.startZoom = nowZoom
        if(zoom==old){
          that.$parent.mapZoom(zoom)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
