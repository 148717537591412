import axios from '@/common/js/request'
// 获取维修列表 // 分页任务配置列表
export function taskpage(params){
    const taskpage = axios({
        url: '/zypatrol/task/config/page',
        method: 'get',
        params
    })
    return taskpage
}
export function checkpointlist(params){
    const checkpointlist = axios({
        url: '/zypatrol/task/config/checkpoint/list',
        method: 'get',
        params
    })
    return checkpointlist
}
export function getdetillist(params){
    const getdetillist = axios({
        url: '/zypatrol/task/config/get',
        method: 'get',
        params
    })
    return getdetillist
}
export function checklinelist(params){
    const checklinelist = axios({
        url: '/zypatrol/task/config/network/list',
        method: 'get',
        params
    })
    return checklinelist
}
export function deletetask(data){
    const deletetask = axios({
        url: '/zypatrol/task/config/delete',
        method: 'post',
        data
    })
    return deletetask
}
// 任务配置-添加-编辑任务 // 计划再用
export function updatetask(data){
    const updatetask = axios({
        url: '/patrol/event/config/eventTask',
        method: 'post',
        data
    })
    return updatetask
}
// 添加紧急任务
export function updatetask1(data){
    const updatetask1 = axios({
        url: '/zypatrol/task/config/update',
        method: 'post',
        data
    })
    return updatetask1
}

//zypatrol/getNetwork
// 片区下拉，巡检区域字典
export function getNetwork(params){
    const getNetwork = axios({
        url: '/zypatrol/getNetwork',
        method: 'get',
        params
    })
    return getNetwork
}

///zypatrol/getUserList
// 巡检人员下拉
export function getUserList(params){
    const getUserList = axios({
        url: '/zypatrol/getUserList',
        method: 'get',
        params
    })
    return getUserList
}
// zypatrol/plan/config/planInfos
export function planInfos(data){
    const planInfos = axios({
        url: '/zypatrol/plan/config/planInfos',
        method: 'post',
        data
    })
    return planInfos
}
//zypatrol/task/config/isOpen
export function isOpen(data){
    const isOpen = axios({
        url: '/zypatrol/task/config/isOpen',
        method: 'post',
        data
    })
    return isOpen
}
//zypatrol/picture/person/list
export function personList(params){
    const personList = axios({
        url: '/zypatrol/picture/person/list',
        method: 'get',
        params
    })
    return personList
}


export function eventList(params){
    const eventList = axios({
        url: '/zypatrol/picture/event/list',
        method: 'get',
        params
    })
    return eventList
}
// 任务列表-备注
export function getRemark(params){
    const getRemark = axios({
        url: '/zypatrol/task/remark',
        method: 'get',
        params
    })
    return getRemark
}
// 任务列表-转派
export function redeploy(data){
    const redeploy = axios({
        url: '/zypatrol/task/redeploy',
        method: 'post',
        data
    })
    return redeploy
}
// 获取设备列表
export function getDict(params){
    const getDict = axios({
        url: '/patrol/getDict',
        method: 'get',
        params
    })
    return getDict
}
// 设备巡检
export function dataPage(params){
    const getDict = axios({
        url: '/zypatrol/facility/equipment/dataPage',
        method: 'get',
        params
    })
    return getDict
}

export function taskPageEvent(params){
    const taskPage = axios({
        url: '/patrol/event/taskPage',
        method: 'get',
        params
    })
    return taskPage
}
export function eventPage(params){
    const eventPage = axios({
        url: '/patrol/event/page',
        method: 'get',
        params
    })
    return eventPage
}
//新增查询10000条带图片加载慢，（取消图片的接口）
export function eventPageNoimg(params){
    const eventPageNoimg = axios({
        url: '/patrol/event/pageAll',
        method: 'get',
        params
    })
    return eventPageNoimg
}
export function taskDelete(params){
    const taskDelete = axios({
        url: '/patrol/event/taskDelete',
        method: 'post',
        data:params
    })
    return taskDelete
}
export function eventIsOpen(params){
    const eventIsOpen = axios({
        url: '/patrol/event/isOpen',
        method: 'post',
        data:params
    })
    return eventIsOpen
}
export function excelLineTask(params){
    const excelLineTask = axios({
        url: '/zypatrol/task/excelLineTask',
        method: 'post',
        data:params,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        responseType: 'blob', //改变接收的值类型
        isloading: true
    })
    return excelLineTask
}
export function excelTask1(params){
    const excelTask1 = axios({
        url: '/zypatrol/task/excelTask1',
        method: 'post',
        data:params,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        responseType: 'blob' //改变接收的值类型
    })
    return excelTask1
}
export function eventRemark(params){
    const eventRemark = axios({
        url: '/patrol/event/remark',
        method: 'post',
        data:params
    })
    return eventRemark
}
export function getTrackWorkPage(params){
    const getTrackWorkPage = axios({
        url: '/zypatrol/track/getTrackWorkPage',
        method: 'get',
        params
    })
    return getTrackWorkPage
}
//  轨迹设为完成
export function setFinish(params){
    const setFinish = axios({
        url: '/zypatrol/track/setFinish',
        method: 'post',
        data:params
    })
    return setFinish
}
// 巡检分页列表
export function trackPage(params){
    const trackPage = axios({
        url: '/zypatrol/track/page',
        method: 'get',
        params
    })
    return trackPage
}
export function pullTemplate(params){
    const pullTemplate = axios({
        url: '/patrolSet/pullTemplate',
        method: 'get',
        params
    })
    return pullTemplate
}

// 设备异常导出
export function facilityexcelEventData(params){  //
    const facilityexcelEventData = axios({
        url: '/zypatrol/facility/equipmentExcel',
        method: 'get',
        params,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        responseType: 'blob' //改变接收的值类型
    })
    return facilityexcelEventData
}
// 设备异常导出 二
export function equipmentExcelAll(params){  //
    const equipmentExcelAll = axios({
        url: '/zypatrol/facility/equipmentExcelAll',
        method: 'get',
        params,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        responseType: 'blob', //改变接收的值类型
        isloading: true
    })
    return equipmentExcelAll
}
// 获取管线长度
export function getLength(params){
    const getLength = axios({
        url: '/zypatrol/line/getLength',
        method: 'get',
        params
    })
    return getLength
}

// PC未完成列表-gmy
export function pcEquipmentOngoingList(params){
    const pcEquipmentOngoingList = axios({
        url: '/appEquipmentPatrol/pcEquipmentOngoingList',
        method: 'get',
        params
    })
    return pcEquipmentOngoingList
}

// PC未完成列表-gmy  taskGenerateStatus==3
export function pcEquipmentOngoing2List(params){
    const pcEquipmentOngoing2List = axios({
        url: '/appEquipmentPatrol/pcEquipmentOngoing2List',
        method: 'get',
        params
    })
    return pcEquipmentOngoing2List
}