<template>
<div :class="isCollapse ? 'content' : 'content hide'">
  <div class="box">
    <!-- <div :class="isCollapse ? 'line' : 'line hide'" @click="Collapse">
      <i :class="isCollapse ? 'el-icon-s-fold' : 'el-icon-s-unfold'" ></i>
      <span>{{isCollapse?'折叠':'展开'}}</span>
    </div> -->
    <div>
      <el-menu 
        :default-active="this.$route.path"
    
        router
        :unique-opened="true"
  
        :collapse="!isCollapse"

    >
      <div v-for="(item) in AsideData"  :key="item.id" >
        <el-submenu :index="item.router" style="background: #1c1f28"  v-if="item.children">
          <template slot="title">
            
            <img v-if="item.SeeImg" :src="item.SeeImg" alt="" style="width:15px;height:15px;margin-left:15px;"> 
            <i v-else class="el-icon-folder-opened"></i>          
            <span slot="title" style="padding-left: 5px;font-size:16px">{{item.name}}</span>
          </template>
          <el-menu-item-group>
            <!-- item1.url=='authManagement'?'/home/logohome':'/home/'+item1.url -->
            <el-menu-item class="MenuItemClass"  v-for="(item1) in item.children" :index="urlItem1(item1.url)" :key="item1.id" @click.native="MenuSelect(item1)">
              <span slot="title" style="padding-left: 30px;font-size:16px">{{item1.name}}</span>
            </el-menu-item>
          </el-menu-item-group>
        </el-submenu>
        <!-- item.url=='authManagement'?'/home/logohome':item.url=='gis'?'/home/logohome1': '/home/'+item.url -->
        <el-menu-item  v-else :index="urlItem(item.url)" @click.native="MenuSelect(item)">
          <img v-if="item.SeeImg"  :src="item.SeeImg" alt="" style="width:15px;height:15px;margin-left:15px;">
          <i v-else class="el-icon-folder-opened"></i> 
          <span  slot="title" style="padding-left: 5px;font-size:16px">{{item.name}}</span>
        </el-menu-item>
      </div>
    </el-menu>
    </div>
    
    
  </div>
  </div>
</template>

<script>
export default {
  name: "Aside",
  data(){
    return{
      activeIcon:'1',
      AsideData:[],
      isCollapse:true,
   //   defultImg:require()
      button:[]
    }
  },
  watch:{
    $route(to,from){
    //  this.AssideDataFun()
      this.AsideData.forEach((item,index)=>{
        item.SeeImg = item.menuCloseIcon
        if(item.children){
          if(JSON.stringify(item.children).indexOf(to.path.split('/')[2])>-1){
            item.SeeImg = item.menuOpenIcon
          }
        }else{
          if(item.router.indexOf(to.path.split('/')[2])>-1){
            item.SeeImg = item.menuOpenIcon
          }
        }
      })
    }
  },
  methods:{
    // 自己是父级
    urlItem(url){
      if(url == 'authManagement' || url == 'warnGis'|| url == 'configuration') {
        return '/home/logohome'
      } else if(url=='gis') {
        return '/home/logohome1' 
      } else {
        return '/home/'+url
      }
    },
    // 子集里面判断
    urlItem1(url){
      if(url == 'authManagement' || url == 'configuration') {
        return '/home/logohome'
      }  else {
        return '/home/'+url
      }
    },
    Collapse() {
      this.isCollapse = !this.isCollapse
    },
    MenuSelect(item){
      if( item.url=='authManagement' || item.url=='gis' || item.url=="configuration" || item.url=='warnGis'){
        let routeData = this.$router.resolve({
            path: '/home/'+item.url,
        })
        window.open(routeData.href, '_blank')
       
      }else{
          let tag = {}
          tag={
            path: '/home/'+item.url,
            name:item.name,
            meta:{name:item.name}
          }
          this.$store.dispatch('addVisitedView', tag)
      }
      
    },
  getNavTree(menus) {
    var aside = [] 
    var resultChildren = []
        menus.forEach((menu, index) => {
            let nav={
              displayMode:menu.displayMode,
              code:menu.code,
              id:menu.id,
              menuCloseIcon:menu.menuCloseIcon,
              menuOpenIcon:menu.menuOpenIcon,
              name:menu.name,
              parentId:menu.parentId,
              router:menu.router,
              scope:menu.scope,
              type:menu.type,
              url:menu.url,
              weight: menu.weight
            }          
            if (menu.children) {            
              resultChildren = this.getNavTree(menu.children)
              if (resultChildren&&resultChildren.length > 0) {              
                  nav.children = resultChildren              
              }
            }        
            if(nav.type==2){//按钮
              this.button.push(nav.code)            
            }else{//菜单
              aside.push(nav)
            }       
        })
      return aside
  },
    AssideDataFun(){
      let AsideData = JSON.parse(localStorage.getItem('SelectMenuArray'))
      if(AsideData){
       
        AsideData.forEach((item,index)=>{
          item.SeeImg = item.menuCloseIcon
          if(item.children){
            if(JSON.stringify(item.children).indexOf(this.$route.path.split('/')[2])>-1){
              item.SeeImg = item.menuOpenIcon
            }
          }else{
            if(this.$route.path.indexOf(item.url)>-1){
              item.SeeImg = item.menuOpenIcon
            }
          }
        })
        this.button=[]
        this.AsideData=this.getNavTree(AsideData)
        localStorage.setItem('buttonList',JSON.stringify(this.button))
      }else{
        this.AsideData = []
      }
    }
  },
  mounted(){
    this.AssideDataFun()
    
  },
  beforeDestroy() {   
  },
 
}
</script>

<style>
.MenuItemClass{
  background: #1c1f28;
}
.box{
  width: 240px;
  height: calc(100vh - 72px );
  overflow-y: auto;
}
/*隐藏滚动条*/
.box::-webkit-scrollbar { width: 0 !important }
.el-menu{
  background-color: #1c1f28 !important;
  color:#a6a9b1!important;
  text-align: left;
}
.el-menu-item-group__title{padding: 0px!important;}
.el-menu-item:focus, .el-menu-item:hover {
  /*border-left: 4px solid #3E81E3!important;*/
  /* background-image: linear-gradient(to right,rgba(64,110,178, 1), rgba(64,110,178,0.1)); */
  background-color: #1082ff!important;
   color: #fff!important;
}
.el-submenu__title:hover {
  /*border-left: 4px solid #3E81E3!important;*/
  /* background-image: linear-gradient(to right,rgba(64,110,178, 1), rgba(64,110,178,0.1)); */
  background-color: #1082ff!important;
   color: #fff!important;
}
.el-menu-item{
  color: #a6a9b1!important;
}
.el-submenu__title{
  color:#a6a9b1!important;
  
}
.el-menu-item.is-active {
  /*border-left: 4px solid #3E81E3!important;*/
  /* background-image: linear-gradient(to right,rgba(64,110,178, 1), rgba(64,110,178,0.1)); */
  /*background-color: #00294B!important;*/
  background-color: #1082ff!important;
  color: #fff!important;

}
.line {
      /* float:left; */
      margin-left: 76%;
      color: #e7eaf2;
      
    }
 .line.hide{
      /* float:left; */
      margin-left: 5%;
    }
 .content {
    width: 240px;
    height: 100%;
    background-color: #242830;
    border-top: rgba(255, 255, 255, 0.1) 1px solid;overflow:hidden;
  }
  .content.hide {
    width:60px;
  }

</style>
