import requestGeo from '../utils/requestGeo'
export function queryFeatrue(params) {
  return requestGeo({
    url: '/geoserver/wms',
    method: 'get',
    params
  })
}
export function regionList(params) {
  return requestGeo({
    url: '/geoserver/ows',
    method: 'get',
    params
  })
}

export function geoOws(params) {
  return requestGeo({
    url: '/geoserver/ows',
    method: 'get',
    params
  })
}
