<template>
  <div class="loginHome">
    <div class="tname">
      <div  :class="companyId == 1 ? 'loginHome_top' : 'loginHome_top1'">
        <!-- <img class="imglogo" src="../../assets/image/logo.png" /> <span class="logoname">智慧燃气</span> -->
      <div class="rightall">
        <div class="entrance" @click="entranceClick" v-if="BIbutton">BI展示</div>
        <div></div>
        <div class="righyitem">
           <p> <img src="../../assets/image/user.png" style="width:36px;margin-right:10px"></p>
            <el-dropdown class="administrator">
              
                <span class="el-dropdown-link" >
                    
                    <span style="color:#ffffff">{{userName}}</span
                    ><i
                    style="color: #ffffff"
                    class="el-icon-arrow-down el-icon--right"
                    ></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item>
                    <div @click="login_out">退出</div>
                    </el-dropdown-item>
                </el-dropdown-menu>
                </el-dropdown>
        </div>
       </div>
      </div>
    </div>
    <div class="content">
        <ul class="showlist">
         <!--    <li v-for="(item,index) of MenuArray" :key="index" :class="selectItem==index?'itemli':'itemli'"> 
                <div class="item" @click="pathTo(item,index)">
                    <img :src="item.imgsrc" style="border:none;box-shadow:none;border-radius:0px"/>
                    <p class="title" style="border:none;box-shadow:none;border-radius:0px">{{item.name}}</p>
                </div>
                
            </li> -->
            <li v-for="(item,index) of MenuArray" :key="index" > 
                <div :class="'item item'+(index+1)" @click="pathTo(item,index)">
                    <img :src="item.menuOpenIcon" class="itemImg"/>
                    <p class="title" >{{item.name}}</p>
                </div>
                
            </li>
            <!-- <li> 
                <div class="item item1">
                    <img src="../../assets/image/1.png">
                    <p class="title">报装系统</p>
                </div>
                
            </li>
            <li> 
                <div class="item item2">
                    <img src="../../assets/image/2.png">
                    <p class="title">工程管理</p>
                </div>
                
            </li>
           <li> 
                <div class="item item3">
                    <img src="../../assets/image/3.png">
                    <p class="title">巡线巡检</p>
                </div>
                
            </li>
           <li> 
                <div class="item item4">
                    <img src="../../assets/image/4.png">
                    <p class="title">入户安检系统</p>
                </div>
                
            </li>
           <li> 
                <div class="item item5">
                    <img src="../../assets/image/5.png">
                    <p class="title">强维修系统</p>
                </div>
                
            </li>
           <li> 
                <div class="item item6">
                    <img src="../../assets/image/6.png">
                    <p class="title">客服派单</p>
                </div>
                
            </li>
           <li> 
                <div class="item item7">
                    <img src="../../assets/image/7.png">
                    <p class="title">GIS系统</p>
                </div>
                
            </li>
            <li> 
                <div class="item item8">
                    <img src="../../assets/image/8.png">
                    <p class="title">系统设置</p>
                </div>
                
            </li> -->
        </ul>

    </div>
  </div>
</template>

<script>
import {
  LoginOut,
  updateUserPassword,
} from "../../RequestPort/Login/LoginRequest";
export default {
  name: "indexBi",
        data(){
            return {
                HeaderBool:true,
                MenuArray:[],
                userName:'',
                selectItem:0,
                BIbutton:false,
                companyId:''
            }
        },
        methods:{
             getMun(){
                this.MenuArray = JSON.parse(localStorage.getItem('MenuTree'))
                let that = this
                console.log(this.MenuArray)
                if(this.MenuArray){
                    this.MenuArray.forEach((item,index)=>{
                        if(item.url == 'BI') {
                            that.MenuArray.splice(index, 1)
                            that.BIbutton = true
                        }
                        if(item.url=='contract'){
                            item.imgsrc=require('../../assets/image/2.png');
                        }
                        if(item.url=='setting'){
                            item.imgsrc=require('../../assets/image/8.png');
                        }
                        if(item.url=='report'){
                            item.imgsrc=require('../../assets/image/1.png');
                        }
                        if(item.url=='homecheck'){
                            item.imgsrc=require('../../assets/image/4.png');
                        }
                        if(item.url=='lineMalking'){
                            item.imgsrc=require('../../assets/image/3.png');
                        }
                      if(item.url=='lineworking'){
                        item.imgsrc=require('../../assets/image/3.png');
                      }
                         if(item.url=='gismanage'){
                            item.imgsrc=require('../../assets/image/3.png');
                        }
                        if(item.url=='Business'){
                            item.imgsrc=require('../../assets/image/4.png');
                        }
                        if(item.url=='serviceOrder'){
                            item.imgsrc=require('../../assets/image/indexkf.png');
                        }
                      if(item.url=='serviceOrderManger'){
                        item.imgsrc=require('../../assets/image/repair.png');
                      }
                    })
                }else{
                    this.$message.error('请重新登录')
                    this.$router.push('/login')
                }
            },
            pathTo(item,index){
                let that = this
                localStorage.setItem("showTitle",item.name);
                localStorage.setItem("tabIndex",index);
                localStorage.setItem("SelectMenuArray",JSON.stringify(this.MenuArray[index].children));
                this.$store.dispatch('clearVisitedView', [])
                if(item.name == "SCADA"){
                    let hasDataBoard = item?.children?.[0]['code'] == 'scadaBoard' ? true : false
                    if(hasDataBoard){
                        that.$router.push('/home/dataBoard')
                    }else{
                        that.$router.push('/home/logohome') 
                    }
                }else{
                    this.$router.push('/home/logohome');
                }
            },
            entranceClick(){
                this.$router.push('/dataBoard');
            },
            login_out() {
                LoginOut({}).then((res) => {
                    localStorage.removeItem("MenuTree");
                    localStorage.removeItem("tokenName");
                    localStorage.removeItem("tokenValue");
                    localStorage.removeItem("userName");
                    localStorage.removeItem("userId");
                    localStorage.removeItem("SelectMenuArray");
                    localStorage.removeItem("tabIndex");
                    localStorage.removeItem("showTitle")
                    this.$store.commit('changeSocketState', false)
                    this.$message({
                    message: "注销成功",
                    duration: 1500,
                    });
                    setTimeout(() => {
                    this.$router.push("/login");
                    }, 1500);
      });
    },

        },
        mounted(){
           this.getMun();
           this.selectItem = localStorage.getItem("tabIndex")?localStorage.getItem("tabIndex"):0;
            
           this.userName = localStorage.getItem("userName");
            this.companyId=localStorage.getItem('zlCompanyId')
        }
}
</script>

<style  scoped lang="scss">
.rightall{
    font-size:15px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 13px;
}
.entrance{
    width: 108px;
    height: 38px;
    line-height: 38px;
    text-align: center;
    background: url('../../assets/biIndex/entrance1.png') top center no-repeat;
    background-size: 100% 100%;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    font-style: italic;
    color: #FFFFFF;
    cursor: pointer;
}
.entrance:hover{
    background: url('../../assets/biIndex/entrance.png') top center no-repeat;
}
.administrator{height:30px;}
.loginHome{
  width: 100%;
  height: 100vh;
  color: #f4f9ff;
  background-color: #f4f9ff;
  background: url('../../assets/biIndex/bg.png') center center no-repeat;
}
.loginHome_top{
    width:100%;
    background: url('../../assets/biIndex/top.png') top center no-repeat;
    background-size: 100% 100%;
    height: 268px;
}
.loginHome_top1{
    width:100%;
    background: url('../../assets/biIndex/top1.png') top center no-repeat;
    background-size: 100% 100%;
    height: 268px;
}
.content{
    width:100%;
    height: 100%;
    background: none; 
    background: url('../../assets/biIndex/vehicle.png') top center no-repeat;
    position: fixed;
    top: 0.5rem;
    z-index: 2;
    border: 0;
    // border: none;
    // overflow: auto;text-align: center;
}
.righyitem{
  margin-right:20px;
  display:flex;
  justify-content:right;
  align-items: center;
  color: #0255BF;
}
.tname {
  font-size: 0.5rem;
  text-align: center;
  z-index: 1;
//   height: 76px;
//   background-color: #fff;
//   div {
//     display: flex;
//     justify-content:right;
//     align-items: center;
    
//     margin: 0 auto;
//     .imglogo {
//       margin-left: 8%;
//     }
//   }
}
.logoname{font-size:36px;margin-left:10px;color:#0255BF;width: 300px;text-align: left;}
.showlist{
    // padding-top: 245px;
        width: 887px;
    margin: 197px auto 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    // background-color: #fff;
    align-items: center;
    }
// .showlist li{ text-align: center;margin: 10px;}
.itemli{cursor: pointer; 
    // background-color: #fff;
    // color: #0F0F0F;
    font-size: 24px;
    font-weight: bold;
    color: #FFFFFF;
    width: 200px;
    height: 186px;
    // border-radius: 8px;
    // box-shadow: 0px 0px 9px 1px rgba(18, 101, 201, 0);
    // border: 1px solid #ffffff;
}
.itemli:hover{
    border: 1px solid #096FF2;
    box-shadow: 0px 0px 9px 1px rgba(18, 101, 201, 0.4);
    background: #0255BF;
    color: #0F71E2;
}

.title{text-align: center;font-size: 24px;}
.item img{width:70px ;height: 70px;margin-top: 22px}
// .item p{}
.select{
    border: 1px solid #096FF2;
box-shadow: 0px 0px 9px 1px rgba(18, 101, 201, 0.4);
border-radius: 8px;

}
.item {
    height: 229px;
        position: relative;
        cursor: pointer;
}
.item1{
    width: 301px;
    // background: url('../../assets/biIndex/nobackdrop1.png') top center no-repeat;
    // background-size: 100px 100px;
    // background-position: 54% 49%;
    p{
            position: absolute;
            bottom: 34px;
            left: 38%;
    }
    .itemImg{
        width: 120px;
        height: 100px;
        position: absolute;
        top: 15%;
        left: 50%;
        margin-left: -49px;
    }
}
.item1:hover{
    background: url('../../assets/biIndex/backdrop1.png') top center no-repeat;
    background-size: 100% 100%;
}
.item2{
    width: 285px;
    // background: url('../../assets/biIndex/nobackdrop2.png') top center no-repeat;
    // background-size: 100px 100px;
    // background-position: 47% 49%;
    p{
            position: absolute;
            bottom: 34px;
            left: 40%;
    }
    .itemImg{
        width: 120px;
        height: 100px;
        position: absolute;
        top: 15%;
        left: 50%;
        margin-left: -49px;
    }
}
.item2:hover{
    background: url('../../assets/biIndex/backdrop2.png') top center no-repeat;
    background-size: 100% 100%;
}
.item3{
    width: 299px;
    // background: url('../../assets/biIndex/nobackdrop3.png') top center no-repeat;
    // background-size: 100px 100px;
    // background-position: 47% 49%;
    p{
            position: absolute;
            bottom: 34px;
            left: 30%;
    }
    .itemImg{
        width: 120px;
        height: 100px;
        position: absolute;
        top: 15%;
        left: 40%;
        margin-left: -49px;
    }
}
.item3:hover{
    background: url('../../assets/biIndex/backdrop4.png') top center no-repeat;
    /* background: url('../../assets/biIndex/backdrop3.png') top center no-repeat; */
    background-size: 100% 100%;
}
.item4{
    width: 301px;
    // background: url('../../assets/biIndex/nobackdrop4.png') top center no-repeat;
    // background-size: 100px 100px;
    // background-position: 42% 50%;
    p{
            position: absolute;
            bottom: 64px;
            left: 43%;
    }
    .itemImg{
        width: 120px;
        height: 100px;
        position: absolute;
        top: 4%;
    left: 50%;
    margin-left: -30px;
    }
}
.item4:hover{
    background: url('../../assets/biIndex/backdrop5.png') top center no-repeat;
    background-size: 100% 88%;
}
.item5{
    width: 285px;
    height: 204px;
    top: -12px;
    // background: url('../../assets/biIndex/nobackdrop5.png') top center no-repeat;
    // background-size: 100px 100px;
    // background-position: 65% 29%;
    p{
            position: absolute;
            bottom: 34px;
            left: 36%;
    }
    .itemImg{
        width: 120px;
        height: 100px;
        position: absolute;
        top: 4%;
    left: 50%;
    margin-left: -44px;
    }
}
.item5:hover{
    background: url('../../assets/biIndex/backdrop6.png') top center no-repeat;
   background-size: 100% 100%;
}
.item6{
    width: 301px;
    height: 204px;
    top: -10px;
    // background: url('../../assets/biIndex/nobackdrop6.png') top center no-repeat;
    // background-size: 100px 100px;
    // background-position: 47% 27%;
    p{
            position: absolute;
            bottom: 34px;
            left: 27%;
    }
    .itemImg{
        width: 120px;
        height: 100px;
        position: absolute;
        top: 4%;
    left: 46%;
    margin-left: -65px;
    }
}
.item6:hover{
    background: url('../../assets/biIndex/backdrop8.png') top center no-repeat;
    background-size: 100% 100%;
}
.item7{
    width: 221px;
    height: 204px;
    // background: url('../../assets/biIndex/nobackdrop7.png') top center no-repeat;
    // background-size: 100px 100px;
    // background-position: 47% 29%;
    p{
            position: absolute;
            bottom: 34px;
            left: 27%;
    }
    .itemImg{
        width: 120px;
        height: 100px;
        position: absolute;
        top: 4%;
    left: 50%;
    margin-left: -65px;
    }
}
.item7:hover{
    background: url('../../assets/biIndex/backdrop7.png') top center no-repeat;
    background-size: 100% 100%;
}
.item8{
    width: 280px;
    height: 204px;
    // background: url('../../assets/biIndex/nobackdrop8.png') top center no-repeat;
    // background-size: 100px 100px;
    // background-position: 31% 29%;
    p{
            position: absolute;
            bottom: 34px;
            left: 17%;
    }
    .itemImg{
        width: 120px;
        height: 100px;
        position: absolute;
        top: 4%;
    left: 50%;
    margin-left: -95px;
    }
}
.item8:hover{
    background: url('../../assets/biIndex/backdrop8.png') top center no-repeat;
    background-size: 100% 100%;
}
</style>
