/*
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-12-03 13:46:16
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2023-06-26 09:39:48
 * @FilePath: \linqingpc\src\global.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
//  let baseUrl = 'http://120.27.16.250:8106'
//let baseUrl ='http://101.42.243.2:8106'
// let socketUrl = "ws://192.168.110.246:8213/messageWebsocket"
// let baseUrl = 'http://192.168.111.40:8212' //超哥
// let baseUrl = 'http://192.168.111.38:8212' //管明杨
// let socketUrl = "ws://192.168.111.34:8213/messageWebsocket"
let baseUrl ='http://192.168.110.246:8212'
//let videoUrl ='http://106.41.119.10:17358'
let videoUrl ='https://www.zhonglianranqi.com/zlmediakit/'
// let baseUrl ='http://192.168.110.18:8212' // 杨立国
// let baseUrl ='http://192.168.111.33:8212'// 刘亚林
// let baseUrl ='http://192.168.111.134:8212'// 张羽
// let baseUrl ='http://101.42.243.2:8212'
let socketUrl = "ws://192.168.110.246:8213"
//let gisUrl ='http://192.168.110.228:7722'
// let gisUrl ='http://192.168.110.246:7722'
let gisUrl ='http://106.41.119.10:8931'
let baseUrlcom = 'http://192.168.110.246:8212'
//let gisUrl ='http://192.168.110.177:8106'
// let baseUrl ='http://192.168.110.228:8106'
let geoUrl=''

if(location.href.indexOf('192.168.111.37')>=0||location.href.indexOf('localhost')>=0){
    baseUrl = 'http://192.168.110.251:8106' // 本地
    socketUrl = "ws://192.168.110.251:8107"
}else if(location.href.indexOf('192.168.110.246')+1){
    // 测试
    baseUrl = 'http://192.168.110.246:8212'
    socketUrl = "ws://192.168.110.246:8213"
    baseUrlcom = 'http://192.168.110.246:8212'
}else if(location.href.indexOf('192.168.110.251')+1){
    baseUrl = 'http://192.168.110.251:8106'
    socketUrl = "ws://192.168.110.251:8107"
    baseUrlcom = 'http://192.168.110.251:8106'
}else if(location.href.indexOf('lq.gaspim.com')+1){
    baseUrl = 'http://120.27.16.250:8106'
    socketUrl = "ws://120.27.16.250:8107"
    baseUrlcom = 'http://120.27.16.250:8106'
}else if(location.href.indexOf('47.93.117.159')+1){
    baseUrl ='http://123.56.135.31:8106'
    socketUrl = "ws://123.56.135.31:8107"
    baseUrlcom = 'http://123.56.135.31:8106'
}else if(location.href.indexOf('t7.gaspim.cn')+1){
    // 演示
    baseUrl ='http://101.42.243.2:8212'
    socketUrl = "ws://101.42.243.2:8213"
    baseUrlcom = 'http://101.42.243.2:8212'
}else if(location.href.indexOf('https://www.zhonglianranqi.com')+1){
    //正式
    baseUrl = 'https://www.zhonglianranqi.com/thzlback' // 本地
    baseUrlcom = 'https://www.zhonglianranqi.com'
    socketUrl = "wss://www.zhonglianranqi.com/thzlwbsocket"
}else if(location.href.indexOf('http://106.41.119.10')+1){
    //正式
    baseUrl = 'http://106.41.119.10:8931/thzlback' // 本地
    baseUrlcom = 'http://106.41.119.10:8931'
    socketUrl = "ws://106.41.119.10:8931/thzlwbsocket"
}

// baseUrl = 'http://192.168.110.246:8212'
//     socketUrl = "ws://192.168.110.246:8213/messageWebsocket"
 //  baseUrl = 'http://192.168.110.4:8106'
const global = {
  baseUrl,socketUrl,gisUrl,geoUrl,videoUrl,baseUrlcom
}

export default global
