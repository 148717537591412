const state = {
  legendSelect: [], //图例
  groupLayer: [], //图层组
  notGroupLayer:[],//没选中的图册组
  currentLayer: '', //图层组当前图层
  sketchType: [],
  activeOptionItem: '', //吸附状态
  isLegendSelect: [],
  pulginLoadGis: false,
  currentLayerName:'',
  onresize:false,
  fullState:true
}

const mutations = {
  pulginLoadGisChange: (state, data) => {
    state.pulginLoadGis = data
  },
  legendSelectChange: (state, data) => {
    state.legendSelect = data
  },
  isLegendSelectChange: (state, data) => {
    state.isLegendSelect = data
  },
  groupLayerChange: (state, data) => {
    if (data && data.length > 0) {
      state.groupLayer = data
    } else {
      state.groupLayer = [{ id: -100 }]
    }
  },
  notGroupLayerChange: (state, data) => {
    if (data && data.length > 0) {
      state.notGroupLayer = data
    } else {
      state.notGroupLayer = [{ id: -100 }]
    }
  },
  currentLayerChange: (state, data) => {
    state.currentLayer = data
  },
  currentLayerNameChange:(state, data)=>{
    state.currentLayerName = data
  },
  sketchTypeChange: (state, data) => {
    state.sketchType = data
  },
  activeOptionChange: (state, data) => {
    state.activeOptionItem = data
  },
  onresizeChange:(state, data) => {
    state.onresize = data
  },
  FullStateChange:(state, data) => {
    state.fullState = data
  }
}

const actions = {
  pulginLoadGisChange: (state, data) => {
    commit('pulginLoadGisChange', data)
  },
  legendSelectChange: ({ commit }, data) => {
    commit('legendSelectChange', data)
  },
  isLegendSelectChange: ({ commit }, data) => {
    commit('isLegendSelectChange', data)
  },
  groupLayerChange: ({ commit }, data) => {
    commit('groupLayerChange', data)
  },
  notGroupLayerChange: ({ commit }, data) => {
    commit('notGroupLayerChange', data)
  },
  currentLayerChange: ({ commit }, data) => {
    commit('currentLayertChange', data)
  },
  currentLayerNameChange: ({ commit }, data) => {
    commit('currentLayerNameChange', data)
  },
  sketchTypeChange: ({ commit }, data) => {
    commit('sketchType', data)
  },
  activeOptionChange: ({ commit }, data) => {
    commit('activeOptionItem', data)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
