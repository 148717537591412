const state = {
  //选中工具栏
  CurrentlySelectedToolBar: {
    Type: '', //选中的工具栏svg类型
    TypeName: '', //选中的工具栏svg类型名称
    Identity: '', //选中的工具栏svg标识
    Title: '', //选中的工具栏svg标题
    Color: '', //选中的工具栏svg颜色
    CreateType: '', //选中工具栏的创建方式
    EChartsOption: '' //选中工具栏的图表默认option
  }
}

const mutations = {
  setCurrentlySelectedToolBarAction: (state, data) => {
    state.CurrentlySelectedToolBar = data
  },
  clearCurrentlySelectedToolBarAction: (state, data) => {
    let item = {
      Type: '',
      Identity: '',
      TypeName: '',
      Title: '',
      Color: '',
      CreateType: '',
      EChartsOption: '',
      extend_attr: ''
    }
    state.CurrentlySelectedToolBar = item
  }
}

const actions = {
  setCurrentlySelectedToolBarAction: ({ commit }, data) => {
    commit('setCurrentlySelectedToolBarAction', data)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
