<template>
  <div></div>
</template>
<script>
import BASEDATA from "../../../constants/index";
import { userPath } from "@/apis/commonType";
import {  personList } from "@/RequestPort/maintenance/task";
export default {
  name: "Wms",
  components: {},
  props: {
    layers: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      wms: null,
      showUser: false,
      vectorLayerUser: null,
      end: require("@/assets/end.png"),
      start: require("@/assets/start.png"),
      labelsLayer: null,
      wmsAlone: null,
      layerNamelas: "",
      // 暂存更改树
      wmsLayerList: "",
      treeLayer:'',
      needReload:false,
      filter:'',
      layerNumber:'',
      LabelsLayer:null,
      wmsUser:null,
      layerParmas:{}
      
    };
  },
  computed: {},
  watch: {
    '$store.getters.layerTree' (val){
        if(this.needReload){
           if(val.length>0){
            this.treeLayer=val.toString()
           }else{
            this.treeLayer=''
           }
          this.wmlineWalking(this.filter,this.layerNumber)
        }else{
          this.needReload=true
        }
    }
  },
  destroyed() {
    this.$eventBus.$off("wmsLayer");
    this.$eventBus.$off("clearLayer");
    this.$eventBus.$off("reloadLayer");
    this.$eventBus.$off("wmsLayerAlone");
    this.$eventBus.$off("wmlineWalking");
    this.$eventBus.$off("getUserList");
    
    clearInterval(this.userTime);
  },
  mounted() {
    //人员
    this.$eventBus.$on("personList", (data) => {
      this.personList();
    });
    // 点击左侧复选（原来）
    this.$eventBus.$on("wmsLayer", (data) => {
      this.clearLayer();
      this.wmsLayerList = data;
      this.wmsLayer(data);
    });
    // 人员巡检轨迹
    this.$eventBus.$on("wmsLayerers", (data) => {
      // this.clearLayer();
      // this.wmsLayerList = data;
      this.wmsLayerers(data.sql);
    });
    this.$eventBus.$on("clearLayer", (data) => {
      this.clearLayer();
    });
    this.$eventBus.$on("reloadLayer", (data) => {
      this.reloadLayer();
    });
    // 有查询条件
    this.$eventBus.$on("wmsLayerAlone", (data) => {
      this.clearLayer();
      this.wmsLayerAlone(data.sql, data.layer);
    });
    // 有查询条件
    this.$eventBus.$on("wmlineWalking", (data) => {
     
      this.clearLayer();
      this.wmlineWalking(data.sql, data.layer);
    });
  },
  methods: {
    clearLayer() {
      if (this.wms) {
        this.$parent.map.remove(this.wms);
      }
    },
    reloadLayer() {
      let that = this;
      //let zoom = that.$parent.map.getZoom();
     // that.$parent.map.setZoom(zoom);
      this.$nextTick(function () {
      // let layers=that.$parent.map.getLayers()
      // let objLayer=null
      // layers.forEach((item)=>{
      //   if(item.CLASS_NAME== "AMap.TileLayer.WMS"){
      //      objLayer=item
      //   }        
      // })
      // if(objLayer){
      //   let str=objLayer['_tileUrl']
      //   let arr=str.split('?')
      //   let valuestr=arr[1]
      //   let valuearr=valuestr.split('&')
      //   let p={}
      //   valuearr.forEach(item => {
      //     let itemarr=item.split('=')
      //     p[itemarr[0]]=itemarr[1]
      //   });
      // }
      console.log(this.layerParmas,"4444444444444444444")
        if(this.layerParmas){
          let newl=JSON.parse(JSON.stringify(this.layerParmas))
          let filter=newl.CQL_FILTER
          const s=new Date().getTime()
          filter =filter+ ` and ${s} = ${s}`
          let p=newl
          p.CQL_FILTER=filter
          this.wms.setParams(p)
          that.wms.reload();
        }
        
      });
    },
    // 原视图
    wmsLayer(layer) {
      if (!layer) {
        layer = this.layers;
      }
      if(this.layerNamelas=='gis_line_view'){
           let list = layer.split(",");
            let index = list.findIndex((el) => el == "'patrol_lines'");
            list.splice(index, 1);
            layer = list.join(",");
      }else{
          if (layer && layer.indexOf(",") != -1 && this.layerNamelas) {
            let list = layer.split(",");
            let index = list.findIndex((el) => el == `'${this.layerNamelas}'`);
            list.splice(index, 1);
            layer = list.join(",");
          }
       }
      // this.$store.commit("layersChange", layer);
      if(layer){
        this.$store.commit("layersChange", layer);
      }else{
        this.$store.commit("layersChange",'noQuery');
      }
      this.setVector(layer);
      // gas:checkProject
      let p={
          LAYERS: "gas:checkProject", //layer,
          VERSION: "1.1.1",
          TRANSPARENT: true,
          timestamp: Date.parse(new Date()),
          CQL_FILTER: `table_type in(${layer}) and general_status=1`,
        }
      this.layerParmas=p
      this.wms = new AMap.TileLayer.WMS({
        url: "/geoserver/wms",
        tileSize: 512,
        dataZooms: [0, 20],
        zooms: [0, 20],
        zIndex: 5,
        params: p,
      });
      this.$parent.map.add(this.wms);
    },
    // 人员巡检轨迹
    wmsLayerers(filter, layerName) {
      if (this.wmsUser) {
        this.$parent.map.remove(this.wmsUser);
      } 
      this.wmsUser = new AMap.TileLayer.WMS({
        url: "/geoserver/wms",
        tileSize: 512,
        dataZooms: [0, 20],
        zooms: [0, 20],
        zIndex: 5,
        params: {
          LAYERS: "linqing:view_user_track", //layer,
          VERSION: "1.1.0",
          TRANSPARENT: true,
          timestamp: Date.parse(new Date()),
          CQL_FILTER: filter,
        },
      });
      this.$parent.map.add(this.wmsUser);
    },
    // 告警设备视图
    wmsLayerWarn(layer) {
      if (!layer) {
        layer = this.layers;
      }
      if (layer && layer.indexOf(",") != -1 && this.layerNamelas) {
        let list = layer.split(",");
        let index = list.findIndex((el) => el == `'${this.layerNamelas}'`);
        list.splice(index, 1);
        layer = list.join(",");
      }
      this.$store.commit("layersChange", layer);
      this.setVector(layer);
      let p={
          LAYERS: "gas:gs_alarm_equipment", //layer,
          VERSION: "1.1.1",
          TRANSPARENT: true,
          timestamp: Date.parse(new Date()),
          CQL_FILTER: `1=1`,
        }
      this.layerParmas=p
      this.wms = new AMap.TileLayer.WMS({
        url: "/geoserver/wms",
        tileSize: 512,
        dataZooms: [0, 20],
        zooms: [0, 20],
        zIndex: 5,
        params: p,
      });
      this.$parent.map.add(this.wms);
    },
    // 临清巡线——管线
    wmlineWalking(layer,layerNumber) {
    
   
      // const s= Math.random()
      this.filter=layer
      const s=new Date().getTime()
      let all = `${s} = ${s}`
     // let all = `1 = 1`
      if (layer) {
        all = all+' and '+layer;
      }
       this.layerNumber=layerNumber
      let allNode1 = this.$parent.$refs.search.allNode
      let check = this.$store.getters.layerTree
      let notCheck = []
      allNode1.forEach(el=>{
        if(check.indexOf(el)<0){
          notCheck.push(el) 
        }
      })
      if(notCheck.length>check.length){
        if(this.treeLayer ){
          if(all){
            all=all+  " and coverage in("+this.treeLayer+")"
            //  all=all+ " and ( concat( ',',"+ this.treeLayer+ "',' ) REGEXP concat( ',', REPLACE ( coverage, ',', ',|,' ), ',' )) > 0"
          }else{
            all="'coverage' in("+this.treeLayer+")"
            // all= " ( concat( ',',"+ this.treeLayer+ "',' ) REGEXP concat( ',', REPLACE ( coverage, ',', ',|,' ), ',' )) > 0"
          }
        
        }
      }else{
        if(notCheck&&notCheck.length>0){
          if(all){
            all=all+  " and coverage not in("+notCheck.toString()+")"
          }else{
            all="'coverage' not in("+notCheck.toString()+")"
          }
        }
        
      }
      
      
       this.getUserList()
      
      this.$store.commit('setfilterAllTree', all)
      // if(this.wms){
      //   this.$parent.map.remove(this.wms);
     
      // }
      //  const s=new Date().getTime()
      //  all =all+ ` and ${s} = ${s}`
     
      let p = {
          LAYERS: layerNumber,//"linqing:view_all_line", //layer,
          VERSION: "1.1.1",
          TRANSPARENT: true,
       //   timestamp: Date.parse(new Date()),
          CQL_FILTER: all,
        }
        this.layerParmas=p
        if(this.wms&& this.$parent.map.hasLayer(this.wms)){
          this.wms.setParams(p)
          this.wms.reload()
        }else{
          this.wms = new AMap.TileLayer.WMS({
            url: "/geoserver/wms",
            tileSize: 512,
            dataZooms: [0, 20],
            zooms: [0, 20],
            zIndex: 5,
            params: p
          });
          // this.$parent.map.add(this.wms);
          this.wms.setMap(this.$parent.map)
        }
    },
    // 计划安排所属
    department(layer,layerNumber,posStr,layersVue1,networkId) {
      const s= Math.random()
      this.filter=layer
      let all = `${s} = ${s}`
      if (layer) {
        all = all+' and '+layer;
      }
       this.layerNumber=layerNumber
       if(this.$store.getters.setLayersqles) {
          all = all +' and '+ this.$store.getters.setLayersqles
       }
       // 跳转页面后的情况
       if(posStr) {
        // 'event','user',
          all = all + " and coverage in ("+ layersVue1 + ",'clock') and INTERSECTS(location,POLYGON((" + posStr + "))) and tableName <> 'tb_basic_pipe' and tableName <> 'patrol_lines'"
       }
       if(networkId){
        // let tableName= ''
         all = `${s} = ${s}` + " and coverage in ("+ layersVue1 + ",'clock') and INTERSECTS(location,POLYGON((" + posStr + ")))  and (((network_id in ("+ networkId+") and tableName = 'patrol_clock') or (tableName <> 'patrol_clock' and tableName <> 'tb_basic_pipe' and tableName <> 'patrol_lines'))) and (((id in ("+ networkId+") and tableName = 'table_name') or (tableName <> 'table_name' and tableName <> 'tb_basic_pipe' and tableName <> 'patrol_lines')))"
       //   all = `${s} = ${s}` + "  and coverage in ("+ layersVue1 + ",'clock') and INTERSECTS(location,POLYGON((" + posStr + ")))"
       }
       //(network_id in ("+ networkId+") and tableName = 'patrol_clock') or
      let p={
          LAYERS: layerNumber,//"linqing:view_all_line", //layer,
          VERSION: "1.1.1",
          TRANSPARENT: true,
       //   timestamp: Date.parse(new Date()),
          CQL_FILTER: all,
        }
      this.layerParmas=p
      this.wms = new AMap.TileLayer.WMS({
        url: "/geoserver/wms",
        tileSize: 512,
        dataZooms: [0, 20],
        zooms: [0, 20],
        zIndex: 5,
        params: p,
      });
      let that = this
      setTimeout(() => {
        that.$parent.map.add(this.wms);
      },500)
      
    },
    getUserList(){
      let that = this;
    
        if(this.treeLayer&&this.treeLayer.indexOf('user')==-1){
          if(that.LabelsLayer){
            that.LabelsLayer.clear()
          }
          return
        }
        let  PersonAjaxData= {
        name: "",
        onlineState: "",
        trackState: "",
        current: 1,
        size: 100000,
      }
      personList(PersonAjaxData).then((e) => {
           // this.PersonList = e.data.records;
        
          let userList = e.data.records
          if (that.LabelsLayer) {
            that.LabelsLayer.clear()
          } else {  
            that.LabelsLayer = new AMap.LabelsLayer({
              collision: false,
              allowCollision: false
            })

            that.$parent.map.add(that.LabelsLayer)
          }
          let label = []
          let icon = require('@/assets/legend/xjr.png')
          if(userList&&userList.length>0){
          userList.forEach(el=>{
            if(el.location&&el.location.length>0){
            let poly1 = new AMap.LabelMarker({
                  position:[Number(el.location[0]),Number(el.location[1])],
              //  position:[117.608765,39.700866],
                  icon: {
                    type: 'image',
                    image: icon,
                    anchor: 'bottom-center'
                  },
                  zIndex: 160,
                  extData:el
                })

                label.push(poly1)
          
                that.LabelsLayer.add(poly1)
                poly1.on('click', function(e) {
                let data = e.target.getExtData();
                  that.$parent.$refs.markInfo.itemKey ='linqing_view_user'
                  that.$parent.$refs.markInfo.openFeatrueInfo(e.target.getExtData(),e.lnglat)
                })
                  }
          })
          }
      })
    },
    wmsLayerAlone(filter, layerName) {
      this.layerNamelas = layerName;
      let layerNameLas = ''
      let sql
      if(layerName == 'gis_line_view'){
        layerNameLas = 'gas:gis_line_view'
        sql= filter ? filter+ " and general_status=1" : "1=1"
      } else {
        layerNameLas = `gas:${layerName}_jihe`
         sql= filter ? filter+ " and general_status=1" : "table_type in('"+ layerName+"') and general_status=1"
        
      }
      // if (this.layers != "'" + layerName + "'") {
      //   //过滤调layerName
      //   let layeres;
      //   if (this.wmsLayerList.indexOf(",") != -1) {
      //     layeres = this.wmsLayerList.replace(`'${layerName}',`, "");
      //   }
      //   // this.wmsLayer(layeres);
      // } else {
        this.clearLayer();
      // }
      
      let layer2={sql,name:layerNameLas}  
        // this.$store.commit("layers2Change", layer2);
      if (this.wmsAlone) {
        this.$parent.map.remove(this.wmsAlone);
      }
      let p={
          LAYERS: layerNameLas, //layerName,//layer,
          VERSION: "1.1.1",
          TRANSPARENT: true,
          timestamp: Date.parse(new Date()),
          CQL_FILTER: sql,
        }
      this.layerParmas=p
      this.wmsAlone = new AMap.TileLayer.WMS({
        url: "/geoserver/wms",
        tileSize: 512,
        dataZooms: [0, 20],
        zooms: [0, 20],
        zIndex: 5,
        params: p,
      });
      this.$parent.map.add(this.wmsAlone);
    },
    setVector(layer) {
      let that = this;
      if (layer && layer.indexOf("user") > 0) {
        that.showUser = true;
        that.getUserPath();
        that.userTime = setInterval(() => {
          that.getUserPath();
        }, 30000);
      } else {
        that.showUser = true;
        clearInterval(that.userTime);
        if (this.vectorLayerUser) {
          this.$parent.map.remove(this.vectorLayerUser);
        }
        if (this.labelsLayer) {
          this.$parent.map.remove(this.labelsLayer);
        }
      }
    },
    getUserPath() {
      let that = this;
      userPath({ onlineState: 0, type: 1, size: 10000 }).then((res) => {
        let group = [];
        let arr = res.data.records;
        if (that.vectorLayerUser) {
          that.$parent.map.remove(that.vectorLayerUser);
        }

        if (that.labelsLayer) {
          that.$parent.map.remove(that.labelsLayer);
        }
        let markers = [];
        arr.forEach((item) => {
         if (item.locations && item.locations.length > 0) {
            let startPoint = new AMap.LabelMarker({
              position: item.locations[0],
              zIndex: 20,
              icon: {
                size: [19, 30], // 图标尺寸
                anchor: "bottom-center",
                image: that.start, // Icon的图像地址
              },
            });
            markers.push(startPoint);
            let l = item.locations.length - 1;
            let endPoint = new AMap.LabelMarker({
              position: item.locations[l],
              zIndex: 21,
              icon: {
                size: [19, 30], // 图标尺寸
                anchor: "bottom-center",
                image: that.end,
              },
              text: {
                extData: { index: item.id },
                content: item.username,
                direction: "right",
                offset: [0, 0, 0, 0],
                style: {
                  fontSize: 12,
                  fillColor: "#fff",
                  strokeColor: "#000",
                  strokeWidth: 2,
                },
              },
            });
            markers.push(endPoint);
            //  that.$parent.map.add(endPoint)

            let poly = new AMap.Polyline({
              path: item.locations,
              isOutline: true,
              outlineColor: "#ffeeff",
              borderWeight: 2,
              strokeColor: "#0f930d",
              strokeOpacity: 1,
              strokeWeight: 3,
              strokeStyle: "solid",
              lineJoin: "round",
              lineCap: "round",
              zIndex: 1,
            });
            group.push(poly);
          }
        });
        that.labelsLayer = new AMap.LabelsLayer({
          zooms: [0, 20],
          zIndex: 160,
          collision: false,
          allowCollision: false,
        });
        that.labelsLayer.add(markers);
        that.vectorLayerUser = new AMap.OverlayGroup([...group]);
        that.$parent.map.add(that.labelsLayer);
        that.$parent.map.add(that.vectorLayerUser);
      });
    },
  },
};
</script>
