import Vue from 'vue'
import Vuex from 'vuex'
import gis from './gis'
import dict from './modules/dict'
import zt from './modules/zt'
Vue.use(Vuex)
const storeTree = {
  modules: {
    gis,
    dict,
    zt
  }
}
export default new Vuex.Store({
  
  state: {
    AsideArray:[],
    BottomPage:true,
    DnArray:[],
    DeArray:[],
    color: '',
    layers:'',
    pulginLoad:false,
    PolyItem:{},
    socketState:false,
    visitedViews:[],
    cancelTokenList:null,
   
    adsorb:true,
    cancelTokenList:null,
    layerTree:[],
    notLayerTree:[],
    filterAllTree:'',
    setLayersqles:'',
    setLayerRowes:{},
    deriveVisible:false
  },
  mutations: {
    setderiveVisible:(state,data) => {
      console.log(data)
      state.deriveVisible = data
    },
    setLayerTree(state, data){
      state.layerTree = data
    },
    setNotLayerTree(state, data){
      state.notLayerTree = data
    },
    setLayersql(state, data){
      state.setLayersqles = data
    },
    setLayerRow(state, data){
      state.setLayerRowes = data
    },
    setfilterAllTree(state, data){
      state.filterAllTree = data
    },
    setCancelTokenList(state, data){
      state.cancelTokenList = data
    },
    changeSocketState(state, data){
      state.socketState = data
    },
    ChangePoly(state,data){
        state.PolyItem = data
    },
    changeAdsorb(state, data){
      state.adsorb = data
    },
    BottomPageChange(state,data){
      state.BottomPage = data
    },
    ChangeAsideArray (state,data) {
      state.AsideArray = data
    },
    DnChangeData(state,data){
      state.DnArray = data
    },
    DeChangeData(state,data){
      state.DeArray = data
    },
    colorChange: (state, data) => {
      state.color = data
    },
    layersChange: (state, data) => {
      state.layers = data
    },
    pulginLoadChange: (state, data) => {
      state.pulginLoad = data
    },

    ADD_VISITED_VIEW: (state, view) => {
      if (state.visitedViews.some(v => v.path === view.path)) return
      state.visitedViews.push(
        Object.assign({}, view, {
          title: view.meta.name || 'no-name'
        })
      )
    },  
    CLEAR_VISITED_VIEW: (state, view) => {
      state.visitedViews=view
    },
    DEL_VISITED_VIEW: (state, view) => {
      for (const [i, v] of state.visitedViews.entries()) {
        if (v.path === view.path) {
          state.visitedViews.splice(i, 1)
          break
        }
      }
    },
  },
  actions: {
    colorChange: (state, data) => {
      commit('colorChange', data)
    },
    layersChange: (state, data) => {
      commit('layersChange', data)
    },
    
    clearVisitedView({ commit }, view) {
      commit('CLEAR_VISITED_VIEW', view)
    },
    delView({ dispatch, state }, view) {
      return new Promise(resolve => {
        dispatch('delVisitedView', view)
        resolve({
          visitedViews: [...state.visitedViews]
        })
      })
    },
    addVisitedView({ commit }, view) {
      commit('ADD_VISITED_VIEW', view)
    },
    delVisitedView({ commit, state }, view) {
      return new Promise(resolve => {
        commit('DEL_VISITED_VIEW', view)
        resolve([...state.visitedViews])
      })
    },
    cancelTokenList(state, data){
      commit('cancelTokenList', data)
    },
  },
  modules: {
    gis,
    dict,
    zt
  },
  
  getters:{
    deriveVisible:state => state.deriveVisible,
    GetBottomPage:state => state.BottomPage,
    color: state => state.color,
    layers: state => state.layers,
    pulginLoad: state => state.pulginLoad,
    getSocketState:state=>state.socketState,
    GetPoly:state=>state.PolyItem,
    adsorb:state=>state.adsorb,
    visitedViews: state => state.visitedViews,
    cancelTokenList: state => state.cancelTokenList,
    legendSelect: state => state.gis.legendSelect,
    currentLayer: state => state.gis.currentLayer,
    groupLayer: state => state.gis.groupLayer,
    notGroupLayer: state => state.gis.notGroupLayer,
    sketchType: state => state.gis.sketchType,
    activeOptionItem: state => state.gis.activeOptionItem,
    isLegendSelect: state => state.gis.isLegendSelect,
    pulginLoadGis: state => state.gis.pulginLoadGis,
    currentLayerName: state => state.gis.currentLayerName,
    layerTree: state => state.layerTree,
    notLayerTree: state => state.notLayerTree,
    setLayersqles:state => state.setLayersqles,
    setLayerRowes:state => state.setLayerRowes,
    filterAllTree: state => state.filterAllTree,
    onresize: state => state.gis.onresize,
    fullState: state => state.gis.fullState,
    CurrentlySelectedToolBar: state => state.zt.CurrentlySelectedToolBar
  }
})
