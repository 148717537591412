<template>
    <div>
     <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="60%"
      :close-on-click-modal="false"
      @close="clearnF('form')"
      @open="opDialog"
    >
      <div class="table">
        <el-tabs v-model="activeName" @tab-click="handleClick"  style="margin-left:25px">
            <el-tab-pane label="未读" name="one">
              <div style="text-align: left;width:100%">
              <el-button size="small"
                    @click="editAll(row,1)" 
                    >全部已读</el-button>
              </div>

                     <!--数据表格-->
           <div >
          <el-table
            ref="tableData"
            :data="tableData"
            style="width: 100%;margin: 15px 0;"
            height="450px"
            border
            :stripe="true"
            :index="(parseInt(search.current)-1)*parseInt(search.size)+1"
            
          >
           <el-table-column
                type="index"
                label="序号"
                align="center"
                >
                </el-table-column>
            <el-table-column
              prop="message"
              label="消息内容"
              align="center"
              show-overflow-tooltip
              
            >
            </el-table-column>
            <el-table-column
              prop="createTime"
              label="创建时间"
              align="center"
              show-overflow-tooltip
               width="200"
            >
            </el-table-column>
           
            <el-table-column prop="operation" label="操作" align="left" width="100">
              <template slot-scope="{ row }">
                   <el-button
                    type="text"
                    size="small"
                    style="color:#008000"
                    @click="edit(row,1)" 
                    >
                    {{row.remark ? '打开' : '确认已读'}}
                    </el-button>
                    
              </template>
            </el-table-column>
          </el-table>
          </div>
            </el-tab-pane>
            <el-tab-pane label="已读" name="two">
              <div class="table">
          <el-table
            ref="tableData"
            :data="tableData"
            height="450px"
            style="width: 100%;margin: 15px 0;"
            border
            :stripe="true"
            
          >
           <el-table-column
                type="index"
                label="序号"
                align="center"
                 :index="(parseInt(search.current)-1)*parseInt(search.size)+1"
                >
                </el-table-column>
            <el-table-column
              prop="message"
              label="消息内容"
              align="center"
              show-overflow-tooltip
              
            >
            </el-table-column>
            <el-table-column
              prop="createTime"
              label="创建时间"
              align="center"
              show-overflow-tooltip
               width="200"
            >
            </el-table-column>
           
            <el-table-column prop="updateTime" label="操作时间" align="center" width="200"> </el-table-column>
            <el-table-column prop="operation" label="操作" align="left" width="100">
              <template slot-scope="{ row }">
                   <el-button
                    v-if="row.remark"
                    type="text"
                    size="small"
                    style="color:#008000"
                    @click="edit2(row,1)" 
                    >
                    打开
                    </el-button>                    
              </template>
            </el-table-column>
          </el-table>
          </div>
            </el-tab-pane>
        </el-tabs>
         <div>
          <el-pagination
            :current-page="search.current"
            :background="true"
            :page-sizes="[30, 50, 100]"
            :page-size="search.size"
            layout="total, prev, pager, next, sizes, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
        
          
         
        
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {bzMessageConfig_pageUserMessage,bzMessageConfig_userMessageRead,bzMessageConfig_userMessageAllRead} from '../../RequestPort/message/message'
export default {
  components: { },
  name: 'applyList',
  mixins: [],
  data() {
    return {
      dialogVisible:false,
      title:'',
      form:{},
      activeName:'one',
      tableData:[],
      search: { current: 1, size: 100},
      total:10
    }
  },
  props: {
    id: {
      type: String,
    },
    type: {
      type: String,
    }
  },
  mounted() {
      
  },
  methods: {
      opDialog(){
        this.clearnF();
        if(this.$nextTick){
           this.getList(1)
            
        }
      },
      clearnF(){
        this.form={
        }
    },
    handleClick(){
      this.getList(1)
    },
    edit(row){
      bzMessageConfig_userMessageRead({id:row.id}).then(res=>{
        this.$message.success('已读成功！')
        this.getList(1)
        // row.remark ? window.location.href = row.remark : ''
        if(row.remark){
          this.downloadFile(row.remark,row.fileName)
        }
      })
    },
    edit2(row){
     
        // row.remark ? window.location.href = row.remark : ''
        if(row.remark){
          this.downloadFile(row.remark,row.fileName)
        }
    
    },
    downloadFile(sourceUrl,fileName) {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', sourceUrl, true);
      xhr.responseType = 'blob';
      xhr.onload = function () {
        if (xhr.status === 200) {
          const res = xhr.response;
          const link = document.createElement('a');
          link.style.display = 'none';
          const url = window.URL.createObjectURL(res);
          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          window.URL.revokeObjectURL(link.href);
          document.body.removeChild(link);
        }
      }
      xhr.send()
    },
    editAll(){
      
      if(this.tableData.length<=0){
        this.$message.warning('当前无未读消息')
        return;
      }
      bzMessageConfig_userMessageAllRead({}).then(res=>{
        this.$message.success('全部已读成功！')
        this.getList(1)
      })
    },
    getList(page){
      if(page){
        this.search.current = 1
      }
      this.search.statusCode = this.activeName=='one'?'0':'1'
      bzMessageConfig_pageUserMessage(this.search).then(res=>{
        this.tableData = res.data.records
        this.total = res.data.total
      })
    },
    setdialogVisible(flag){
          this.dialogVisible = flag;
    },
    handleSizeChange(val) {
      this.search.size = val
      this.getList(1)
    },
    handleCurrentChange(val) {
      this.search.current = val
      this.getList()
    },
  }
}
</script>
<style scoped>
.icon_line{
  width: 3px;
height: 16px;
background: #1082FF;
display: inline-block;
}
.title{
  font-weight: 400;font-size: 16px; margin-left: 5px;height: 16px; line-height: 16px;
}
.table {
  line-height: 30px;
}
</style>
