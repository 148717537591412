import axios from '@/common/js/request'
// 获取维修列表
export function maintenancePage(params){
    const maintenancePage = axios({
        url: '/zypatrol/maintenance/page',
        method: 'get',
        params
    })
    return maintenancePage
}
export function eventPage(params){ //事件分页列表
    const eventPage = axios({
        url: '/patrol/event/page',
        method: 'get',
        params
    })
    return eventPage
}
export function ByPostpeople(params){
    const ByPostpeople = axios({
        url: '/zypatrol/getUserList',
        method: 'get',
        params
    })
    return ByPostpeople
}
export function zypatrolGetUserList(params){ // 报送人
    // 1是不包括禁用的人 2是包括
    const zypatrolGetUserList = axios({
        url: '/zypatrol/getUserList',
        method: 'get',
        params
    })
    return zypatrolGetUserList
}
export function equipmentPage(params){
    const equipmentPage = axios({
        url: '/zypatrol/facility/equipment/page',
        method: 'get',
        params
    })
    return equipmentPage
}
export function Pagedetil(params){
    const Pagedetil = axios({
        url: '/zypatrol/maintenance/get',
        method: 'get',
        params
    })
    return Pagedetil
}
export function eventPagedetil(params){
    const eventPagedetil = axios({
        url: '/zypatrol/event/detail/get',
        method: 'get',
        params
    })
    return eventPagedetil
}
export function dicByType(params){
    const dicByType = axios({
        url: '/dict/selectDictListByParentCode',
        method: 'get',
        params
    })
    return dicByType
}
// 字典下拉 
export function patrolGetDict(params){
    const patrolGetDict = axios({
        url: '/patrol/getDict',
        method: 'get',
        params
    })
    return patrolGetDict
}
// 字典编辑
export function patrolEditDict(data){
    const patrolEditDict = axios({
        url: '/patrol/editDict',
        method: 'post',
        data
    })
    return patrolEditDict
}
export function transferRepair(data){
    const transferRepair = axios({
        url: '/arumData/transferRepair',
        method: 'post',
        data
    })
    return transferRepair
}
// 字典删除
export function batchDelDict(data){
    const batchDelDict = axios({
        url: '/patrol/batchDelDict',
        method: 'post',
        data
    })
    return batchDelDict
}
// 字典新增
export function addDict(data){
    const addDict = axios({
        url: '/patrol/addDict',
        method: 'post',
        data
    })
    return addDict
}
export function tasklist(params){
    const tasklist = axios({
        url: '/zypatrol/task/daily/list',
        method: 'get',
        params
    })
    return tasklist
}
export function taskpreview(params){
    const taskpreview = axios({
        url: '/zypatrol/task/month/preview',
        method: 'get',
        params
    })
    return taskpreview
}
export function deleteBatchgroup(data){
    const deleteBatchgroup = axios({
        url: '/zypatrol/maintenance/delete',
        method: 'post',
        data
    })
    return deleteBatchgroup
}
export function deleteBatchevent(data){
    const deleteBatchevent = axios({
        url: '/patrol/event/delete',
        method: 'post',
        data
    })
    return deleteBatchevent
}
export function savelistroup(data){
    const savelistroup = axios({
        url: '/zypatrol/maintenance/save',
        method: 'post',
        data
    })
    return savelistroup
}
// 设置巡检点
export function setcheckpoint(data){
    const setcheckpoint = axios({
        url:'/patrol/event/checkpoint/set',
        method: 'post',
        data
    })
    return setcheckpoint
}
export function closeevent(data){
    const closeevent = axios({
        url: '/patrol/event/close',
        method: 'post',
        data
    })
    return closeevent
}
export function uploadImg(data){
    const uploadImg = axios({
        url: '/aliYun/uploadGasPatrolImgToAliYunOSS',
        method: 'post',
        data
    })
    return uploadImg
}
export function managemaintenance(data){
    const managemaintenance = axios({
        url: '/zypatrol/maintenance/manage',
        method: 'post',
        data
    })
    return managemaintenance
}
// 增加事件
export function saveevent(data){
    const saveevent = axios({
        url: '/patrol/event/save',
        method: 'post',
        data
    })
    return saveevent
}
///patrol/event/excelEvents

export function excelEvents(params){
    const excelEvents = axios({
        url: '/patrol/event/excelEvents',
        method: 'get',
        params
    })
    return excelEvents
}
//zypatrol/picture/person/details // 人员列表
export function getPersonDetail(params){
    const excelEvents = axios({
        url: '/zypatrol/picture/person/list',
        method: 'get',
        params
    })
    return excelEvents
}
// 标记已读事件
export function markEvent(data){
    const saveevent = axios({
        url: '/patrol/event/markEvent',
        method: 'post',
        data
    })
    return saveevent
}
// 点击维修
export function saveMaintain(data){
    const saveevent = axios({
        url: '/orderInfo/saveOrder',
        method: 'post',
        data
    })
    return saveevent
}
// 设置已处理事件
export function setEvent(data){
    const setEvent = axios({
        url: '/patrol/event/setEvent',
        method: 'post',
        data
    })
    return setEvent
}
// 标记已提交维修工单
export function maintainEvent(data){
    const maintainEvent = axios({
        url: '/patrol/event/maintainEvent',
        method: 'post',
        data
    })
    return maintainEvent
}
// 巡线字典
export function findDictTreeLine(params){
    const findDictTree = axios({
        url: '/dict/findDictTree',
        method: 'get',
        params
    })
    return findDictTree
}
// 部门下拉 维修
// export function deptDropDown(params){
//     const deptDropDown = axios({
//         url: '/arumAddr/deptDropDown',
//         method: 'get',
//         params
//     })
//     return deptDropDown
// }
// 通过部门id查此部门及其下所有部门用户（人员下拉） 维修
export function selectAllUsersByDeptId(params){
    const selectAllUsersByDeptId = axios({
        url: '/arumData/selectAllUsersByDeptId',
        method: 'get',
        params
    })
    return selectAllUsersByDeptId
}
// 获取超时配置列表 维修
export function getTimeoutList(params){
    const getTimeoutList = axios({
        url: '/dispatch/timeout/getTimeoutList',
        method: 'get',
        params
    })
    return getTimeoutList
}
// 事件转维修 维修
export function eventMaintain(params){
    const eventMaintain = axios({
        url: '/patrol/event/eventMaintain',
        method: 'get',
        params
    })
    return eventMaintain
}