/*
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-06-17 08:21:43
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @LastEditTime: 2022-09-05 16:34:18
 * @FilePath: \linqingpc\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import myMixin from './common/js/InputLength'
import ElementUI from 'element-ui';
import moment from 'moment'
import 'element-ui/lib/theme-chalk/index.css';
import './common/css/AllFileCss.scss'
import './common/iconfont/iconfont.css'
import '@/common/styles/index.scss'
import '@/utils/flexible'
import constants from '@/constants'
import eventBus from '@/utils/eventBus'
Vue.use(ElementUI)
import lodash from 'lodash'
import service from './common/js/request'
import anthButtons from './common/anthButtons'
import Print from '@/utils/print'
Vue.use(Print) // 注册

import vueMiniPlayer from 'vue-mini-player'
import 'vue-mini-player/lib/vue-mini-player.css'
Vue.use(vueMiniPlayer)
// 引入echarts
import * as echarts from 'echarts'
import VideoPlayer from 'vue-video-player'
import Fragment from 'vue-fragment'

Vue.use(Fragment.Plugin)
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
Vue.use(VideoPlayer)
Object.defineProperty(Vue.prototype, '$moment', { value: moment })
Object.defineProperty(Vue.prototype, '$constants', { value: constants })
Object.defineProperty(Vue.prototype, '$eventBus', { value: eventBus })
Object.defineProperty(Vue.prototype, '$anthButtons', { value: anthButtons })

Vue.prototype.$service = service
Vue.prototype.$echarts = echarts
Vue.config.productionTip = false

Vue.prototype.$lodash = lodash
Vue.mixin(myMixin);

// main.js 文件
// import global from './common/js/socket'
// Vue.prototype.global = global


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
