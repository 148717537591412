export default {
  DATE_FORMAT: 'YYYY-MM-DD HH:mm:ss',
  EMPTY: '—',
  GROUP_NAME: 'gas:gasall',
  POINT_NAME: 'gas:v_gis_point',
  LINE_NAME: 'gas:view_layer_query',
  //KEY: 'ffc0506e06061bbe769da7047116ae0f',
  // KEY:'fb2c08a2f998f28d607627e88be22906',
  KEY:'471ee6305530cc80c91497952c8632d1',
  GEOSERVERHTTP:'http://47.93.96.126:7779/'
}
