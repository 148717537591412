<template>
  <div class="container"> 
    <img src="./../assets/image/logocontent.png">
    <router-view />
      <el-card class="box-card">
        <div class="more" @click="showList">查看更多</div>
          <ul class="showList">
            <li class="item" v-for="(item,index) in tableData" :key="index">
              <div>{{item.message}}</div>
              <div style="text-align: right;">
                <el-button  type="primary" plain size="mini" @click="readMsg(item)" class="prder_button">
                  {{item.remark ? '打开' : '已读'}}
                </el-button>
              </div>
            </li>
            
          </ul>
    </el-card>
      <msg-list ref="msgList"></msg-list>
  </div>
</template>

<script>
import msgList  from "./message/msgList.vue"
import {bzMessageConfig_pageUserMessage,bzMessageConfig_userMessageRead} from '../RequestPort/message/message'

export default {
  name: "logohome",
  components: {msgList},
  data() {
    return {
      tableData:[],
      search: { current: 1, size: 4,statusCode:0}
    }
  },
  methods: {
    showList(){
      this.$refs.msgList.setdialogVisible(true)
    },
    getList(){
      bzMessageConfig_pageUserMessage(this.search).then(res=>{
        this.tableData = res.data.records;
      })
    },
    readMsg(item){
      bzMessageConfig_userMessageRead({id:item.id}).then(res=>{
        this.$message.success('已读成功')
        this.getList(1)
        // item.remark ? window.location.href = item.remark : ''
        if(row.remark){
          this.downloadFile(row.remark,row.fileName)
        }
      })
    },
    downloadFile(sourceUrl,fileName) {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', sourceUrl, true);
      xhr.responseType = 'blob';
      xhr.onload = function () {
        if (xhr.status === 200) {
          const res = xhr.response;
          const link = document.createElement('a');
          link.style.display = 'none';
          const url = window.URL.createObjectURL(res);
          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          window.URL.revokeObjectURL(link.href);
          document.body.removeChild(link);
        }
      }
      xhr.send()
    },

  },
  mounted(){
    this.getList();
    this.$eventBus.$on('methodB',(data)=> {
				this.getList();
			})
  }
}

</script>

<style  lang="less" scoped>
.box-card{
  position:absolute;
  bottom: 10px;
  width: 300px;
  right: 0px;
}
.more{text-align: left;}
.showList{
  position: relative;
  text-align: left;
}
.item{
  border: 1px solid #F0F2F5;

  margin:10px;
  padding: 10px;
}
.container {
  /* background-image: url('./../assets/home.png'); */
  background-size: cover;
  background-repeat: no-repeat;
 
  height: calc(100vh - 175px);
  vertical-align: middle;
  text-align: center;
  background-color:#fff;
  box-shadow: 1px 1px 5px #888888;
  margin: 10px;
  display: flex;
  align-items: center;justify-content: center;
 
}
</style>