import axios from '@/common/js/request'
export function LoginRequest(data){
    const LoginRequest = axios({
        url: 'user/userLogin',
        method: 'post',
        data
    })
    return LoginRequest
}
export function LoginOut(data){
    const LoginOut = axios({
        url: 'user/logout',
        method: 'get',
        data
    })
    return LoginOut
}
export function ReadTenantTree(params){
    const ReadTenantTree = axios({
        url: 'api/basicsParam/readTenantTree',
        method: 'get',
        params
    })
    return ReadTenantTree
}
export function validDefaultTime(params){
    const validDefaultTime = axios({
        url: 'kaptcha/validDefaultTime',
        method: 'post',
        params
    })
    return validDefaultTime
}
export function updateUserPassword(data){
    const updateUserPassword = axios({
        url: '/user/updateUserPassword',
        method: 'post',
        data
    })
    return updateUserPassword
}

 // /xtpxUser/selectPermissionListByUserIdAndPermissionId

export function LoadMenu(params){
    const LoadMenu = axios({
        url: '/xtpxUser/selectPermissionListByUserIdAndPermissionId',
        method: 'get',
        params
    })
    return LoadMenu
}
export function dicpage(params){
    const dicpage = axios({
        url: '/dict/page',
        method: 'get',
        params
    })
    return dicpage
}
export function dicdelete(params){
    const dicdelete = axios({
        url: '/dict/delete',
        method: 'get',
        params
    })
    return dicdelete
}
export function dicsave(data){
    const dicsave = axios({
        url: '/dict/save',
        method: 'post',
        data
    })
    return dicsave
}
export function dicupdata(data){
    const dicupdata = axios({
        url: '/dict/update',
        method: 'post',
        data
    })
    return dicupdata
}
export function getUserMessageCount(params){
    const dicupdata = axios({
        url: '/bzMessageConfig/getUserMessageCount',
        method: 'get',
        params
    })
    return dicupdata
}
// 工商业用户安检修改排序
export function updateSortNum(params){
    const updateSortNum = axios({
        url: '/AaumArchive/updateSortNum',
        method: 'get',
        params
    })
    return updateSortNum
}
// 修改排序（通用）
export function updateSortNum1(params){
    const updateSortNum1 = axios({
        url: '/arumPeriod/updateSortNum1',
        method: 'get',
        params
    })
    return updateSortNum1
}