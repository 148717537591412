import axios from 'axios';
let  baseUrl= "";
// if(location.href.indexOf('zy')+1){
//   baseUrl = "http://47.93.96.126:7779/"
// }

//http request 拦截器
const serviceGeo = axios.create({
  })
  serviceGeo.defaults.baseURL = baseUrl
//http request 拦截器
serviceGeo.defaults.withCredentials = true
serviceGeo.interceptors.request.use(
  config => {
    //  showFullScreenLoading()
    return config
  },
  error => {
    return Promise.reject(err)
  }
)
serviceGeo.interceptors.response.use(
  response => {
    //   tryHideFullScreenLoading()
    return response.data
  },
  error => {
    //  tryHideFullScreenLoading()
    return Promise.reject(error)
  }
)
export default serviceGeo
