import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index/index.vue'
import indexAll from '../views/index/indexBi.vue'
import indexGis from '../views/index/indexGis.vue'
import Login from '../views/Login.vue'
import domMessage from '../common/js/MessageOnce'
import MenuConfig from './routerConfig'
import logohome from '../views/logohome'
import inspePlanAdd from '../views/Inspection/inspePlanAdd'
import incident from '../views/event/incident'
Vue.use(VueRouter)
let MenuArray = []
let Message  = new domMessage()
let Menu = []
let FullScreenRouter = []
if(localStorage.getItem('MenuTree')&&localStorage.getItem('MenuTree')!='undefined'){
  Menu = JSON.parse(localStorage.getItem('MenuTree'))
}
MenuArray = MenuArray.concat(MenuConfig(Menu))

const routes = [
  {
    path:'/',
    name:'',
    redirect:'/home/logohome'
  },{
    path:'/index',
    name:'',
    component:indexAll
  },
  {
    path: '/home',
    name: 'home',
    component:index,
    children:[
      ...MenuArray,
      {
        path: '/home/logohome',
        name: '首页',
        component:logohome
      },
     
      {
        path:'/inspePlanAdd',
        name:'inspePlanAdd',
        component:inspePlanAdd
      },
      {
        path: '/home/inspePlanAdds',
        name: '新建计划安排',
        component: () => import('@/views/scheduling/inspePlanAdd'),
        meta:{keepAlive:true}
      },
      {
        path: '/home/addWalking',
        name: '新增巡线采集配置项',
        component: () => import('@/views/lineDictionaries/options/addCollec'),
      },
      {
        path: '/home/descWalking',
        name: '巡线采集配置项详情',
        component: () => import('@/views/lineDictionaries/options/collecDesc'),
      },
      {
        path: '/home/addSampleWalking',
        name: '新增巡检采集配置模板',
        component: () => import('@/views/lineDictionaries/options/addSample'),
      },
      {
        path: '/home/sampleWalkingDesc',
        name: '采集配置模板详情',
        component: () => import('@/views/lineDictionaries/options/sampleDesc'),
      },
      {
        path:'/incident',
        name:'incident',
        component:incident
      },
      {
        path: '/home/selectDesc',
        name: 'Login',
        component: () => import('@/views/selectQuery/selectDesc'),
      },
      {
        path: '/home/addCollec',
        name: '新增采集配置项',
        component: () => import('@/views/checkDict/options/addCollec'),
      },
      {
        path: '/home/peopleAddCollec',
        name: '新增采集配置项',
        component: () => import('@/views/checkDict/options/peopleAddCollec'),
      },
      {
        path: '/home/addSample',
        name: '新增采集配置模板',
        component: () => import('@/views/checkDict/options/addSample'),
      },
      {
        path: '/home/peopleSampleDesc',
        name: '新增采集配置模板',
        component: () => import('@/views/checkDict/options/peopleSampleDesc'),
      },
      {
        path: '/home/peopleAddAnjianTemplate',
        name: '新增采集配置模板',
        component: () => import('@/views/checkDict/options/peopleAddAnjianTemplate'),
      },
      {
        path: '/home/peopleAnjianTemplateDesc',
        name: '新增采集配置模板',
        component: () => import('@/views/checkDict/options/peopleAnjianTemplateDesc'),
      },
      {
        path: '/home/peopleAddSample',
        name: '新增采集配置模板',
        component: () => import('@/views/checkDict/options/peopleAddSample'),
      },
      {
        path: '/home/collecDesc',
        name: '采集配置项详情',
        component: () => import('@/views/checkDict/options/collecDesc'),
      },
      {
        path: '/home/peopleCollecDesc',
        name: '采集配置项详情',
        component: () => import('@/views/checkDict/options/peopleCollecDesc'),
      },
      {
        path: '/home/printDetilsupply',
        name: '打印',
        component: () => import('@/views/suppliesManage/print'),
      },
      {
        path: '/home/sampleDesc',
        name: '采集配置模板详情',
        component: () => import('@/views/checkDict/options/sampleDesc'),
      },
      {
        path: '/home/TableWith',
        name: '工商用户安检表具信息',
        component: () => import('@/views/record/comList/TableWith'),
      },
      {
        path: '/home/addTable',
        name: '工商用户安检添加表具信息',
        component: () => import('@/views/record/comList/addTable'),
      },{
        path: '/home/record/peopleEdit',
        name: '用户编辑记录',
        component: () => import('@/views/record/comList/peopleEdit'),
        meta:{keepAlive:true}
      },{
        path: '/home/record/peopleCompileUser',
        name: '用户编辑记录',
        component: () => import('@/views/record/comList/peopleCompileUser'),
      },{
        path: '/home/record/peopleSecurity',
        name: '用户编辑记录',
        component: () => import('@/views/record/comList/peopleSecurity'),
        meta:{keepAlive:true}
      },
      {
        path: '/home/record/recordTable',
        name: '工商用户安检换表记录',
        component: () => import('@/views/record/comList/recordTable'),
        meta:{keepAlive:true}
      },
      {
        path: '/home/record/recordEdit',
        name: '工商用户安检编辑记录',
        component: () => import('@/views/record/comList/recordEdit'),
        meta:{keepAlive:true}
      },
      {
        path: '/home/record/compileUser',
        name: '工商用户安检编辑记录-用户信息',
        component: () => import('@/views/record/comList/compileUser'),
      },
      {
        path: '/home/record/compileTable',
        name: '工商用户安检编辑记录-表具信息',
        component: () => import('@/views/record/comList/compileTable'),
      },
      {
        path: '/home/record/recordSecurity',
        name: '工商用户安检编辑记录-安检信息',
        component: () => import('@/views/record/comList/recordSecurity'),
        meta:{keepAlive:true}
      },
      {
        path: '/home/record/recordSecurityInfo',
        name: '工商用户安检编辑记录-安检信息详情',
        component: () => import('@/views/record/comList/recordSecurityInfo'),
      },
      {
        path: '/home/record/peopleUserEdit',
        name: '档案管理-居民用户新增',
        component: () => import('@/views/record/comList/peopleUserEdit'),
        meta:{keepAlive:true}
      },
      {
        path: '/home/record/peopleUserEditDetails',
        name: '档案管理-居民用户查看',
        component: () => import('@/views/record/comList/peopleUserEditDetails'),
        meta:{keepAlive:true}
      },
      
      {
        path: '/home/workorder/details',
        name: '工商用户安检工单详情',
        component: () => import('@/views/workorder/component/details'),
        meta:{keepAlive:true}
      },
      {
        path: '/home/workorder/civilDetails',
        name: '工商用户安检民用工单详情',
        component: () => import('@/views/workorder/component/civilDetails'),
        meta:{keepAlive:true}
      },
      {
        path: '/home/device/details',
        name: '设备巡检工单',
        component: () => import('@/views/lineCheckDicts/options/dtDesc'),
        meta:{keepAlive:true}
      },
      {
        path: '/home/eventPollings',
        name: '事件巡检工单',
        component: () => import('@/views/pollingResult/eventPollings'),
        meta:{keepAlive:true}
      },
      {
        path: '/home/device/pathDetails',
        name: '巡检轨迹',
        component: () => import('@/views/lineCheckDicts/options/pathDesc'),
        meta:{keepAlive:true}
      },
      {
        path: '/home/devicePollingDesc',
        name: '设备巡检详情',
        component: () => import('@/views/pollingResult/devicePollingDesc'),
        meta:{keepAlive:true}
      },
      {
        path: '/home/incident',
        name: '巡检轨迹上报',
        component: () => import('@/views/pollingResult/incident'),
        meta:{keepAlive:true}
      },
      {
        path: '/home/detailsPrint',
        name: '设备巡检详情',
        component: () => import('@/views/pollingResult/detailsPrint'),
      },
      {
        path: '/home/eventPollingDesc',
        name: '事件巡检详情',
        component: () => import('@/views/pollingResult/eventPollingDesc'),
        meta:{keepAlive:true}
      },
      {
        path: '/home/evntPollingPrint',
        name: '事件巡检详情',
        component: () => import('@/views/pollingResult/eventPollingPrint'),
      },
      {
        path: '/home/kfOrderDeil',
        name: '报修工单详情',
        component: () => import('@/views/mainOrder/seeDetil'),
        meta:{keepAlive:true}
      },
      {
        path: '/home/serviceOrder/options/addCollec',
        name: '派单采集项新增',
        component: () => import('@/views/serviceOrder/options/addCollec'),
      },
      {
        path: '/home/serviceOrder/options/collecDesc',
        name: '派单采集项查看',
        component: () => import('@/views/serviceOrder/options/collecDesc'),
      },
      {
        path: '/home/serviceOrder/options/addSample',
        name: '派单采集模板',
        component: () => import('@/views/serviceOrder/options/addSample'),
      },
      {
        path: '/home/serviceOrder/options/sampleDesc',
        name: '派单采集项模板查看',
        component: () => import('@/views/serviceOrder/options/sampleDesc'),
      },
      {
        path: '/home/repairManage/options/collecAdd',
        name: '添加报修记录',
        component: () => import('@/views/repairManage/options/collecAdd'),
      },
      {
        path: '/home/resident/reAdd',
        name: '民用安检任务详情',
        component: () => import('@/views/securityTask/assembly/reAdd'),
        meta:{keepAlive:true}
      },
      {
        path: '/home/resident/unAdd',
        name: '非民用安检任务详情',
        component: () => import('@/views/securityTask/assembly/unAdd'),
        meta:{keepAlive:true}
      },
      {
        path: '/home/resident/addArea',
        name: '添加常规区域安检',
        component: () => import('@/views/securityTask/assembly/addArea'),
        meta:{keepAlive:true}
      },
      {
        path: '/home/resident/addRetail',
        name: '添加常规散户安检',
        component: () => import('@/views/securityTask/assembly/addRetail'),
        meta:{keepAlive:true}
      },
      {
        path: '/home/resident/addAbnormal',
        name: '添加异常安检',
        component: () => import('@/views/securityTask/assembly/addAbnormal'),
        meta:{keepAlive:true}
      },
      // 泄漏报警
      // {
      //   path: '/home/DataReport/DeviceStates',
      //   name: '添加异常安检',
      //   component: () => import('@/views/yitong/index/DataReport/DeviceStates'),
      //   meta:{keepAlive:true}
      // }
    ],
  },
  // {
  //   path: '/home/authManagement',
  //   name: 'indexGis',
  //   component:indexGis,
  //   redirect: '/home/authManagementIndex',
  //   children:[
  //     {
  //     path: '/home/authManagementIndex',
  //     name: 'gis',
  //       component: () => import('@/views/Gis/index'),
  //   }
  //   ]
  // },
  {
    path: '/Login',
    name: 'Login',
    component:Login
  },
  {
    path: '/indexpc',
    name: 'Login',
    component: () => import('@/views/applyPC/index'),
  },
  {
    path: '/indexAdd',
    name: 'Login',
    component: () => import('@/views/applyPC/add'),
  } ,
  {
    path: '/succeed',
    name: 'Login',
    component: () => import('@/views/applyPC/succeed'),
  }
  ,
  {
    path: '/progress',
    name: 'Login',
    component: () => import('@/views/applyPC/progress'),
  }
  ,
  {
    path: '/descpc',
    name: 'Login',
    component: () => import('@/views/applyPC/desc'),
  },
  {
    path: '/detailsPrint',
    name: 'detailsPrint',
    component: () => import('@/views/workorder/component/detailsPrint'),
  },
  {
    path: '/bi',
    name: 'bi',
    component: () => import('@/views/bi/index'),
  },
  {
    path: '/dataBoard',
    name: 'dataBoard',
    component: () => import('@/views/dataBoard/index'),
  }
]
FullScreenRouter.forEach((item,index)=>{
  routes.push(item)
})

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
var add;

var MenuStorage = '';
router.beforeEach((to,from,next)=>{
   MenuStorage = localStorage.getItem('MenuTree');
  if(to.fullPath == '/login' ||to.fullPath == '/bi' ||to.fullPath == '/Login'||to.fullPath == '/indexpc'||to.fullPath == '/indexAdd'||to.fullPath == '/succeed'||to.fullPath == '/progress'||to.fullPath == '/descpc'){
    next()
  }else{
    if(MenuStorage){
     if(localStorage.getItem('getMenuFlag')==0){
        getMu(to,next);
      }else{
        next()
      }
      
    }else{
      Message.error('登录超时，请重新登录')
      next('/login')
    }
  }
  if(to.matched.length ==0){
     next('/')
  }
})

function getMu(to,next){
  if(MenuStorage){
    let menuArray=JSON.parse(localStorage.getItem('MenuTree'))
    let SelectMenuArray = MenuConfig(menuArray)
    
     add = SelectMenuArray.filter((item) => !router.options.routes[2].children.some((ele) => ele.path === item.path));

    if(add.length != 0){
      router.addRoute({
        path: '/home',
        name: 'home',
        component:index,
        children:[
          ...add,
          {
            path: '/home/logohome',
            name: 'logohome',
            component:logohome
          },
          {
            path: '/home/logohome1',
            name: 'logohome1',
            component:logohome
          }
        ],
      })
      router.options.routes[2] = {
        path: '/home',
        name: 'home',
        component:index,
        children:[
          ...add,
          {
            path: '/home/logohome',
            name: 'logohome',
            component:logohome
          }
        ],
      }
    }
    localStorage.setItem('getMenuFlag', 1);
    if(to.fullPath == '/index'){
      router.push('/index')
    }else{
      if(menuArray.length>0){
        const select = JSON.parse(localStorage.getItem('SelectMenuArray'));
        selectRouter(select)
      }
    }
  }
  else{
    Message.error('登录超时，请重新登录')
    next('/login')
  }
}
function selectRouter(item){
  // if(item.children&&item.children.length>0){
  //     selectRouter(item.children[0])
  // }else{
    router.push('/home/'+item.url)
 // }
}


export default router
