<template>
  <div>
    <!-- <el-header  style="background-color: #252733;"> -->
    <div class="logoName" @click="goIndex()">
      <img src="../../assets/image/topLogo.png" />
      <span style="font-size: 24px"> 中联燃气</span>
    </div>
    <div class="title">{{ title }}系统</div>

    <div class="rightall">
      <div class="righyitem">
        <div class="msg" @click="showList">
          <slot>
            <el-badge :value="msgNum" class="msgitem">
              <!-- <i class="el-icon-bell"></i> -->
              <img src="../../assets/image/ling.png" />
            </el-badge>
          </slot>
        </div>
      </div>
      <div class="righyitem">
        <el-dropdown class="administrator" v-show="showChang">
          <span class="el-dropdown-link">
            <span style="color: #fff">切换系统</span
            ><i
              style="color: #fff"
              class="el-icon-arrow-down el-icon--right"
            ></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <div class="allList">
              <div
                v-for="(item, i) in MenuArray"
                :key="item.id"
                :class="['itemList', i == chosedIndex ? 'select' : '']"
                @click="tabclickfun(i, item)"
              >
                <img class="imglist" :src="item.imgsrc" />
                <p class="titleList" style="line-height: 14px">
                  {{ item.name }}
                </p>
              </div>
            </div>
            <!-- <div v-for="(item,i) in MenuArray" :key="item.id" :class="['tabs',i == chosedIndex ? 'active' : '']" @click="tabclickfun(i)">
              <el-dropdown-item>
              <span :class="title==item.name?'fontcolor':'rightmargin'" >{{item.name}}</span>
              <i class="el-icon-d-arrow-right"  v-if="title!=item.name"></i>
              </el-dropdown-item>
              </div> -->
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="righyitem">
        <el-divider direction="vertical"></el-divider>
      </div>
      <div class="righyitem">
        <img
          src="../../assets/image/user.png"
          style="vertical-align: middle; width: 27px; margin-right: 10px"
        />
        <el-dropdown class="administrator">
          <span class="el-dropdown-link">
            <span style="color: #fff">{{ userName }}</span
            ><i
              style="color: #fff"
              class="el-icon-arrow-down el-icon--right"
            ></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>
              <div @click="modifyPassWord">修改密码</div>
            </el-dropdown-item>
            <el-dropdown-item>
              <div @click="$router.push('/index')">返回系统选择</div>
            </el-dropdown-item>
            <el-dropdown-item>
              <div @click="login_out">退出</div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <!-- <socket></socket> -->
    <scadaWarn ref="scadaWarn"></scadaWarn>
    <!-- </el-header> -->
    <el-dialog
      title="修改密码"
      :visible.sync="visible"
      width="30%"
      @close="clear('form')"
    >
      <el-form ref="form" :model="form" label-width="100px" :rules="rule">
        <el-form-item label="原密码:" prop="password">
          <el-input
            v-model="form.password"
            type="password"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item label="新密码:" prop="newPassword">
          <el-input
            v-model="form.newPassword"
            type="password"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item label="确认密码:" prop="newPassword2">
          <el-input
            v-model="form.newPassword2"
            type="password"
            show-password
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">取 消</el-button>
        <el-button type="primary" @click="submitfrom('form')">确 定</el-button>
      </span>
    </el-dialog>
    <msg-list ref="msgList"></msg-list>
  </div>
</template>

<script>
import {
  LoginOut,
  updateUserPassword,
  getUserMessageCount,
} from "../../RequestPort/Login/LoginRequest";
import msgList from "../../views/message/msgList.vue";
import socket from "../../common/js/socket";
import scadaWarn from "../scadaWarn";
export default {
  name: "Header",
  components: { msgList, scadaWarn },
  mixins: [socket],
  props: {
    showChang: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    //此处即表单发送之前验证
    let validateNewPassword = (rule, value, callback) => {
      if (value === this.form.password) {
        callback(new Error("新密码不能与原密码相同!"));
      } else {
        callback();
      }
    };
    let validateNewPassword2 = (rule, value, callback) => {
      if (value !== this.form.newPassword) {
        callback(new Error("与新密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      userName: "",
      userId: "",
      rule: {
        password: [
          { required: true, message: "请输入原密码", trigger: "blur" },
          { min: 6, message: "长度在最少为6个字符", trigger: "blur" },
        ],
        newPassword: [
          { required: true, message: "请设置新密码", trigger: "blur" },
          { min: 6, message: "长度在最少为6个字符", trigger: "blur" },
          { validator: validateNewPassword, trigger: "blur" },
        ],
        newPassword2: [
          { required: true, message: "请确认新密码", trigger: "blur" },
          { min: 6, message: "长度在最少为6个字符", trigger: "blur" },
          { validator: validateNewPassword2, trigger: "blur" },
        ],
      },
      goOn: "",
      visible: false,
      form: {
        password: "",
        newPassword: "",
        newPassword2: "",
      },
      systemType: "",
      navTo: "",
      title: "",
      MenuArray: [],
      MenuNum: [],
      headerBool: true,
      chosedIndex: 0,
      msgNum: 0,
      userpng: "../../assets/image/user.png",
    };
  },
  watch: {
    $route() {
      localStorage.getItem("tabIndex")
        ? (this.chosedIndex = localStorage.getItem("tabIndex"))
        : "";
    },
    systemType(val) {
      switch (this.systemType) {
        case "gismanage":
          this.title = "GIS";
          this.navTo = "";
          break;
        case "setting":
          this.title = "用户权限管理";
          this.navTo = "";
          break;
        case "homecheck":
          this.title = "入户安检管理系统";
          this.navTo = "切换巡检系统";
          break;
        case "lineMalking":
          this.title = "智能巡检管理平台";
          this.navTo = "切换入户安检系统";
          break;
      }
    },
  },
  mounted() {
    this.userName = localStorage.getItem("userName");
    this.userId = localStorage.getItem("userId");
    this.systemType = localStorage.getItem("showTitle"); //localStorage.getItem("systemType");
    this.GetMenu();
    localStorage.getItem("tabIndex")
      ? (this.chosedIndex = localStorage.getItem("tabIndex"))
      : "";
    this.title = localStorage.getItem("showTitle");
    getUserMessageCount({}).then((res) => {
      this.msgNum = res.data.num;
    });
  },
  methods: {
    showList() {
      this.$refs.msgList.setdialogVisible(true);
    },
    tabclickfun(index, item) {
      let tag = [
        {
          //首页
          path: "/home/logohome",
          name: "首页",
          title: "首页",
          meta: { name: "首页" },
        },
      ];
      if (item.url === "BI") {
        this.$router.push("/dataBoard");
        return;
      }
      this.$store.dispatch("clearVisitedView", tag);
      if (this.chosedIndex != index) {
        this.chosedIndex = index;
        localStorage.setItem("tabIndex", index);
        localStorage.setItem(
          "SelectMenuArray",
          JSON.stringify(this.MenuArray[index].children)
        );
        this.$emit("ChangeMenuTab");
      }
      localStorage.setItem("showTitle", this.MenuArray[index].name);
      this.title = localStorage.getItem("showTitle");
    },

    /*    changeSystem() {
      let e
      if (this.systemType == 'homecheck') {
        e = 'lineMalking'
        localStorage.setItem('systemType',e)
      } else if (this.systemType == 'lineMalking') {
        e = 'homecheck'
        localStorage.setItem('systemType',e)
      }
      this.MenuArray.forEach((item,index)=>{
        if(item.url == e){
          localStorage.setItem('SelectMenuArray',JSON.stringify(item.children))
        }
      })
      this.$emit('ChangeMenuTab')
      this.$router.push('/home')
      // this.$router.go(0)
    },*/
    GetMenu() {
      this.MenuArray = JSON.parse(localStorage.getItem("MenuTree"));
      if (this.MenuArray) {
        this.MenuArray.forEach((item, index) => {
          // if(item.name == 'BI') {
          //                   that.MenuArray.splice(index, 1)
          //               }
          this.MenuNum.push(item.id);
          if (item.url == "contract") {
            item.imgsrc = require("../../assets/header/2.png");
          }
          if (item.url == "setting") {
            item.imgsrc = require("../../assets/header/8.png");
          }
          if (item.url == "BI") {
            item.imgsrc = require("../../assets/header/9.png");
          }
          if (item.url == "report") {
            item.imgsrc = require("../../assets/header/1.png");
          }
          if (item.url == "homecheck") {
            item.imgsrc = require("../../assets/header/4.png");
          }
          if (item.url == "lineMalking") {
            item.imgsrc = require("../../assets/header/6.png");
          }
          if (item.url == "lineworking") {
            item.imgsrc = require("../../assets/image/3.png");
          }
          if (item.url == "gismanage") {
            item.imgsrc = require("../../assets/header/3.png");
          }
          if (item.url == "Business") {
            item.imgsrc = require("../../assets/header/4.png");
          }
          if (item.url == "serviceOrder") {
            item.imgsrc = require("../../assets/image/kf.png");
          }
          if (item.url == "serviceOrderManger") {
            item.imgsrc = require("../../assets/image/repairTwo.png");
          }
          if (item.url == "SCADA") {
            item.imgsrc = require("../../assets/header/scada.png");
          }
          if (item.url == "yitong") {
            item.imgsrc = require("../../assets/header/warn.png");
          }
        });
        if (!localStorage.getItem("showTitle")) {
          localStorage.setItem("showTitle", this.MenuArray[0].name);
        }
      } else {
        this.$message.error("请重新登录");
        this.$router.push("/login");
      }
    },
    modifyPassWord() {
      this.visible = !this.visible;
    },
    submitfrom(form) {
      this.$refs[form].validate((valid) => {
        if (!valid) return false;
        let obj = {
          userId: this.userId,
          oldPassword: this.form.password,
          newPassword: this.form.newPassword,
          confirmPassword: this.form.newPassword2,
        };
        updateUserPassword(obj).then((res) => {
          if (res.code === 200) {
            this.visible = !this.visible;
            this.$message.success(res.msg);
            setTimeout(() => {
              this.$router.push("/login");
            }, 1500);
          } else {
            this.$message.error(res.msg);
          }
        });
      });
    },
    clear(form) {
      this.form = {};
      this.$refs.form.resetFields();
    },
    goIndex() {
      this.$router.push("/index");
    },
    login_out() {
      LoginOut({}).then((res) => {
        localStorage.removeItem("MenuTree");
        localStorage.removeItem("tokenName");
        localStorage.removeItem("tokenValue");
        localStorage.removeItem("userName");
        localStorage.removeItem("userId");
        localStorage.removeItem("SelectMenuArray");
        localStorage.removeItem("tabIndex");
        localStorage.removeItem("showTitle");
        this.$store.commit("changeSocketState", false);
        this.$message({
          message: "注销成功",
          duration: 1500,
        });
        setTimeout(() => {
          this.$router.push("/login");
        }, 1500);
      });
    },
  },
};
</script>

<style lang="less" scoped>
.administrator {
  height: 50px;
  top: -12px;
}
.fontcolor {
  color: #1082ff;
}
.rightmargin {
  margin-right: 6px;
}
.righyitem {
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  .msg {
    height: 35px;
    line-height: 35px;
    ::v-deep .msgitem {
      padding-right: 5px;
    }
  }
}
.rightall {
  font-size: 15px;
  margin-right: 25px;
  display: flex;
  justify-content: flex-end;
  height: 72px;
}
.msgitem img {
  width: 20px;
  height: 20px;
}
.logoName {
  float: left;
  height: 70px;
  line-height: 70px;
  cursor: pointer;
  width: 200px;
  color: #fff;
  background-color: #252733;
  font-size: 26px;
  font-weight: bold;
  display: flex;
}
.title {
  float: left;
  height: 70px;
  line-height: 70px;
  cursor: pointer;
  width: 200px;
  color: #fff;
  background-color: #252733;
  font-size: 18px;
  margin-left: 20px;

  display: flex;
}
.logoName img {
  margin: 10px 20px;
}
.changeSystem {
  float: left;
  margin-left: 15px;
  cursor: pointer;
}
.el-header {
  padding-left: 0 !important;
}
.allList {
  display: flex;
  margin: 10px 9px 0px 15px;
  width: 375px;
  flex-wrap: wrap;
}
.itemList {
  width: 110px;
  height: 90px;
  border: 1px solid #ebeff3;
  border-radius: 4px;
  margin: 0px 5px 10px;
  text-align: center;
}
.allList :hover {
  background-color: #ebf5ff;
  cursor: pointer;
}
.select {
  border: 1px solid #1f87ff;
  border-radius: 4px;
}
.imglist {
  margin-top: 9px;
  margin-bottom: 4px;
  width: 50px;
  height: 50px;
}
.titleList {
  font-size: 14px;
  font-weight: 400;
  color: #303133;
  margin: 0px;
  padding: 0px;
  line-height: 14px;
}
.select .titleList {
  color: #0f71e2;
}
</style>
