import requestGeo from '@/common/js/requestGeo'
export function queryFeatrue(params) {
  return requestGeo({
    url: '/geoserver/wms',
    method: 'get',
    params
  })
}
export function regionList(params) {
  return requestGeo({
    url: '/geoserver/ows',
    method: 'get',
    params
  })
}

export function geoOws(params) {
  return requestGeo({
    url: '/geoserver/ows',
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    params
  })
}

export function geoWfs(params) {
  return requestGeo({
    url: '/geoserver/wfs',
    method: 'get',
    params
  })
}