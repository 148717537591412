<template>
  <div class="login">
    <div class="tname">
      <p><img src="../assets/image/logo.png" /> <span style="font-size:36px;margin-left:10px;font-weight: 800;">中联燃气</span> <img style="margin-left:57%" src="../assets/image/logo_r.png"></p>
    </div>
    <div class="bodymodle">
      <img src="../assets/image/login_l.png" style="margin-right:10%">
      <img src="../assets/1047app.png" class="appImg" >
      <div class="formbox">
        <div class="formright">
          <div class="fname">登录</div>
          <el-form ref="user" :model="user" :rules="rules" >
          <li class="ili">
            <input
              v-model="user.username"
              type="text"
              placeholder="请输入用户名"
            />
          </li>
          <li class="ili">
            <input
              v-model="user.password"
              type="password"
              placeholder="请输入密码"
              @keyup.enter="LoginSubmit('user')"
            />
          </li>
          <!-- <li class="ili">
            <input
              v-model="user.authcode"
              type="password"
              placeholder="请输入验证码"
              @keyup.enter="loginIn()"
              style="width:60%"
            />
            <img src="../assets/image/logo.png" >
          </li> -->
          <p class="bt"  @click="LoginSubmit('user')">登录</p>
          </el-form>
        </div>
      </div>
    </div>

    <div class="bottomy">
      <p><span>版权所有 © 2021 保留一切权利</span><span>保密协议</span></p>
    </div>

  </div>
</template>

<script>
import {LoginRequest} from '../RequestPort/Login/LoginRequest'
  import md5 from 'js-md5'
  import axios from "axios";
  export default {
    name: 'home',
    data(){
      return {
        arrList:[],
        baseURL:axios.defaults.baseURL,
        user:{
          username:'',
          password:'',
          authcode:''
        },
        rules:{
          username:[{ required: true, message: '请输入用户名', trigger: 'blur' }],
          password:[{ required: true, message: '请输入密码', trigger: 'blur' }],
          authcode:[{ required: true, message: '请输入验证码', trigger: 'blur' }],
        }
      }
    },
    methods: {
      loadimg(){
     //   document.getElementById("kaptcha").src=this.baseURL+'/kaptcha/render?d='+new Date();
      },
      CalcuMD5(pwd) {
        let onepwd = md5(pwd);
        let newpwd = onepwd.substring(onepwd.length-8)
        let twopwd = md5(newpwd)
        let endpwd = twopwd.substring(twopwd.length-8)
        return endpwd;
      },
      makeArr(data){
        for (let i in data){
          this.arrList.push(data[i])
          if(data[i].children){
            this.makeArr(data[i].children)
          }
        }
      },
      LoginSubmit(user){
        this.$refs[user].validate((valid) => {
          if(!valid) return false
          LoginRequest({
            username: this.user.username,
            password: this.CalcuMD5(this.user.password),
            userKey:'0'
          }).then((e) => {
            if(e.data.permissionAndButtonList.length>0){
              let tokenInfo = e.data.tokenInfo
              localStorage.setItem('MenuTree',JSON.stringify(e.data.permissionAndButtonList))
              this.makeArr(e.data.permissionAndButtonList)
              localStorage.setItem('MenuArrayList',JSON.stringify(this.arrList))
              localStorage.setItem('SelectMenuArray',JSON.stringify(e.data.permissionAndButtonList[0].children))
              localStorage.setItem('tokenName', tokenInfo.tokenName)
              localStorage.setItem('tokenValue', tokenInfo.tokenValue)
              localStorage.setItem('userName', e.data.username)
              localStorage.setItem('role', e.data.role)
              localStorage.setItem('userId', e.data.userId)
              localStorage.setItem('roleCodes', e.data.roleCodes)
              localStorage.setItem('roleIds', e.data.roleIds)
              localStorage.setItem('dataScope', e.data.dataScope)
              localStorage.setItem('zlCompanyId', e.data.companyId)
              localStorage.setItem('SelectMenuArray', JSON.stringify(e.data.permissionAndButtonList[0].children))
              localStorage.setItem('getMenuFlag', 0)
              //连接socket
              this.$store.commit('changeSocketState', true)
              this.$message({
                message: '恭喜你，登录成功',
                duration: 1500,
                type: 'success'
              });
                
              // setTimeout(() => {
              //   this.$router.push('/home')
              // }, 1000)
               setTimeout(() => {
                if(e.data.permissionList.length==1){
                  this.$router.push('/home')
                }else{
                  this.$router.push('/index')
                }
              }, 1000)
            }else{
              this.$message.error('您没有权限，请联系管理员');
            }
            

            // setTimeout(() => {
            //  // if(e.data.permissionList.length>=1){
            //     this.$router.push('/home')
            //   // }else{
            //   //   this.$router.push('/index')
            //   // }
              
            // }, 1000)


          }).catch((e)=>{
            this.user.authcode=''
            this.loadimg()
          })
        })
      }
    }
  }
</script>
<style scoped lang="scss">
.appImg{
    position: fixed;
    right: 40px;
    bottom: 100px;
    width: 100px;
}
.fname {
  width: 100%;
  font-size: 0.3rem;
  margin-top: 0.4rem;
  text-align: center;
  margin-bottom: 0.5rem;
  color: #323234;
  font-weight: 20px
}
//.formright {
//   text-align: center;
//}
.bt {
   background: #1d7ae1;
  width: 80%;
  height: 45px;
  line-height: 45px;
  margin: 0 auto;
  margin-top: 20px;
  cursor: pointer;
  color: #fff;
  text-align: center;
}
.ili {
  width: 80%;
  margin: 0 auto;
  margin-bottom: 0.15rem;
  position: relative;
  img {
    position: absolute;
    top: 4px;
    left: 70%;
    width: 35px;
  }
  input {
    height: 35px;
    width: 100%;
    color: #000;
    font-size: 0.16rem;
    text-indent: 17px;
    border: 1px solid #d7d5dd;
  }
  input:focus {
    outline:none;
    border: 1px solid #b7e3ff;
    }
}
.login {
//   background: #0c1e35;
  width: 100%;
  height: 100vh;
  color: #0556c6;
}
.bottomy {
//   border-top: 1px solid #1e4568;
  padding-top: 0.3rem;
  p {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 70%;
    margin: 0 auto;
    color: #252733;
  }
}
.tname {
//   border-bottom: 1px solid #1e4568;
  font-size: 0.5rem;
  text-align: center;
  p {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 0.7rem;
    width: 100%;
    margin: 0 auto;
    img {
      margin-left: 8%;
    }
  }
}
.bodymodle {
  width: 100%;
  height: calc(100vh - 1.4rem);
  background: url('../assets/image/login_bg.png') center center no-repeat;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.formbox {
  height: 4.82rem;
  width: 4.12rem;
  background: url('../assets/image/login_form.png') center center no-repeat;
  margin-right: 25%;
  background-size: 100% 100%;
}
// input::-webkit-input-placeholder {
//   /* WebKit browsers */
//   color: #fff;
// }
// input:-moz-placeholder {
//   /* Mozilla Firefox 4 to 18 */
//   color: #fff;
// }
// input::-moz-placeholder {
//   /* Mozilla Firefox 19+ */
//   color: #fff;
// }
// input:-ms-input-placeholder {
//   /* Internet Explorer 10+ */
//   color: #fff;
// }
</style>
