
import { makeList, getMapCenterLocation } from '@/apis/commonType'
import markIcon from '@/assets/tool/mark_b.png'
import mark from '@/assets/legend/poi-marker-default.png'
import hq from '@/assets/various/hq.png'
import { queryFeatrue } from '@/apis/geo'
import { lineDetail, lineGet } from '@/apis/commonType'
// import Cookies from "js-cookie";
export const mapMixin = {
  data() {
    return {
      map: null,
      AmapType: { 'AMap.Marker': 'point' },

      mapConfig: {
        viewMode: '3D',
        pitch: 0,
        rotation: 0,
        zoom: 14,
        center: [125.790604,41.677467],
        zooms: [0, 20],
        maxZoom: 20,
        labelzIndex: 1,
        pitchEnable: false,
        rotateEnable: false,
        expandZoomRange: true, //调整最大缩放级数
        showBuildingBlock: false,
        animateEnable: false
        //   mapStyle: 'amap://styles/grey'
      },
      drawType: '',
      drawPositon: [],
      markForm: {
        remark: '',
        type: '',
        line: [],
        point: []
      },
      clickListener: null,
      drawlays: [],
      showTip: false,
      tipMessege: '',
      tipInfo: null,
      tipPoint: [],
      overlayGroups: null,
      drawState: false,
      polygon: null,
      nowType: ''
    }
  },
  destroyed() {
    this.$eventBus.$off('initMap')
    // 查看
    this.$eventBus.$off('showPolygon')
    this.$eventBus.$off('clearPolygon')
  },
  mounted() {
    let that = this
    this.$eventBus.$on('initMap', data => {
      that.initMap()
    })
    this.$eventBus.$on('showPolygon', data => {
      that.showPolygon(data)
    })
    this.$eventBus.$on('clearPolygon', data => {
      that.map.remove(this.polygon);
    })
    if (this.$store.getters.pulginLoad) {
      that.initMap()
      // 获取中心点
      getMapCenterLocation().then(res => {
          if(res.data!=null&&res.data.length>0){
          let area = res.data
          this.map.setCenter([Number(area.split(",")[0]),Number(area.split(",")[1])])
          
          this.mapConfig.center =  [Number(area.split(",")[0]),Number(area.split(",")[1])];

        }
      })
    }

  },

  methods: {
    setCityCenter() {
      let that = this
      getMapCenterLocation().then(res => {
        if (res.data != null && res.data.length > 0) {
          let area = res.data

          if (area && area.split(",").length > 0) {
            that.map.setCenter([Number(area.split(",")[0]), Number(area.split(",")[1])])

            that.mapConfig.center = [Number(area.split(",")[0]), Number(area.split(",")[1])];
          }
        }
      })
    },
    showPolygon(path) {
      if (this.polygon) {
        this.map.remove(this.polygon);
      }
      this.polygon = new AMap.Polygon({
        path: path,
        strokeColor: "#FF33FF",
        strokeWeight: 5,
        strokeOpacity: 0.2,
        fillOpacity: 0.1,
        fillColor: '#1791fc',
        zIndex: 1,
      });

      let that = this
      that.polygon.on('click', e => {
        if (!that.drawState) {
          that.$refs.markInfo.getFeatrue(e.lnglat)
        }
      })
      setTimeout(() => {
      this.map.add(this.polygon);
      this.map.setFitView([this.polygon])
      },500)
    },

    getMark() {
      let p = {}
      let that = this
      if (that.overlayGroups) {
        this.map.remove(that.overlayGroups)
      }
      makeList(p).then(res => {
        let list = res.data
        let group = []
        for (var i = 0; i < list.length; i++) {
          let extData = list[i]
          if (list[i].type == 'line') {
            // 创建线实例
            let poly = new AMap.Polyline({
              path: list[i].line,
              strokeColor: '#3e8bff',
              strokeWeight: 3,
              zIndex: 160,
              extData
            })
            group.push(poly)
          } else if (list[i].type == 'polygon') {
            // 创建线实例
            let poly = new AMap.Polygon({
              path: list[i].line,
              fillColor: '#00b0ff',
              strokeColor: '#80d8ff',
              zIndex: 160,
              extData
            })
            group.push(poly)
          } else if (list[i].type == 'point') {
            // 创建线实例
            let poly = new AMap.LabelMarker({
              position: list[i].point,
              icon: {
                type: 'image',
                image: hq,
                anchor: 'bottom-center'
              },
              zIndex: 160,
              extData
            })
            group.push(poly)
          } else if (list[i].type == 'circle') {
            // 创建线实例
            let point = list[i].point
            let poly = new AMap.Circle({
              center: [point[0], point[1]],
              radius: point[2],
              fillColor: '#00b0ff',
              strokeColor: '#80d8ff',
              zIndex: 160,
              extData
            })
            group.push(poly)
          } else if (list[i].type == 'rectangle') {
            // 创建线实例
            let bounds = new AMap.Bounds(list[i].line[0], list[i].line[1])
            let poly = new AMap.Rectangle({
              bounds: bounds,
              fillColor: '#00b0ff',
              strokeColor: '#80d8ff',
              zIndex: 160,
              extData
            })
            group.push(poly)
          }
        }
        that.overlayGroups = new AMap.OverlayGroup(group)

        that.overlayGroups.on('click', function (e) {
          if (!that.drawState) {
            that.markForm = e.target.getExtData()
          }
          if (!that.pLoaction && !that.drawState) {
            that.drawInfo()
          }
        })
        this.map.add(that.overlayGroups)
      })
    },
    drawEnd() {

      let that = this
      this.mouseTool.on('draw', function (event) {
        that.$eventBus.$emit('wiredata')
        let obj = event.obj
        let type = obj.className
        that.tipPoint = []
        that.drawlays.push(obj)
        let point = []
        that.markForm = {
          remark: '',
          type: '',
          line: [],
          point: []
        }
        if (that.$refs.layerEdit) {
          that.$refs.layerEdit.closePDraw()
        }
        if (type == 'AMap.Marker') {
          that.markForm.point = obj.getPosition()
          that.markForm.type = 'point'
          that.markForm.line = []
          point = obj.getPosition()
          if (that.pLoaction) {
            // that.$parent.setLocation(point)
            that.map.remove(that.drawlays)
            that.drawlays = [obj]
            that.map.add(that.drawlays)
            that.$emit('setLocation', point)
            return
          }
        } else if (type == 'Overlay.Polyline') {

          that.markForm.point = []
          that.markForm.type = 'line'
          that.markForm.line = obj.getPath()
          point = obj.getPath()[obj.getPath().length - 1]
          if (that.pLoaction) {
            that.map.remove(that.drawlays)
            that.drawlays = [obj]
            // that.map.add(that.drawlays)
            that.map.add(that.drawlays)
            that.$emit('setLocation', obj.getPath())
            return
          }
        } else if (type == 'Overlay.Polygon') {
          that.markForm.point = []
          that.markForm.type = 'polygon'
          that.markForm.line = obj.getPath()
          point = obj.getPath()[obj.getPath().length - 1]
          if (that.pLoaction) {
            that.map.remove(that.drawlays)
            that.drawlays = [obj]
            that.map.add(that.drawlays)
            //    that.$emit('setLocation', obj.getPath())
          }
        } else if (type == 'Overlay.Rectangle') {
          let north = obj.getBounds().northEast
          let south = obj.getBounds().southWest
          that.markForm.point = []
          that.markForm.type = 'rectangle'
          that.markForm.line = [north, south]
          point = south
        } else if (type == 'Overlay.Circle') {
          let position = obj.getCenter()
          that.markForm.point = [position.lng, position.lat, obj.getRadius()]
          that.markForm.type = 'circle'
          that.markForm.line = []
          point = position
        }

        if (obj.name !== 'recrdLine') {
          that.drawInfo()
          that.closeDraw()
        }
      })
    },
    xfFeatrue(obj, edit, lineId, oldPath) {
      //吸附工具
      const that = this
      let path = []
      let index = 0
      // 关闭吸附的时候取消关联管线
      if(!that.$store.getters.adsorb) {
        this.$eventBus.$emit('wiredata', { })
        return
      }
      if (obj[0] && obj[0].className == 'AMap.Marker') {
        path = [obj[obj.length - 1].getPosition()]
      } else if (edit == 'eqyedit') {
        this.nowType = ''
        this.$eventBus.$emit('wiredata', { })
        path = [obj.getPosition()]
      } else if (edit == 'lineEdit') {
        // this.nowType = 'lineEdit'
        path = obj.getPath()
        index = this.contrastPath(oldPath, path)
        if (index == 'noPoint') {
          that.$eventBus.$emit('setLength', obj)
          return
        }
      }
      else {
        if(obj && obj.length > 0) {
        path = obj[0].getPath()
        index = path.length - 2
        } else {
          return
        }
      }
      let lnglat = path[index]
      let points = that.centerPointGetFourPoint(lnglat)
      let a = points.leftBottomPoint[0]
      let b = points.leftBottomPoint[1]
      let c = points.upRightPoint[0]
      let d = points.upRightPoint[1]
      // let cqlFilter = this.$store.getters.layers
      // let cql
      // if (cqlFilter) {
      //   cql = { CQL_FILTER: `table_type in(${cqlFilter}) and general_status=1` }
      // }
      let parames = {
        SERVICE: 'WMS',
        VERSION: '1.1.0',
        REQUEST: 'GetFeatureInfo',
        FORMAT: 'image/png',
        TRANSPARENT: true,
        QUERY_LAYERS: 'linqing:lineWalking',
        LAYERS: 'linqing:lineWalking',
        //  exceptions: 'application,Fvnd.ogc.se_inimage',
        INFO_FORMAT: 'application/json',
        FEATURE_COUNT: 10,
        X: 50,
        y: 50,
        SRS: 'EPSG:4326',
        WIDTH: 101,
        HEIGHT: 101,
        // ...cql,
        BBOX: a + ',' + b + ',' + c + ',' + d,
        // propertyName: 'location'
      }
      queryFeatrue(parames).then(res => {
        if (res.features && res.features[0]) {
          // 计算path上距离pos最近的点
          //var closestPositionOnLine  = AMap.GeometryUtil.closestOnLine(pos,path);
          let features = res.features
          let lineFeature = []
          let min = ''
          let point = []
          let lineM = {}//
          features.forEach(item => {
            let id = item.id
            let arr = id.split('.')
            let path = item.geometry.coordinates
            // if (arr[0] == 'view_all_line' &&this.nowType=='polyline'){//画线并且有线层，找线的端点 最近polyline marker              
            //   path.forEach((pointItem,i) => {            
            //      let distance = AMap.GeometryUtil.distance(lnglat,pointItem);
            //       if(min>distance || !min){
            //         min=distance  
            //         lineM={
            //           id:item.properties.id,
            //           table_name:item.properties.table_name ? item.properties.table_name : item.properties.type,
            //           i:i
            //         }              
            //       }
            //   });              
            // }else 
            if (arr[0] == 'view_all_line' && (this.nowType == 'marker' || this.nowType == 'polyline') && edit != 'lineEdit') {//画点粘连上最近的线
              let distance = AMap.GeometryUtil.distanceToLine(lnglat, path);
              if (min > distance || !min) {
                min = distance
                lineM = {
                  id: item.properties.id,
                  table_name: item.properties.table_name ? item.properties.table_name : item.properties.type,
             
                }
              }
            }
            else if (arr[0] == 'view_all_line' && edit == 'eqyedit'  && edit != 'lineEdit') {//画点粘连上最近的线
              let distance = AMap.GeometryUtil.distanceToLine(lnglat, path);
              if (min > distance || !min) {
                min = distance
                lineM = {
                  id: item.properties.id,
                  table_name: item.properties.table_name,
                
                }
              }
            }
            // else if (arr[0] == 'view_all_line' && edit == 'lineEdit' && lineId != item.properties.id) {//画点粘连上最近的线
            else if (arr[0] == 'view_all_line' && edit == 'lineEdit' && lineId != item.properties.id) {
              path.forEach((pointItem, i) => {
                let distance = AMap.GeometryUtil.distance(lnglat, pointItem);
                if (min > distance || !min) {
                  min = distance
                  lineM = {
                    id: item.properties.id,
                    table_name: item.properties.table_name,
                    i: i
                  }
                }
              });
            }
          })

          if (min) {//画线查到了连接点
            if (lineM.table_name == 'line' || lineM.table_name == 'patrol_lines' || lineM.table_name == "tb_basic_pipe") {
              lineGet({ id: lineM.id, tableName: lineM.table_name }).then(res => {
                // Cookies.set("wiredata", JSON.stringify({lineId:res.data.records[0].id,lineName:res.data.records[0].lineName}));
                this.$eventBus.$emit('wiredata', { lineId: res.data.id, lineName: res.data.pipeName ? res.data.pipeName : res.data.lineName,lineTable:res.data.tableName })
                if (edit == 'eqyedit') {
                  var closestPositionOnLine
                  if (res.data.coordinate) {
                    closestPositionOnLine = AMap.GeometryUtil.closestOnLine(lnglat, res.data.coordinate);
                  } else {
                    closestPositionOnLine = AMap.GeometryUtil.closestOnLine(lnglat, res.data.location);
                  }
                  obj.setPosition(closestPositionOnLine)
                  that.$eventBus.$emit('setLength', obj)
                }
               
                // else if (this.nowType == 'lineEdit' && edit == 'lineEdit') {
                //   let newPath = path
                //   let PixelOld = this.map.lngLatToContainer(lnglat)

                //   let arr = res.data.location[lineM.i]

                //   let lineP = AMap.GeometryUtil.closestOnLine(lnglat, res.data.location)
                //   let Pixelnew = this.map.lngLatToContainer(lineP)
                //   let x = Math.abs(Pixelnew.x - PixelOld.x)
                //   let y = Math.abs(Pixelnew.y - PixelOld.y)
                //   let l = Math.sqrt((x * x + y * y))//勾股定理
                //   if (l < 30) {//30像素内
                //     newPath[index] = lineP
                //     obj.setPath(newPath)
                //   }
                // }
                else if (edit == 'lineEdit') {
                  let newPath = path
                  let PixelOld = this.map.lngLatToContainer(lnglat)
                  let Pixelnew = this.map.lngLatToContainer(res.data.location[lineM.i])
                  let x = Math.abs(Pixelnew.x - PixelOld.x)
                  let y = Math.abs(Pixelnew.y - PixelOld.y)
                  let l = Math.sqrt((x * x + y * y))//勾股定理                    
                  if (l < 15) {//30像素内                      
                    newPath[index] = res.data.location[lineM.i]
                    obj.setPath(newPath)
                    this.$eventBus.$emit('reloadEdit')
                  } else {
                    this.$eventBus.$emit('setLength', obj)
                  }
                } else if (this.nowType == 'polyline') {
                  let newPath = path
                  let PixelOld = this.map.lngLatToContainer(lnglat)

                  let arr = res.data.location[lineM.i]

                  let lineP = AMap.GeometryUtil.closestOnLine(lnglat, res.data.location)
                  let Pixelnew = this.map.lngLatToContainer(lineP)
                  let x = Math.abs(Pixelnew.x - PixelOld.x)
                  let y = Math.abs(Pixelnew.y - PixelOld.y)
                  let l = Math.sqrt((x * x + y * y))//勾股定理
                  if (l < 30) {//30像素内
                    newPath[index] = lineP
                    obj[0].setPath(newPath)
                  }
                } else if (this.nowType == 'marker') {
                  // 计算path上距离pos最近的点
                  var closestPositionOnLine = AMap.GeometryUtil.closestOnLine(lnglat, res.data.location);
                  obj[obj.length - 1].setPosition(closestPositionOnLine)
                  that.$eventBus.$emit('setLength', obj)
                }
              })
            }
            else if (lineM.table_name != 'tb_basic_pipe') {
              lineDetail({ id: lineM.id, tableName: lineM.table_name }).then(res => {
                // Cookies.set("wiredata", JSON.stringify({lineId:res.data.records[0].id,lineName:res.data.records[0].lineName}));
                this.$eventBus.$emit('wiredata', { lineId: res.data.records[0].id, lineName: res.data.records[0].lineName ,lineTable:res.data.records[0].tableName})
                if (edit == 'eqyedit') {
                  var closestPositionOnLine = AMap.GeometryUtil.closestOnLine(lnglat, res.data.records[0].coordinate);
                  obj.setPosition(closestPositionOnLine)
                }
                else if (edit == 'lineEdit') {
                  let newPath = path
                  let PixelOld = this.map.lngLatToContainer(lnglat)
                  let Pixelnew = this.map.lngLatToContainer(res.data.records[0].coordinate[lineM.i])
                  let x = Math.abs(Pixelnew.x - PixelOld.x)
                  let y = Math.abs(Pixelnew.y - PixelOld.y)
                  let l = Math.sqrt((x * x + y * y))//勾股定理                    
                  if (l < 15) {//30像素内                      
                    newPath[index] = res.data.records[0].coordinate[lineM.i]
                    obj.setPath(newPath)
                    this.$eventBus.$emit('reloadEdit')
                  } else {
                    this.$eventBus.$emit('setLength', obj)
                  }
                }
                // else if(this.nowType=='polyline'){      
                //       let newPath=path
                //       let PixelOld = this.map.lngLatToContainer(lnglat)
                //       let Pixelnew = this.map.lngLatToContainer(res.data.records[0].coordinate[lineM.i])
                //       let x=Math.abs(Pixelnew.x-PixelOld.x)
                //       let y=Math.abs(Pixelnew.y-PixelOld.y)
                //       let l=Math.sqrt((x*x+y*y))//勾股定理
                //       if(l<15){//30像素内
                //         newPath[index]=res.data.records[0].coordinate[lineM.i]
                //         obj[0].setPath(newPath)
                //       }
                //     }
                else if (this.nowType == 'marker' || this.nowType == 'polyline') {
                  // 计算path上距离pos最近的点
                  var closestPositionOnLine = AMap.GeometryUtil.closestOnLine(lnglat, res.data.records[0].coordinate);
                  obj[obj.length - 1].setPosition(closestPositionOnLine)
                }
              })
            } else {
              this.$eventBus.$emit('wiredata', { lineId: features[0].properties.id, lineName: features[0].properties.line_name })
              let newPath = path
              let PixelOld = this.map.lngLatToContainer(lnglat)
              let Pixelnew = this.map.lngLatToContainer(features[0].geometry.coordinate[lineM.i])
              let x = Math.abs(Pixelnew.x - PixelOld.x)
              let y = Math.abs(Pixelnew.y - PixelOld.y)
              let l = Math.sqrt((x * x + y * y))//勾股定理                    
              if (l < 15) {//30像素内                      
                newPath[index] = features[0].geometry.coordinate[lineM.i]
                obj.setPath(newPath)
                this.$eventBus.$emit('reloadEdit')
              } else {
                this.$eventBus.$emit('setLength', obj)
              }
            }
          } else if (edit == 'lineEdit') {
            this.$eventBus.$emit('setLength', obj)
          }
        } else if (edit == 'lineEdit') {
          this.$eventBus.$emit('setLength', obj)
        } else {
          this.$eventBus.$emit('wiredata', { })
        }
      })
    },
    contrastPath(oldPath, newPath) {
      let index = 0
      if (oldPath.length <= newPath.length) {
        for (let i = 0; i < newPath.length; i++) {
          let item = newPath[i]
          if (oldPath[i] && (item.lat != oldPath[i].lat && item.lng != oldPath[i].lng)) {
            index = i
            break
          }
        }
      } else if (oldPath.length < newPath.length) {
        index = 'noPoint'
      }
      return index
    },
    centerPointGetFourPoint(lnglat) {
      let margin = 200
      let Pixel = this.map.lngLatToContainer(lnglat)
      let newPixel = new AMap.Pixel(Pixel.x - margin, Pixel.y)
      const newLnglat = this.map.containerToLngLat(newPixel)

      let side = AMap.GeometryUtil.distance(lnglat, newLnglat)

      const centerPoint = lnglat
      const upLeftPoint = centerPoint.offset(-parseInt(side / 2), parseInt(side / 1.9))
      const upRightPoint = centerPoint.offset(parseInt(side / 2), parseInt(side / 1.9))
      const leftBottomPoint = centerPoint.offset(-parseInt(side / 2), -parseInt(side / 1.7))
      const rightBottomPoint = centerPoint.offset(parseInt(side / 2), -parseInt(side / 1.7))
      return {
        upLeftPoint: [upLeftPoint.lng, upLeftPoint.lat],
        upRightPoint: [upRightPoint.lng, upRightPoint.lat],
        leftBottomPoint: [leftBottomPoint.lng, leftBottomPoint.lat],
        rightBottomPoint: [rightBottomPoint.lng, rightBottomPoint.lat]
      }
    },
    drawInfo() {
      let that = this
      this.$refs.toolInfo.initialize({
        item: that.markForm
      })
    },
    clearDraw() {
      this.map.remove(this.drawlays)
      this.drawlays = []
      this.getMark()

      this.drawState = false
      this.closeDraw()
      if (this.$refs.rightTool) {
        this.$refs.rightTool.toolActive = ''
      }

    },
    mapZoom(zoom) {
      this.$refs.Toolbar.zoom = zoom
      if (this.$refs.rightTool) {
        this.$refs.rightTool.zoom = zoom
      }
    },
    //图层dom
    toolLayerChange() {
      this.$refs.toolLayer.changeShow()
    },
    closeDraw() {
      this.mouseTool.close(true)
    },
    //

    //绘制矢量图形
    draw(type, isTip) {
      let status = true
      let that = this
      this.nowType = type
      switch (type) {
        case 'marker': {
          this.mouseTool.marker({

            icon: isTip ? hq : markIcon,
            anchor: 'bottom-center'

          })
          status = false
          break
        }
        case 'polyline': {

          this.mouseTool.polyline({
            strokeColor: '#3e8bff',
            strokeWeight: 3
          })
          that.tipMessege = '移动鼠标，双击结束绘制'

          break
        }
        case 'polygon': {
          this.mouseTool.polygon({
            fillColor: '#00b0ff',
            strokeColor: '#80d8ff'
          })
          that.tipMessege = '移动鼠标，双击结束绘制'
          break
        }
        case 'circle': {
          this.mouseTool.circle({
            fillColor: '#00b0ff',
            strokeColor: '#80d8ff'
          })
          that.tipMessege = '按住鼠标左键移动，松开鼠标左键结束绘制'
          break
        }
        case 'rectangle': {
          this.mouseTool.rectangle({
            fillColor: '#00b0ff',
            strokeColor: '#80d8ff'
          })
          that.tipMessege = '按住鼠标左键移动，松开鼠标左键结束绘制'
          break
        }
        case 'rule': {
          this.mouseTool.rule({
            d: 1,
            lineOptions: {
              strokeStyle: 'solid',
              strokeColor: '#ffcc33',
              strokeOpacity: 1,
              strokeWeight: 2
            }
          })
          status = false
          break
        }
      }
      this.showTip = status
      this.tipPoint = []
    },
    onClick(e) {
      let that = this
      that.tipPoint.push(e.lnglat)
      if (this.showTip && that.tipPoint.length < 2) {
        let infoWindowContent = `<div class="drawTip">${that.tipMessege}</div>`
        this.tipInfo = new AMap.InfoWindow({
          anchor: 'top-left',
          content: infoWindowContent
        })
        that.tipInfo.open(that.map, e.lnglat)

        setTimeout(() => {
          that.tipInfo.close()
        }, 1500)
      }
    },
    getFeatrue(lnglat) {
      this.$refs.markInfo.getFeatrue(lnglat)
    }
  },
  beforeDestroy() {
    if (this.map) {
      this.map.destroy()
    }
    if(!gis){
      clearInterval(this.timer);
    }
  }
}
