<template>
  <div class="mapMain">
    <div id="amap" ref="amap"></div>
      <zoom ref="zoom" />
      <toolbar ref="Toolbar"  />
      <!-- <search :modeal='modeal'/> -->
      <!-- <rightTool
          ref="rightTool"
          @closeDraw="closeDraw"
          @draw="draw"
        /> -->
      <!-- <toolInfo ref="toolInfo" /> -->
      <layerWms :layers="layers" ref="layerWms" />
      <!-- <markInfo ref="markInfo" /> -->
      <!-- <layerEdit ref="layerEdit" /> -->
  </div>
</template>

<script>
import zoom from '@/views/mapTool/question/zoom'
import Toolbar from '@/views/mapTool/leftTool/Toolbar'
// import search from '@/views/mapTool/leftTool/search'
// import rightTool from '@/views/mapTool/rightTool/index'
// import toolInfo from '@/views/mapTool/layer/toolInfo'
import { mapMixin } from '@/common/amapInit'
import layerWms from '@/views/mapTool/layer/layerWms'
// import markInfo from '@/views/mapTool/layer/markInfo'
// import layerEdit from '@/views/mapTool/layer/layerEdit'
import { userPath } from '@/apis/commonType'
import { queryFeatrue } from '../Gis/apis/geo'
import { formatLocation } from '../Gis/utils/geoTool'
export default {
  name: 'Query',
  components: {
   zoom,
   Toolbar,
  //  search,
  //  rightTool,
  //  toolInfo,
   layerWms,
  //  markInfo,
  //  layerEdit
  },
  mixins: [mapMixin],
  props: {
    // modeal: {
    //   type: String,
    //   default: ''
    // },
    // layerName: {
    //   type: String,
    //   default: ''
    // },
    layers:{
      type: String,
      default: "'patrol_events','patrol_equipments','patrol_lines','user'"
    },
// ,'patrol_networks' 片区
  },
  data() {
    return {
      mouseTool:null,
      pLoaction: false,
      markerHighLight:{},
      userpolyline:null,
      polyline1Add:null,
      end:require('@/assets/end.png'),
      start:require('@/assets/start.png'),
      labelsLayerUser:null,
      setLayerRows:{}
    }
  },
  computed: {},
  watch: {},
  mounted() {
    let that=this
     this.setLayerRows = JSON.parse(this.$route.query.setLayerRow)
    that.initMap()
   
    
  },

  methods: {
   
    setCenterFun(point){
        this.map.setCenter(point)
    },
    
    SetMarkerHighLight(e){
      this.map.remove(this.markerHighLight)
      this.markerHighLight = new AMap.CircleMarker({
        center:e,
        radius:15,//3D视图下，CircleMarker半径不要超过64px
        strokeColor:'white',
        strokeWeight:2,
        strokeOpacity:0.5,
        fillColor:'red',
        fillOpacity:0.5,
        zIndex:10,
        bubble:true,
        clickable: true
      })
      this.markerHighLight.setMap(this.map)
    },
    setCenter(arr){
      this.map.setCenter(arr)
    },
    setUserPath(e){
       if(this.userpolyline){
          this.map.remove(this.userpolyline)
        }
        if(this.labelsLayerUser){
           this.map.remove(this.labelsLayerUser)
        }
        
        let that=this

        userPath({userId:e.id}).then(res => {
          let list=res.data.records
           if(list){
              let locations=list[0].locations
              if(locations&&locations.length>0){
          
                this.userpolyline = new AMap.Polyline({
                    path: locations,
                    isOutline: true,
                    outlineColor: '#ffeeff',
                    borderWeight: 2,
                    strokeColor: "#3366FF",
                    strokeOpacity: 1,
                    strokeWeight: 4,
                    strokeStyle: "solid",
                    lineJoin: 'round',
                    lineCap: 'round',
                    zIndex: 2,
                  })
                  this.map.add(this.userpolyline)

                    let markers=[]
                     let startPoint = new AMap.LabelMarker({
                        position: locations[0],
                        zIndex: 20,
                        icon: {
                          size: [19, 30],    // 图标尺寸
                          anchor: 'bottom-center',
                          image: that.start,  // Icon的图像地址
                        },                        
                      })       
                      markers.push(startPoint)
                      let l=locations.length-1
                      console.log(locations[l])
                      let endPoint = new AMap.LabelMarker({
                        position: locations[l],
                        zIndex: 21,
                        icon: {
                          size: [19, 30],    // 图标尺寸
                          anchor: 'bottom-center',
                          image: that.end, 
                        },
                        text: {
                          content: list[0].username,
                          direction: 'right',
                          offset: [0, 0,0,0],
                          style: {
                            fontSize: 12,
                            fillColor: '#fff',
                            strokeColor: '#000',
                            strokeWidth: 2
                          }
                        }
                      })      
                    //   markers.push(endPoint)
                    //   that.labelsLayerUser = new AMap.LabelsLayer({
                    //     zooms: [0, 20],
                    //     zIndex: 160,
                    //     collision: false,
                    //     allowCollision: false,
                    // });
                    //   that.labelsLayerUser.add(markers)
                    //   that.map.add(that.labelsLayerUser)


              }
           }
        })
    },
   
    clearLocation(){
       this.pLoaction = false
       this.drawState = false
       if(this.drawlays){
         this.map.remove(this.drawlays)
         this.mouseTool.close(true)
       }       
    },
    getLocation(type) {
      this.drawState = true
      this.pLoaction = true
      if(type=='line'){
        this.draw('polyline')  
      }else if(type=='polygon'){
        this.draw('polygon')  
      }else{
        this.draw('marker')  
      }       
    },
   
    initMap() {
      let that = this
      let map = new AMap.Map('amap', {
        ...that.mapConfig
      })
      that.map = map


      that.map.plugin(["AMap.Scale"],function(){
          let scale = new AMap.Scale();
          that.map.addControl(scale);
      });

      that.map.plugin(["AMap.MouseTool"],function(){
         that.mouseTool = new AMap.MouseTool(that.map)
         that.drawEnd()  
     });
           
      that.map.on('complete', function() {

            that.$refs.zoom.init()
            that.getMark()
            that.map.on('click', e => {
              if (!that.drawState) {
                that.$refs.markInfo.getFeatrue(e.lnglat)
              }
            })
            that.map.on('mousedown', e => {
              if (that.drawState) {
                that.onClick(e)
              }
            })
            // that.$refs.layerWms.wmsLayer()
            if(that.setLayerRows.coverage) {
              let coverage=that.setLayerRows.coverage
              let network=that.setLayerRows.location
              let pos = []
              network.forEach(element => {
                pos.push(element[0] + ' ' + element[1])
              })
              pos.push(pos[0])
              let posStr = pos.join(',')  
                
              let layersVue1 = coverage
              that.$refs.layerWms.department('','linqing:lineWalking',posStr,layersVue1,that.setLayerRows.networkId);
            } else {
              that.$refs.layerWms.department('','linqing:lineWalking');
            }

            that.drawLineSplic(that.setLayerRows.coverage ? that.setLayerRows : that.$store.getters.setLayerRowes)


      })
    },
     drawLineSplic(row){
    let that=this  
      let overlayGroups = new AMap.OverlayGroup({
          collision: false,
          allowCollision: false
      })
      that.map.add(overlayGroups)
      let coverage=row.coverage
      let network=row.location
      let pos = []
      network.forEach(element => {
        pos.push(element[0] + ' ' + element[1])
      })
      pos.push(pos[0])
      let posStr = pos.join(',')     
      let layersVue1 = coverage
      let params = {
        SERVICE: 'wfs',
        VERSION: '1.0.0',
        REQUEST: 'GetFeature',
        typeName: 'linqing:view_all_line_q',
        outputFormat: 'application/json',
        viewparams: `netid:${row.networkId}`,
         CQL_FILTER:
            "coverage in (" + layersVue1 + ") and INTERSECTS(location,POLYGON((" + posStr + ")))"
        }
        if (!layersVue1) {
         params.CQL_FILTER = '1=2'
        }  
         const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });      
      queryFeatrue(params).then(res => {//调取管线
         loading.close();
       
            let newLine=[]
            let f = res.features
            let line=[]
              f.forEach(item => {
                let location=formatLocation(item.properties.location_str,'LineString')               
                    for(let i=1;i<location.length;i++){                 
                      let sub=[Number(location[i-1][0]),Number(location[i-1][1])]
                      let sub1=[Number(location[i][0]),Number(location[i][1])]                
                      line.push({...item.properties,line:[sub1,sub]})
                    }               
            })

              let py=network
            // py.push(py[0])//首尾相接
            
              line.forEach(obj => {//切割
                  let item=obj.line
                  var line1 = turf.lineString(item);
                  var line2 = turf.lineString(py);
                  var intersects = turf.lineIntersect(line1, line2);  
                  if(intersects.features.length<1){//没相交是面内
                      let pn = turf.polygon([py]);                 
                      let isHave=turf.booleanContains(pn, line1);
                      if(isHave){                   
                         newLine.push({...obj})
                      }                
                  }else if(intersects.features.length>0){//一条线相交了两次及以上
                    var line = turf.lineString(item);
                    var splitter = turf.lineString(py);
                    var splitline = turf.lineSplit(line, splitter);     
                    splitline.features.forEach(fea => {     
                      let feaLine=  fea.geometry.coordinates
                      let pn = turf.polygon([py]);
                      let pl = turf.lineString(feaLine);
                      let isHave=turf.booleanContains(pn, pl);
                      if(isHave){
                        obj.line=feaLine
                        newLine.push({...obj})
                      }
                    }); 
                  }
              })
      let group=[]
      for(let i=0;i<newLine.length;i++){
          let polyline = new AMap.Polyline({
            path: newLine[i].line,
            strokeColor: newLine[i].style_color,
            strokeOpacity: 1,
            strokeWeight: 2,
            strokeStyle: "solid",
            strokeDasharray: [10, 5],
            lineJoin: "round",
            lineCap: "round",
            zIndex: 2021,
          });
        //  this.map.add(polyline);
         group.push(polyline)
      }
       overlayGroups.addOverlays(group)
      
          
      })

    },
  }
}
</script>
<style lang="scss" src="./skin.scss"></style>
<style lang="scss" src="./common.scss"></style>

<style lang="scss" scoped>
.subMap {
  width: 100%;
  height: 100%;
  
}
.mapMain{position: relative;height: 100%}
#amap{width: 100%;height: calc(100vh);}

::v-deep .amap-scalecontrol {
  color: var(--font-color-skin);
  background: none !important;
  left: 0.7rem !important;
  bottom: 0.1rem !important;
}
::v-deep .tool {
  display: flex;
  top: 0.1rem;
  li {
    margin-left: 0.1rem;
    margin-top: 0;
  }
}
::v-deep .bottom {
  width: 65%;
}

::v-deep {
  .rightTool {
    right: 0rem !important;
    height: 100%;
  }

  .toolbar {
    bottom: 1.1rem;
  }
  .MapChangeClass {
    bottom: 0.1rem;
  }
  .legend {
    bottom: 0.9rem;
  }
}
</style>

