// import dictApi from '@/apis/dict'
import dropDownApi from '@/apis/dropDown'
import gasStationApi from '@/apis/siteAdministration/gasStationApi'
const dictMap = {
  '100019': 'gasStationDict',
  '100032': 'userStationDict',
  '300081': 'dataTypeDict',
}
const state = {
  msgNum: null,
  stationSecondType: [],// 站点类型
  stationType: [],// 站点分类
  usingStatus: [],// 使用状态
  gasType: [],// 燃气介质
  scadaStationImportant: [],// 重点监测
  pointStatus: [],//状态
  dataType: [],//数据类型
  monitorPointPressureType: [],//压力类型
  scadaMonitorPointImportant: [],// 监测点位重点检测
  equipmentType: [],// 设备类型
  CollectionPointDataType: [],// 采集点数据类型
  WarningLevel: [],// 告警级别
  equipmentStatus: [],//设备状态 
  installType:[],//安装类型
  monitorPointStatus:[],//监测点位状态
  dataTypeDict:{},
  isTransmit: [],//是否远传
  valveKind: [],//阀门种类
  stationDict: [],//场站下拉
}

Object.values(dictMap).forEach(key => (state[key] = state[key] || []))

const mutations = {
  SETDATA: (state, { name, data }) => {
    state[name] = data
  }
}
const getters = {
  
}
const actions = {
  /**
   * @description		获取字典树
   * @author			hauner
   * @data			2022-03-07
   */
  async getDict({ state, commit }, params) {
    if (!params.parentCode.length) return
    let res = (await dropDownApi.getDicts(params)) || []
    if (res.data) {
      let key = params.parentCode
      let data = res.data
      let name = dictMap[key]
      commit('SETDATA', { name, data })
    }
  },
  /**
   * @description		获取字典树
   * @author			hauner
   * @data			2022-03-07
   */
  async getDict1({ state, commit }, params) {
    if (!params.parentCode.length) return
    let res = (await dropDownApi.getDicts1(params)) || []
    if (res.data) {
      let key = params.parentCode
      let data = res.data
      let name = dictMap[key]
      commit('SETDATA', { name, data })
    }
  },
  // 供应商下拉框
  // 下拉框
  async getDropdown({ state, commit }, params) {
    let paramsArr = []
    if(params) paramsArr = params.split(',')
    let res = await dropDownApi.getDropdown({names: params})
    let data = res.data || []
    paramsArr.forEach(e => {
      let arr = data[e] || []
      commit('SETDATA', { name: e, data: arr })
    })
  },
  // 数据类型下拉框
  async getDataType({ state, commit }, params) {
    let res = await dropDownApi.getDataType()
    let data = res.data || []
    let name = 'dataType'
    commit('SETDATA', { name, data })
  },
  // 场站下拉
  async selectStationOptions({ state, commit }, params) {
    let res = await gasStationApi.selectStationOptions(params)
    let data = res.data || []
    let name = 'stationDict'
    commit('SETDATA', { name, data })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
