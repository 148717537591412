//  引入拦截器
import axios from '@/common/js/request';

export default {
    // 请求字典：赵洋
    getOptions(params) {
        return axios({
            url:'/options/enum',
            method:'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            params
        })
    },
    getOptionsKyes(params) {
        return axios({
            url:'/options/keys',
            method:'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            params
        })
    },
    // 实时数据：张羽
    getRealTime(params) {
        return axios({
            url:'/scada/realTime/realTime',
            method:'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            params
        })
    },
    // 正常、告警统计值：张羽
    getStatistics(params) {
        return axios({
            url:'/scada/realTime/statistics',
            method:'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            params
        })
    },
    // 历史数据：张羽
    getHistory(params) {
        return axios({
            url:'/scada/history/page',
            method:'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            params
        })
    },
    // 查询场站下拉框：刘亚林
    getStationOptions(params) {
        return axios({
            url:'/scada/station/selectStationOptions',
            method:'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            params
        })
    },
    // 查询监测点下拉框：刘亚林
    selectMonitorPointOptions(params) {
        return axios({
            url:'/scada/monitorPoint/selectMonitorPointOptions',
            method:'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            params
        })
    },
    // 历史数据导出 ：张羽
    exportScadaHistory(params) {
        return axios({
            url:'/scada/history/export',
            method:'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            responseType: 'blob', //改变接收的值类型
            params
        })
    },
    // 历史数据 导出
    // pipeExportStandingBook(params){
    //     const pipeExportStandingBook = axios({
    //         url: '/pipe/exportStandingBook',
    //         method: 'get',
    //         params,
    //         headers: {
    //             'Content-Type': 'application/x-www-form-urlencoded'
    //         },
    //         responseType: 'blob' //改变接收的值类型
    //     })
    //     return pipeExportStandingBook
    // }
    //  请求模板列表
    // getTableData(params) {
    //     return axios({
    //         url:'/workTemplate/selectTemplate',
    //         method:'GET',
    //         headers: {
    //             'Content-Type': 'application/json'
    //         },
    //         params
    //     })
    // },
    // //  新增模板
    // addNewData(data) {
    //     return axios({
    //         url:'/workTemplate/insertTemplate',
    //         method:'POST',
    //         headers:{
    //             'Content-Type':'application/json'
    //         },
    //         data
    //     })
    // },
    // 设备分页查询：赵洋
    getEquipmentLists(params) {
        return axios({
            url:'/equipment/page',
            method:'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            params
        })
    },
    // 告警分页查询：张羽
    getWarningPage(params) {
        return axios({
            url:'/scada/warning/page',
            method:'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            params
        })
    },
    //  设备新增:赵洋
    addEquipmentData(data) {
        return axios({
            url:'/equipment/save',
            method:'POST',
            headers:{
                'Content-Type':'application/json'
            },
            data
        })
    },
    // 设备查询单条：赵洋
    getEquipmentfindByIds(params) {
        return axios({
            url:'/equipment/findById',
            method:'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            params
        })
    },
    // 停止声光告警：张羽
    stopLightVoice(params) {
        return axios({
            url:'/scada/warning/stopLightVoice',
            method:'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            params
        })
    },
    //  设备修改:赵洋
    addEquipmentupdate(data) {
        return axios({
            url:'/equipment/update',
            method:'POST',
            headers:{
                'Content-Type':'application/json'
            },
            data
        })
    },
    //  设备启用-禁用:赵洋
    addEquipmentstatus(data) {
        return axios({
            url:'/equipment/status',
            method:'POST',
            headers:{
                'Content-Type':'application/json'
            },
            data
        })
    },
    // 设备删除：赵洋
    getEquipmentDelete(params) {
        return axios({
            url:'/equipment/delete',
            method:'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            params
        })
    },
    // 告警导出：张羽
    exportWarn(params) {
        return axios({
            url:'/scada/warning/export',
            method:'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            responseType: 'blob', //改变接收的值类型
            params
        })
    },
    //  监测点查询列表:刘亚林
    addSelectLists(data) {
        return axios({
            url:'/scada/monitorPoint/selectList',
            method:'POST',
            headers:{
                'Content-Type':'application/json'
            },
            data
        })
    },
    //  根据scada场站id查询列表:刘亚林
    getselectByScadaStationIdLists(params) {
        return axios({
            url:'/scada/monitorPoint/selectByScadaStationId',
            method:'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            params
        })
    },
    //  添加检测点位 :刘亚林
    addPoint(data) {
        return axios({
            url:'/scada/monitorPoint/insertMonitorPoint',
            method:'POST',
            headers:{
                'Content-Type':'application/json'
            },
            data
        })
    },
    //  添加监测点位:刘亚林
    addinsertMonitorPoints(data) {
        return axios({
            url:'/scada/monitorPoint/insertMonitorPoint',
            method:'POST',
            headers:{
                'Content-Type':'application/json'
            },
            data
        })
    },
    //  编辑监测点位:刘亚林
    updateinsertMonitorPoints(data) {
        return axios({
            url:'/scada/monitorPoint/updateById',
            method:'POST',
            headers:{
                'Content-Type':'application/json'
            },
            data
        })
    },
    // 监测点位根据id查询：刘亚林
    getPointById(params) {
        return axios({
            url:'/scada/monitorPoint/selectById',
            method:'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            params
        })
    },
    // 监测点位启用停用：刘亚林
    settogglePointStatus(params) {
        return axios({
            url:'/scada/monitorPoint/togglePointStatus',
            method:'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            params
        })
    },
    // 告警处置：张羽
    handleWarning(data) {
        return axios({
            url:'/scada/warning/handleWarning',
            method:'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            data
        })
    },
    // 告警处置详情：张羽
    handleInfo(params) {
        return axios({
            url:'/scada/warning/handleInfo',
            method:'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            params
        })
    },
    // 分页查询未绑定采集点：刘亚林
    getpageNoBind(params) {
        return axios({
            url:'/scada/collectionPoint/pageNoBind',
            method:'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            params
        })
    },
    // 阈值分页查询：张羽
    thresholdConfigPage(params) {
        return axios({
            url:'/scada/thresholdConfig/page',
            method:'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            params
        })
    },
    // 绑定采集点：刘亚林
    bindCollectPoint(params) {
        return axios({
            url:'/scada/monitorPoint/bindCollectPoint',
            method:'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            params
        })
    },
    // 阈值查询单条：张羽
    thresholdConfigId(params) {
        return axios({
            url:'/scada/thresholdConfig/findById',
            method:'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            params
        })
    },
    // 解绑采集点：刘亚林
    unbindCollectPoint(params) {
        return axios({
            url:'/scada/monitorPoint/unbindCollectPoint',
            method:'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            params
        })
    },
    //  绑定-解绑监测点:赵洋
    setmonitorPoint(data) {
        return axios({
            url:'/equipment/monitorPoint',
            method:'POST',
            headers:{
                'Content-Type':'application/json'
            },
            data
        })
    },
    // 阈值新增：张羽
    thresholdConfigSave(data) {
        return axios({
            url:'/scada/thresholdConfig/save',
            method:'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            data
        })
    },  
    // 阈值修改：张羽
    thresholdConfigUpdate(data) {
        return axios({
            url:'/scada/thresholdConfig/update',
            method:'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            data
        })
    },
    // 数据看板-用户站压力监测 张羽
    pressureMonitoring(params) {
        return axios({
            url:'/screen/pressureMonitoring',
            method:'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            params
        })
    },
    // 数据看板-近30日供气趋势 张羽
    gasSupplyTrend(params) {
        return axios({
            url:'/screen/gasSupplyTrend',
            method:'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            params
        })
    },
    // 已创建工艺图场站列表
    ztUserHistoryInfo(params) {
        return axios({
            url:'/ztUserHistoryInfo/page',
            method:'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            params
        })
    },
    // 已创建工艺图场站列表
    ztUserHistoryInfo(params) {
        return axios({
            url:'/ztUserHistoryInfo/page',
            method:'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            params
        })
    },
    // 未创建工艺图场站列表
    getStation(params) {
        return axios({
            url:'/ztConfigInfo/stationParameters',
            method:'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            params
        })
    },
    // 创建工艺图
    buildSave(data) {
        return axios({
            url:'/ztConfigInfo/save',
            method:'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            data
        })
    },
    // 修改工艺图
    updateConfigure(data) {
        return axios({
            url:'/ztConfigInfo/update',
            method:'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            data
        })
    },
    // 发布
    updateForApply(data) {
        return axios({
            url:'/ztConfigInfo/updateForApply',
            method:'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            data
        })
    },
    // 查看工艺图
    findById(params) {
        return axios({
            url:'/ztConfigInfo/findById',
            method:'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            params
        })
    },  
    // 气源供气情况
    screen3(params) {
        return axios({
            url:'/screen/screen3',
            method:'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            params
        })
    },
    // 重点监测用户站用气量情况
    screen4(params) {
        return axios({
            url:'/screen/screen4',
            method:'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            params
        })
    },
    // 阈值配置数据类型
    dataTypeOptions(params) {
        return axios({
            url:'/scada/thresholdConfig/dataTypeOptions',
            method:'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            params
        })
    }, 
    // 实时数据数据类型
    eqTypeOptions(params) {
        return axios({
            url:'/scada/realTime/eqTypeOptions',
            method:'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            params
        })
    }, 
    // 删除组态配置
    deleteConfig(params) {
        return axios({
            url:'/ztConfigInfo/delete',
            method:'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            params
        })
    }, 
    // 根据场站id查询摄像头信息
    getVideoUrl(params) {
        return axios({
            url:'/scada/video/selectByStationId',
            method:'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            params
        })
    }, 
}