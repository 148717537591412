//  引入拦截器
import axios from '@/common/js/request';

export default {
    //  获取列表
    getList(data) {
        return axios({
            url: '/scada/station/selectList',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            data
        })
    },
    //  新增场站
    addGasStation(data) {
        return axios({
            url: '/scada/station/insertScadaStation',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            data
        })
    },
    //  查询场站单条数据
    getStationInfo(params) {
        return axios({
            url: '/scada/station/selectById',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            params
        })
    },
    //  修改场站
    updateStation(data) {
        return axios({
            url: '/scada/station/updateById',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            data
        })
    },
    // 删除场站
    deleteStation(params) {
        return axios({
            url: '/scada/station/deleteById',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            params
        })
    },
    //  查询未关联GIS场站列表
    getGisStationList(data) {
        return axios({
            url: '/scada/station/selectGisStationList',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            data
        })
    },
    //  绑定scada场站与gis场站
    bindGisScada(data) {
        return axios({
            url: '/scada/station/bindGisScada',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            data
        })
    },
    // 解绑scada场站与gis场站
    unBindGisScada(params) {
        return axios({
            url: '/scada/station/unBindGisScada',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            params
        })
    },
    // 场站下拉
    selectStationOptions(params) {
        return axios({
            url: '/scada/station/selectStationOptions',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            params
        })
    },
    //  导出
    exportList(data) {
        return axios({
            url: '/scada/station/exportList',
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            responseType: 'blob', //改变接收的值类型
            data
        })
    },
}