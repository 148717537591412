import axios from 'axios'
import { Loading } from 'element-ui'
import global from '@/global';
let loading

function startLoading() {
  loading = Loading.service({
    lock: true,
    text: '加载中……',
    background: 'rgba(0, 0, 0, 0.7)',
    fullscreen: true
  })
}
function endLoading() {
  loading.close()
}
let needLoadingRequestCount = 0
export function showFullScreenLoading() {
  if (needLoadingRequestCount === 0) {
    startLoading()
  }
  needLoadingRequestCount++
}

export function tryHideFullScreenLoading() {
  if (needLoadingRequestCount <= 0) return
  needLoadingRequestCount--
  if (needLoadingRequestCount === 0) {
    endLoading()
  }
}

//http request 拦截器
const serviceGeo = axios.create({
  baseURL: global.geoUrl, // api 的 base_url
  timeout: 300000 // request timeout
})

serviceGeo.defaults.withCredentials = true
serviceGeo.interceptors.request.use(
  config => {
    return config
  },
  error => {
    return Promise.reject(err)
  }
)
serviceGeo.interceptors.response.use(
  response => {
    //   tryHideFullScreenLoading()
    return response.data
  },
  error => {
    //  tryHideFullScreenLoading()
    return Promise.reject(error)
  }
)

export default serviceGeo
