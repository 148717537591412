import axios from '@/common/js/request'
//列表
export function bzMessageConfig_pageUserMessage(params){
    const pcAllList = axios({
        url: '/bzMessageConfig/pageUserMessage',
        method: 'get',
        params
    })
    return pcAllList
}
export function bzMessageConfig_userMessageRead(params){
    const pcAllList = axios({
        url: '/bzMessageConfig/userMessageRead',
        method: 'get',
        params
    })
    return pcAllList
}
export function bzMessageConfig_userMessageAllRead(params){
    const pcAllList = axios({
        url: '/bzMessageConfig/userMessageAllRead',
        method: 'get',
        params
    })
    return pcAllList
}

