<template>
    <div class="tabs">
        <el-dialog :visible.sync="dialogVisibleWarn"
            width="1026px"
            top="20vh"
            @close="closeDialogWarn"
            :close-on-click-modal="false">
            <template slot="title">
                <div class="dialogTitles"><span></span> <b>告警信息</b></div>
            </template>
            <el-table
                :data="tableData"
                border
                height="480"
                :row-class-name="tableRowClassName"
            >
                <el-table-column
                    label="序号"
                    type="index"
                    width="52"
                >
                </el-table-column>
                <el-table-column
                v-for="col in head"
                :key="col.prop"
                :prop="col.prop"
                :label="col.label"
                align="left"
                show-overflow-tooltip
                :sortable="col.sorts"
                :width="col.widths"
                >
                    <template v-if="col.isBar" #default="{ row }">
                    <div v-if="col.prop == 'val'">
                        {{ row.val }}{{row.unit}}
                    </div>
                    <div v-if="col.prop == 'handleStatus'">
                        {{ row.handleStatus == 1 ? '已处理' : '未处理' }}
                    </div>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="175">
                    <template slot-scope="{ row }">
                        <span v-if="row.lightVoiceStatus == 1" class="linkBtn" style="margin-right:16px" @click="closeAlarm(row)">停止声光报警</span>
                        <span class="linkBtn" @click="toDispose(row)">告警处置</span>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageParams.current"
            :page-sizes="[30, 50, 100]"
            :page-size="pageParams.size"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            class="TablePages"
            >
            </el-pagination>
        </el-dialog>
        <el-dialog
            :visible.sync="dialogVisible"
            width="470px"
            top="20vh"
            @close="closeDialog"
            :close-on-click-modal="false"
        >
            <template slot="title">
                <div class="dialogTitles"><span></span> <b>告警处置</b></div>
            </template>
            <div class="note">
                {{disposeForm.handleNote}}
            </div>
            <div class="notes">
                <span>处置人：</span> {{disposeForm.handler}}
            </div>
            <div class="notes">
                <span>处置时间：</span> {{disposeForm.handleTime}}
            </div>
        </el-dialog>
        <el-dialog
            :visible.sync="dialogVisibleHandle"
            width="470px"
            top="20vh"
            @close="closeDialogHandle"
            :close-on-click-modal="false"
        >
            <template slot="title">
                <div class="dialogTitles"><span></span> <b>告警处置</b></div>
            </template>
            <p style="color:#0F0F0F">处置结果</p>
            <el-input class="textDom" type="textarea" placeholder="请输入" v-model="disposeForms.handleNote" :rows="3"></el-input>
            <div class="btnGroup">
                <el-button @click="dialogVisibleHandle = false">取消</el-button>
                <el-button type="primary" @click="saveHandle()">保存</el-button>
            </div>
        </el-dialog>
        <audio ref="audio" hidden src="../assets/warning.mp3" loop></audio>
    </div>
</template>

<script>
import scadaApi from "@/apis/scadaApi";
import warnSocket from "@/common/js/warnSocket";
export default {
    name: 'abnormalData',
    components: {},
    mixins: [warnSocket],
    data() {
        return {
            pageParams:{
                size: 100,
                current:1,
                handleStatus:0
            },
            dialogVisibleWarn:false,
            time:[],
            tableData:[],
            head:[
                { prop: 'location', label: '所在位置' },
                { prop: 'equipmentName', label: '设备名称' },
                { prop: 'monitorPointName', label: '监测点位'},
                { prop: 'val', label: '远传数据',isBar:true },
                { prop: 'dataStatus', label: '数据状态',widths:'80px' },
                { prop: 'warningDescribe', label: '异常信息' },
                { prop: 'handleStatus', label: '是否处理',isBar:true,widths:'80px' },
                { prop: 'uploadTime', label: '数据时间' },
                // { prop: 'collectTime', label: '采集时间' },
            ],
            total:0,
            dataTypeList:[],
            dataStatusList:[],
            handleStatus:[],
            dialogVisible:false,
            disposeForm:{
                handleNote:'',
                handler:'',
                handleTime:''
            },
            dialogVisibleHandle:false,
            disposeForms:{
                handleNote:'',
                id:''
            }
        }
    },
    mounted(){
        // 设备类型
        // scadaApi.getOptions({names:"CollectionPointDataType,WarningLevel,HandleStatus"}).then(res => {
        //     this.dataTypeList = res.data.CollectionPointDataType
        //     this.dataStatusList = res.data.WarningLevel
        //     this.handleStatus = res.data.HandleStatus
        // })
        // 获取列表
        // this.getList()
    },
    computed: {
    },
    methods: {
        closeDialogWarn(){
            this.$refs.audio.pause()
            this.pageParams.size = 30
            this.pageParams.current = 1
            this.tableData = []
            this.total = 0
        },
        tableRowClassName({ row, rowIndex }) {
            if (row.lightVoiceStatus == 1) {
                return 'double'
            }
        },
        getList(reset){
            let that = this
            if(reset){
                this.pageParams.current = 1
                this.pageParams.size = 30
            }
            scadaApi.getWarningPage(this.pageParams).then(res => {
                this.tableData = res.data.list
                this.total = res.data.total
                let palyAutio = false
                res.data.list.forEach(item => {
                    if(item.lightVoiceStatus == 1){
                        palyAutio = true
                    }
                })
                if(palyAutio){
                    setTimeout( () => {
                        that.$refs.audio.play()
                    },20)
                }
            })
        },
        //关闭声光告警
        closeAlarm(row) {
            this.$confirm(`确定关闭${ row.equipmentName || '' }声光告警?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                closeOnClickModal: false,
                type: 'warning'
            }).then(() => {
                scadaApi.stopLightVoice({id:row.id}).then(res => {
                    if (res.code === 200) {
                        this.getList()
                        this.$message.success(res.msg)
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            }).catch()
        },
        closeDialog(){
            this.disposeForm = {
                handleNote:'',
                handler:'',
                handleTime:''
            }
        },
        toDispose(row){
            this.disposeForms.id = row.id
            this.dialogVisibleHandle = true
        },
        saveHandle(){
            if(this.disposeForms.handleNote){
                scadaApi.handleWarning(this.disposeForms).then(res => {
                    this.$message.success(res.msg)
                    this.getList()
                    this.dialogVisibleHandle = false
                })
            }else{
                this.$message.info('请输入告警处置')
            }
        },
        closeDialogHandle(){
            this.disposeForms={
                handleNote:'',
                id:''
            }
        },
        handleSizeChange(e){
            this.pageParams.size = e
            this.getList()
        },
        handleCurrentChange(e){
            this.pageParams.current = e
            this.getList()
        },
    }
}
</script>

<style lang="scss" scoped>
::v-deep.input-with-select .el-input-group__prepend{
    background-color: #fff;
    color: #0F0F0F;
}
::v-deep.dialogTitles{height:25px !important;line-height: 25px !important;}
::v-deep .double{
  animation:beat 1s infinite;
}
::v-deep.el-table thead{
    height: 50px;
    line-height: 30px;
}
@keyframes beat{
  0%{transform: scale(1,1); opacity: 1;background-color: #fff;color:red}
  100%{transform: scale(1,1); opacity: 1;background-color: red;color:#fff}
}
.linkBtn{
    cursor: pointer;
    color: #1082FF;
}
.note{
    color: #303133;
    font-size: 14px;
    line-height: 28px;
    padding-bottom: 20px;
    border-bottom: 1px solid #EBEBEB;
    span{
        color: #909399;
    }
}
.notes{
    font-size: 14px;
    color: #303133;
    padding-top: 14px;
    span{
        color: #909399;
        font-size: 14px;
    }
}
.btnGroup{
    padding-top: 12px;
    text-align: center;
}
.textDom{
    padding-bottom: 20px;
    padding-top: 6px;
    border-bottom: 1px solid #EBEBEB;
}
.tabs {
  margin: 10px;
  background-color: #fff;
  height: calc(100vh - 206px);
  padding: 16px 10px;
  .topSearch{
        color: #0F0F0F;
        padding: 0px 0px 16px 10px;
        .selectBox{
            width: 200px;
            margin: 0 30px 0 0;
        }
        .selectBoxMi{
            width: 120px;
            margin: 0 30px 0 0;
        }
        .searchBtn{
            display: inline-block;
        }
    }
}
.TablePages{
    margin: 16px 0 0;
    padding-left: 0;
    height: 25px;
}
</style>