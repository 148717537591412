<template>
    <div>
        <!-- <el-container>
            <el-header>
                <Header></Header>
            </el-header>
            <el-container>
                <el-aside style="width:220px;height:100%;overflow: hidden">
                    <Aside style="height: 100%;"></Aside>
                </el-aside>
                <el-main>
                    <router-view></router-view>
                </el-main>
            </el-container>
        </el-container> -->
      <div class="topheader">
        <Header @ChangeMenuTab='ChangeMenuTab' v-if='HeaderBool' :showChang="false"></Header>
      </div>
      <el-container v-if='HeaderBool' class="main-con">
            
                <Aside ref="aside" style="height: 100%;" v-show="isShowMnu"></Aside>
            
            <el-container class="main-right">                     
                <el-main>
                     <div class="crumbs" v-show="isShowMnu">
                            <span class="collapse"
                                ><i :class="isCollapse ? 'el-icon-s-fold' : 'el-icon-s-unfold'" @click="Collapse"></i
                            ></span>
                            <div class="breadcrumb">
                                <el-breadcrumb separator="/">
                                <!-- <el-breadcrumb-item :to="{ path: '/index' }">
                                    <font>系统选择</font>
                                </el-breadcrumb-item> -->
                                <el-breadcrumb-item v-for="o in selectlist" :key="o.path">
                                    <font v-if="o.name">{{ o.name }}</font>
                                </el-breadcrumb-item>
                                </el-breadcrumb>
                            </div>
                        </div>
                    <scroll-pane ref="scrollPane" class="tags-view-wrapper" v-if="isShowMnu">
                          <router-link  
                                v-for="tag in visitedViews"
                                ref="tag"
                                :key="tag.path"
                                :class="isActive(tag)?'active':''"
                                :to="{ path: tag.path, query: tag.query, fullPath: tag.fullPath }"
                                tag="span"
                                class="tags-view-item"
                                @click.middle.native="closeSelectedTag(tag)"
                                @contextmenu.prevent.native="openMenu(tag,$event)"
                            >
                                {{ tag.title }}
                                <span v-if="tag.name!='首页'" class="el-icon-circle-close" @click.prevent.stop="closeSelectedTag(tag)" />
                            </router-link>
                    </scroll-pane>
                    <router-view></router-view>                    
                </el-main>
            </el-container>
        </el-container>
    </div>
</template>
<script>
    import Header from '../../components/layout/Header'
    import Aside from '../../components/layout/Aside'
    import ScrollPane from '../../components/layout/ScrollPane'
    export default {
        components:{
           Aside,Header,ScrollPane
        },
        name: "index",
        data(){
            return {
                HeaderBool:true,
                isCollapse:true,
                isShowMnu:false
            }
        },
         mounted() {
            this.initTags()
        },
        methods:{
            changeShow(flag){
                this.isShowMnu = flag
            },
            Collapse(){
              this.isCollapse=!this.isCollapse
              this.$refs.aside.Collapse()
            },
            isActive(route){
              return route.path === this.$route.path
            },
            closeSelectedTag(view) {
                this.$store.dispatch('delView', view).then(({ visitedViews }) => {
                    console.log(view)
                    if (this.isActive(view)) {
                    this.toLastView(visitedViews, view)
                    }
                })
            },
            toLastView(visitedViews, view) {
                const latestView = visitedViews.slice(-1)[0]
                if (latestView) {
                    this.$router.push(latestView)
                } else {
                  
                    this.$router.push('/index')
               
                }
            },
            ChangeMenuTab(){
                
                this.$refs.aside.AssideDataFun()
                // var select = JSON.parse(localStorage.getItem('SelectMenuArray'));
                // var selectPath = '/home/'+select[0].children[0].url;
                // this.$router.push(selectPath)
                !window.location.href.includes('logohome') && this.$router.push('/home/logohome')
                // this.HeaderBool = false
                // setTimeout(() => {
                //     this.HeaderBool = true
                // }, 1);
            },
          
            initTags() {
                let route=this.$route
                let tag={
                    path: route.path ,
                    name:route.name,
                    meta:{name:route.name}
                }
                this.$store.dispatch('addVisitedView', tag)
            }
               
        },
        computed: {
     
            visitedViews() {
            return this.$store.state.visitedViews
            },
            selectlist() {
                let list = []
                let matched = this.$route.matched
                let l=matched.length
                let item=matched[l-1]
                if(item.name!='logohome'){
                    if(item.meta.pName){
                        list.push({name:item.meta.pName,path:item.path+'1'})
                    }
                    list.push(item)
                }
                return list
                }
          },
         
    }
</script>
<style  lang="less" scoped>
    ::v-deep  .content{padding: 0px;}
    .topheader{height:72px;line-height:72px;background-color: #252733;color: #fff}
    .el-aside {
        background-color: #1c1f28 !important;
        color: #FFF;
        text-align: center;
    }
    .logoName{
        float:left;
        font-size:20px;
        height:100%;
        line-height:60px;
        font-weight: bold;
    }
    .el-main {
        background-color: #E9EEF3;
        color: #333;
        padding:0px;overflow: hidden;
    }
    .el-container {
        height:100%;
    }
    .main-right{width:calc(100% - 240px);}
    .main-con{width:100%}
    .el-header, .el-footer {
        background-color: #1c1f28;
        font-weight: 500;
        color: #fff;
        text-align: center;
        line-height: 60px;
    }
    .el-header {
       padding-left: 0 !important;
    }
     .collapse {
         line-height: 1;cursor: pointer;
         i{font-size: 20px;margin-left:15px}
     }
     .crumbs {
            height: 40px;
            background: #fff;
            display: flex;
            align-items: center;            
            .breadcrumb {
            margin-left: 12px;
            }
            .el-breadcrumb font{            
                font-size: 16px;
            }
  }
  .tags-view-wrapper {
      background: #fff;
    .tags-view-item {
      display: inline-block;
      position: relative;
      cursor: pointer;
      height: 26px;
      line-height: 26px;
      border: 1px solid #d8dce5;
      color: #495060;
      background: #fff;
      padding: 0 8px;
      font-size: 12px;
      margin-left: 5px;
      margin-top: 2px;
      margin-bottom: 2px;
      &:first-of-type {
        margin-left: 15px;
      }
      &:last-of-type {
        margin-right: 15px;
      }
      &.active {
      
        color: #3595FF;
       
       
      }
    }
  }
</style>
