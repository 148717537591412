import AMapLoader from '@amap/amap-jsapi-loader'
export const LoadMixin = {
  
  data() {
    return {}
  },
  mounted() {
    this.loadMap()
  },
  methods: {
    loadMap() {
      let that = this
      // AMapLoader.load({
      //   key: this.$constants.KEY,
      //   plugins: [
      //     'AMap.Geocoder',
      //     'AMap.GeoJSON',
      //     'AMap.MouseTool',
      //     'AMap.ToolBar',
      //     'AMap.Scale',
      //     'AMap.PlaceSearch',
      //     'AMap.AutoComplete',
      //     'AMap.PolyEditor',
      //     'AMap.PolylineEditor'
          
      //   ],
      //   version: '2.0',
      //   Loca: {
      //     version: '2.0.0'
      //   }
      // })
      //   .then(AMap => {
          that.$eventBus.$emit('initMap', '')
          that.$store.commit('gis/pulginLoadGisChange', true)
          that.$store.commit('pulginLoadChange', true)
          
        // })
        // .catch(e => {
        //   console.log(e)
        // })
    }
  }
  // data() {
  //   return {}
  // },
  // mounted() {
  //   this.loadMap()
  // },
  // methods: {
  //   loadMap() {
  //     let that = this
  //     AMapLoader.load({
  //       key: this.$constants.KEY,
  //       plugins: [
        
  //         'AMap.PolylineEditor',
  //         // 'AMap.MouseTool',
  //         // 'AMap.Scale'
  //         'AMap.Geocoder',
  //         'AMap.GeoJSON',
  //         'AMap.MouseTool',
  //         'AMap.ToolBar',
  //         'AMap.Scale',
  //         'AMap.PlaceSearch',
  //         'AMap.AutoComplete',
  //         'AMap.PolyEditor'
  //       ],
  //       version: '2.0'
  //     }).then(AMap => {
  //        that.$store.commit('pulginLoadChange', true)
  //         // this.$eventBus.$emit('initMap', '')
  //         that.$store.commit('gis/pulginLoadGisChange', true)
  //         that.$eventBus.$emit('initMap', '')
  //       })
  //       .catch(e => {
  //       })
  //   }
  // }
}
