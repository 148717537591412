
import axios from '@/common/js/request'

//工具箱-标记新增或更新
export function saveMake(params) {
  return axios({
    url: '/layerTools/mark/update',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: params
  })
}
//工具箱-标记查询
export function makeList(params) {
  return axios({
    url: '/layerTools/mark/list',
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    params
  })
}
//工具箱-删除
export function delMake(params) {
  return axios({
    url: '/layerTools/mark/delete',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: params
  })
}



//设备设施
export function getEquipmentPage(params) {
  return axios({
    url: '/zypatrol/facility/equipment/page',
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    params
  })
}


//设备设施-巡检点批量设置
export function setcheckpoint(params) {
  return axios({
    url: '/zypatrol/facility/equipment/checkpoint/set',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data:params
  })
}

//设备设施-添加
export function saveEquipment(params) {
  return axios({
    url: '/zypatrol/facility/equipment/update',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data:params
  })
}



//管线-添加
export function saveLine(params) {
  return axios({
    url: '/zypatrol/line/basic/update',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data:params
  })
}

//管线(列表)
export function getLinePage(params) {
  return axios({
    url: '/zypatrol/line/list',
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    params
  })
}
//管线统计列表-分页
export function pageStatisticsStandingBook(data){
  const pageStatisticsStandingBook = axios({
      url: '/pipe/pageStatisticsStandingBook',
      method: 'get',
      params:data
  })
  return pageStatisticsStandingBook
}
//管线列表-分页
export function pipePageStandingBook(data){
  const pipePageStandingBook = axios({
      url: '/pipe/pageStandingBook',
      method: 'get',
      params:data
  })
  return pipePageStandingBook
}
//字典
export function getDict(params) {
  return axios({
    url: '/dict/selectDictListByParentCode',
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    params
  })
}

export function getDict2(params) {
  return axios({
    url: '/dict/selectDictListByParentCode2',
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    params
  })
}

export function selectDictListByParentCode(params) {
  return axios({
    url: '/dict/selectDictListByParentCode',
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    params
  })
}


//片区
export function getNetwork(params) {
  return axios({
    url: '/zypatrol/network/page',
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    params
  })
}
//片区-添加
export function saveNetwork(params) {
  return axios({
    url: '/zypatrol/network/update',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data:params
  })
}


//片区-删除
export function delNetwork(params) {
  return axios({
    url: '/zypatrol/network/delete',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data:params
  })
}



//管线-删除
export function delLine(params) {
  return axios({
    url: '/zypatrol/line/delete',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data:params
  })
}



//管线-详情
export function lineDetail(params) {
  return axios({
    url: '/zypatrol/line/list',
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    params
  })
}


//管线-删除
export function lineDelete(params) {
  return axios({
    url: '/zypatrol/line/delete',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data:params
  })
}


//片区-详情
export function networkDetail(params) {
  return axios({
    url: '/zypatrol/network/get',
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    params
  })
}


//设备-详情
export function equipmentsDetail(params) {
  return axios({
    url: '/zypatrol/facility/equipment/page',
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    params
  })
}

//管线-删除
export function delEquipments(params) {
  return axios({
    url: '/zypatrol/facility/equipment/delete',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data:params
  })
}
//zypatrol/network/get


export function GetNetworkDetail(params) {
  return axios({
    url: '/zypatrol/network/get',
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    params
  })
}



//设备-详情
export function lineGet(params) {
  return axios({
    url: '/zypatrol/line/get',
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    params
  })
}
//删除打卡点
export function delClock(params) {
  return axios({
    url: '/zypatrol/delClock',
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    params
  })
}

//人员轨迹
export function userPath(params) {
  return axios({
    url: '/zypatrol/picture/person/list',
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    params
  })
}



export function getMapCenterLocation(params) {
  return axios({
    url: '/sysCity/getLocation',
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    params
  })
}
//区域打卡点-添加-修改
export function saveClock(params) {
  return axios({
    url: '/zypatrol/updateClock',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data:params
  })
}

// 临清巡线设备详情
export function equipmentDetali(params) {
  return axios({
    url: '/zypatrol/facility/equipment/get',
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    params
  })
}

//打卡点-详情
export function getClock(params) {
  return axios({
    url: '/zypatrol/getClock',
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    params
  })
}

