import { constantDict } from '../utils/dict.js'
export function filterLayer($store) {
  let legendSelect = $store.getters.legendSelect
  let filter = '1=1'
  if (legendSelect && legendSelect.length > 0) {
    filter += ` and node_type2 in (${legendSelect.toString()},${constantDict.guanxian.dictCode})`
  } else {
    filter += ` and node_type2 in (${constantDict.guanxian.dictCode})`
  }
  let groupLayer = $store.getters.groupLayer
  let notGroupLayer = $store.getters.notGroupLayer
   if (notGroupLayer && notGroupLayer.length ==1&&notGroupLayer[0].id=='-100') {

   }else{
    if(groupLayer && notGroupLayer&&groupLayer.length<=notGroupLayer.length){
      let group = []
      groupLayer.forEach(item => {
        group.push(item.id)
      })
      filter += ` and layer_id  in (${group.toString()})`
    }
    if(groupLayer && notGroupLayer&&groupLayer.length>notGroupLayer.length){
      let group = []
      notGroupLayer.forEach(item => {
        group.push(item.id)
      })
      filter += ` and layer_id not in (${group.toString()})`
    }
   }
  if (groupLayer && groupLayer.length > 0) {
    
  }
  return filter
}

export function centerPointGetFourPoint(map, lnglat, box) {
  
  
  let margin = box || 100
  let nowZoom = map.getZoom()
  if(nowZoom>20){
    margin=160
  }
  let Pixel = map.lngLatToContainer(lnglat)
  let newPixel = new AMap.Pixel(Pixel.x - margin, Pixel.y)
  const newLnglat = map.containerToLngLat(newPixel)

  let side = AMap.GeometryUtil.distance(lnglat, newLnglat)

  const centerPoint = lnglat
  const upLeftPoint = centerPoint.offset(-parseInt(side / 2), parseInt(side / 1.9))
  const upRightPoint = centerPoint.offset(parseInt(side / 2), parseInt(side / 1.9))
  const leftBottomPoint = centerPoint.offset(-parseInt(side / 2), -parseInt(side / 1.7))
  const rightBottomPoint = centerPoint.offset(parseInt(side / 2), -parseInt(side / 1.7))
  return {
    upLeftPoint: [upLeftPoint.KL, upLeftPoint.kT],
    upRightPoint: [upRightPoint.KL, upRightPoint.kT],
    leftBottomPoint: [leftBottomPoint.KL, leftBottomPoint.kT],
    rightBottomPoint: [rightBottomPoint.KL, rightBottomPoint.kT]
  }
}
export function lineRangePoint(map, lnglat) {
  let margin = 10
  let Pixel = map.lngLatToContainer(lnglat)
  let newPixel = new AMap.Pixel(Pixel.x - margin, Pixel.y)
  const newLnglat = map.containerToLngLat(newPixel)

  let side = AMap.GeometryUtil.distance(lnglat, newLnglat)

  const centerPoint = lnglat

  const upLeftPoint = centerPoint.offset(-parseInt(side / 2), parseInt(side / 1.9))
  const leftBottomPoint = centerPoint.offset(-parseInt(side / 2), -parseInt(side / 1.7))
  return [
    [upLeftPoint.lng, upLeftPoint.lat],
    [leftBottomPoint.lng, leftBottomPoint.lat]
  ]
}
export function getPixel(map, start, end) {
  var zoom = map.getZoom()
  var startpixel = map.lngLatToPixel(start, zoom)
  var endpixel = map.lngLatToPixel(end, zoom)
  let x = startpixel.x - endpixel.x
  let y = startpixel.y - endpixel.y
  return Math.sqrt(x * x + y * y)
}
/* 
经纬度格式字符串转数组
@val 字符串
type Point是点，LineString是线
*/
export function formatLocation(val, type) {
  let pathObj = []
  if (type == 'LineString') {
    let temp = val
      .split('(')[1]
      .split(')')[0]
      .split(',')
    temp.forEach(item => {
      pathObj.push(item.split(' '))
    })
  } else {
    pathObj = val
      .split('(')[1]
      .split(')')[0]
      .split(' ')
  }
  return pathObj
}

export default {
  filterLayer,
  centerPointGetFourPoint,
  formatLocation
}
