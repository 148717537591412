<template>
  <div class="toolbar" :style="{ left: propsPosition + 'rem' }">
    <div class="disk">
      <span class="diskLeft" @click="panBy(100, 0)"></span>
      <span class="diskTop" @click="panBy(0, 50)"></span>
      <span class="diskRight" @click="panBy(-100, 0)"></span>
      <span class="diskBottom" @click="panBy(0, -50)"></span>
    </div>
    <div class="setZoom">
      <span class="setCommon" @click="setCenter()">
        <i class="setCenter"></i>
      </span>
      <span class="setCommon" @click="setZoom('out')">
        <i class="setjia"></i>
      </span>
      <div class="setCommon c">
        <span class="bg" :alt="zoom">
          <span class="value" :style="{ height: height }"></span>
        </span>
      </div>
      <span class="setCommon last" @click="setZoom('in')">
        <i class="setjian"></i>
      </span>
    </div>
  </div>
</template>

<script>
import {  getMapCenterLocation } from '@/apis/commonType'
export default {
  name: 'Toolbar',
  components: {},
  props: {
    propsPosition: {
      type: String,
      default: '0.1'
    }
  },
  data() {
    return {
      zoom: 14,
      postionVal: 4.62
    }
  },
  computed: {
    height() {
      return (this.zoom / 20) * 100 + '%'
    }
  },

  mounted() {},
  methods: {
    panBy(lon, lat) {
      this.$parent.map.panBy(lon, lat)
    },
    setCenter() {
      // let Position = [118.19928, 39.607487]
      // // 获取中心点
      getMapCenterLocation().then(res => {
          if(res.data!=null&&res.data.length>0){
          let area = res.data.split(',')
          this.$parent.map.setCenter(area)
        }
      })
      // this.$parent.map.setCenter(Position)
    },
    setZoom(type) {
      let zoom = this.$parent.map.getZoom()
      if (type == 'out') {
        zoom += 1
      } else {
        zoom -= 1
      }
      this.$parent.map.setZoom(zoom)
    }
  }
}
</script>

<style lang="scss" scoped>
.setCenter,
.setjia,
.setjian {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-size: 100% 100%;
}
.toolbar {
  position: absolute;
  bottom: 0.3rem;
  left: 4.62rem;
  z-index: 1;
  transition: left 0.5s;
  .disk {
    background-size: 100% 100%;
    width: 0.57rem;
    height: 0.57rem;
    position: relative;
    span {
      width: 0.21rem;
      height: 0.21rem;
      position: absolute;
      cursor: pointer;
    }
    .diskLeft {
      top: 0.2rem;
      left: 0rem;
    }
    .diskTop {
      top: 0rem;
      left: 0.2rem;
    }
    .diskRight {
      top: 0.2rem;
      right: 0rem;
    }
    .diskBottom {
      bottom: 0rem;
      left: 0.2rem;
    }
  }
  .setZoom {
    background: var(--background-color-skin);
    width: 0.4rem;
    text-align: center;
    margin-left: 0.08rem;
    margin-top: 0.12rem;
  }
  .setCommon {
    display: block;
    padding-top: 0.08rem;
    border-bottom: 1px solid var(--background-full-skin);
    text-align: center;
    cursor: pointer;
  }
  .setCommon.last {
    border: none;
  }
  .c {
    cursor: default;
  }
  .bg {
    height: 1rem;
    width: 5px;
    display: block;
    margin: 0 auto;
    background: var(--background-full2-skin);
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 0.12rem;
    position: relative;
  }
  .value {
    background: #01baff;
    width: 5rem;
    position: absolute;
    bottom: 0;
    left: 0;
    transition: height 0.5s;
  }
}
</style>
