import router from "@/router/index";
import domMessage from "@/common/js/MessageOnce";
let Message  = new domMessage()
let Allmenu = []
function mapMenuFun(e,pName){
    if(e.children){
        e.children.forEach((item,index)=>{
            
                mapMenuFun(item,e.name)
          
            
        })
    }else{
            if(e.url=='authManagement'||e.url=='gis'||e.url=='warnGis'||e.url=='configuration'){
                let RouterItem = {}
                RouterItem.path = `/home/${e.url}`
                RouterItem.name = e.name   
                RouterItem.meta={ name:e.name,pName,keepAlive:true}
                RouterItem.component = ()=>import(`../views${e.router}`)
                Allmenu.push(RouterItem)
            }else{
                let RouterItem = {}
                RouterItem.path = `/home/${e.url}`
                RouterItem.name = e.name   
                RouterItem.meta={ name:e.name,pName,keepAlive:false}
                RouterItem.component = ()=>import(`../views${e.router}`)
                Allmenu.push(RouterItem)
            }
            
     
        return
    }
}
function MenuConfig(Menu){
    let MenuArray = []
    if(!Menu){
        if(location.href.indexOf('?parameter') == -1){
            Message.error('登录超时，请重新登录')
            setTimeout(()=>{
                router.push('/login')
            },1000)
        }
    }else{
        Menu=getNavTree(Menu)
        Menu.map(obj=>{
            mapMenuFun(obj)
        })
    }
    return Allmenu
}
function getNavTree(menus) {
    var aside = [] 
    var resultChildren = []
        menus.forEach((menu, index) => {
            let nav={
              displayMode:menu.displayMode,
              id:menu.id,
              menuCloseIcon:menu.menuCloseIcon,
              menuOpenIcon:menu.menuOpenIcon,
              name:menu.name,
              parentId:menu.parentId,
              router:menu.router,
              scope:menu.scope,
              type:menu.type,
              url:menu.url,
              weight: menu.weight
            }          
            if (menu.children) {            
              resultChildren = getNavTree(menu.children)
              if (resultChildren&&resultChildren.length > 0) {              
                  nav.children = resultChildren              
              }
            }        
            if(nav.type==2){//按钮
               
            }else{//菜单
              aside.push(nav)
            }       
        })
      return aside
  }
export default MenuConfig