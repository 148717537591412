
export function getButtonAuth(code) {
  let anthButton=JSON.parse(localStorage.getItem('buttonList')) 
   let auth = anthButton.find(
      item => item == code
    )
   return auth
}
export default {
  getButtonAuth
}