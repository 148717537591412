<template>
  <div id="app">
    <router-view class="fatherRouter" />
  </div>
</template>
<script>
import { LoadMixin } from "@/common/amapLoad";
export default {
  mixins: [LoadMixin],
}
//123456
/*
1234567
*/
/*
****************************************************************************************************

*    重点参数含义： （例：@param 123456789 immediate true - 立即执行， false - 延迟执行）

****************************************************************************************************
*/

// import {getSocketUrl} from './common/js/request'
// export default {

//   created() {
//     if(localStorage.getItem('userId')&&localStorage.getItem('userId')!=undefined){
//       this.localSocket(localStorage.getItem('userId'));
//     }   
//   },
//   watch: {
//     '$store.getters.getSocketState'(val) {
//         console.log("socket状态:"+val)
//         if(val){
//           this.localSocket(localStorage.getItem('userId'));
//         }else{
//           console.log("断开socket")
//           this.closeSocket();
//         }
//     },
//   },
//   computed:{

//   },
//   methods:{ 
//     start() {　　　　// 发送心跳
//       clearInterval(this.timeoutObj)
//       this.timeoutObj = setInterval(() => {
//         let that = this;
//         let date = new Date()        
//         if (that.global.ws && that.global.ws.readyState == 1) {
//           console.log("发送心跳")
//           that.global.ws.send(`发送心跳给后端${date}`);
//         }
//       },  1000*2*60)
//     },
//     closeSocket(){
//       if ("WebSocket" in window) {
//         this.global.ws.close();
//       }
//     },
//     getMessage(msg){
//         console.log(JSON.parse(msg.data));
//         let data = JSON.parse(msg.data);
//       this.$notify.info({
//           title: data.title,
//           message: data.content,
//           duration: 0,
//           position: 'bottom-right',
//           onClick:()=>{
//             this.gotoUrl(data.router,data.tree,data)
//           }
//         });
//     },
//     gotoUrl(router,tree,data){
//       //跳转到对应页面
//       localStorage.setItem('SelectMenuArray',JSON.stringify(tree.children))
//       let menuAll = JSON.parse(localStorage.getItem('MenuTree'))
//       menuAll.forEach((item,index)=>{
//         if(item.id == data.tree.id){
//           localStorage.setItem('tabIndex',index)
//         }
//       })
//       if((this.$route.fullPath.indexOf(data.router)+1)>0){
//         return
//       }else{
//         this.$router.push(router);
//       }
//     },
//   //app.vue
//     localSocket(userId) {
//       let that = this;
//       if ("WebSocket" in window) {
//           // console.log("您的浏览器支持 WebSocket!");
//           // location.host
//           that.ws = new WebSocket(getSocketUrl()+"?userId="+userId);
//           that.global.setWs(that.ws);
//           that.ws.onopen = function () {
//             console.log('websocket连接成功');
//             //发送心跳表
//             that.start();
//           };
//           this.ws.onmessage = this.getMessage;

//           that.ws.onclose = function () {
//             // 关闭 websocket
//             console.log("连接已关闭...");
//             //断线重新连接
//             if(localStorage.getItem('userId')&&localStorage.getItem('userId')!=undefined){
//               setTimeout(() => {
//                   that.localSocket(userId);
//               }, 2000);
//             }
//           };
//       } else {
//           // 浏览器不支持 WebSocket
//           console.log("您的浏览器不支持 WebSocket!");
//           this.openNotificationWithIcon('error', '浏览器', '您的浏览器不支持显示消息请更换', 1,1)
//       }
//     }
//   }
// }
</script>
<style lang="less"  scoped>
 * {
  margin: 0;
  padding: 0;
}
.el-scrollbar{
  height: auto;
}
/deep/ .el-scrollbar{
  height: auto;
}
.routerViwe {
  width: 100%;
  height: 100%;
}
#app {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
body,
html {
  width: 100%;
  height: 100%;
  // overflow: hidden;
}
.demo-table-expand {
  font-size: 0;
  display: flex;
  flex-direction: column;
}
.demo-table-expand label {
  width: 100px;
  color: #99a9bf;
}

.demo-table-expand .el-form-item {
  margin-right: 0;
  // margin-bottom: 0;
  width: 50%;
}
.RightSelect input {
  width: 100px;
  height: 26px;
  line-height: 26px;
  border-color: rgba(60, 207, 255, 0.5);
  border-radius: 0;
  background-color: #0b2d51;
  font-size: 12px;
  color: #3ccfff !important;
}
.RightCountTitle .RightSelect input {
  width: inherit;
}
#AddUserDeviceId .RightSelect input {
  width: 200px;
  height: 26px;
  line-height: 26px;
  border-color: rgba(60, 207, 255, 0.5);
  border-radius: 0;
  background-color: rgba(37, 148, 184, 0.1);
  font-size: 12px;
  color: #3ccfff !important;
}
.fatherRouter {
  height: 100%;
}
.RightSelect .el-input__icon {
  line-height: 26px;
}

.RightSelect .el-icon-arrow-up:before {
  content: "\e78f";
  color: rgba(60, 207, 255, 0.5);
}
#top_item_date input {
  background: none;
  border: 0;
  font-size: 14px;
  color: #ffffff;
  padding: 0 10px;
  text-align: center;
}
#top_item_date input::-webkit-input-placeholder {
  font-size: 12px;
}
#top_item_date .el-input__icon,
#date_time .el-input__icon {
  display: none;
}
#date_time input {
  background: none;
  border: 0;
  font-size: 14px;
  color: #ffffff;
  padding: 0 10px;
  text-align: center;
}
#date_time input::-webkit-input-placeholder {
  font-size: 12px;
}
::v-deep .el-table .el-table__cell {
    padding: 7px 2px;}
::v-deep .el-table{font-size: 12px !important}
::v-deep .el-table .el-table__header .el-table__cell{
  background-color: #F0F2F5 !important;
  color: #0F0F0F;
  font-weight: normal;
}
::v-deep .el-dialog{
  margin-bottom: 0;
  .el-dialog__body{
    padding: 20px;
  }
}
::v-deep .el-dialog__header{
  background: #F9F9F9;
  padding-top:17px;
  border-radius: 6px 6px 0 0;
  padding-bottom:17px;
  .dialogTitles{
    display: flex;
    align-items: center;
    color: #0f0f0f;
    font-size: 16px;
    b{
        margin-right: 10px;
        font-size: 16px;
    }
    span{
        display: inline-block;
        background-color: #1082FF;
        width: 4px;
        height: 16px;
        margin: 0 10px;
    }
  }
}

</style>

